import { get } from 'lodash';

import config from '../config';
import store from '../store';

// TODO: This is bad, turn into an action ASAP

function openForm(userId, formName) {
  const clinicId = get(store.getState(), 'clinic.clinicId', null);

  if (formName === 'all') {
    window.open(`${config.API_URL}/clinics/${clinicId}/user_reports/${userId}`);
  } else {
    window.open(`${config.API_URL}/clinics/${clinicId}/user_reports/${userId}/${formName}`);
  }
}

export default openForm;
