import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { forEach, reject, get, isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles'

import Checkbox from './checkbox';
import { stateFormat, mergeFormat } from '../lib/merge-format-medications';
import ListItem from './emr-list-item';
import EmrComparisonGrid from './emr-comparison-grid';
import EmrMergeHeader from './emr-merge-header';
import EmrCard from './emr-card';
import { emrPageStyles } from '../lib/styles';

const styles = { ...emrPageStyles };

function buildList(combined, list, handleSelection, disableSelection, classes) {
  if(isEmpty(list)) {
    return 'None Reported';
  }

  const components = [];
  if (combined) {
    forEach(list, (item, index) => {
      components.push(
        <ListItem
          key={`${index}-combined}`}
          primaryText={`${item.name}   ${item.dosage}   ${item.frequency}`}
          secondaryText={`${item.prescribing_physician}   ${item.start_date}`}
        />);
    });
    return components;
  }

  forEach(list, (item, key) => {
    components.push(
      <ListItem
        leftCheckbox={
          <Checkbox
            checked={disableSelection || item.checked || false}
            classes={{ root: classes.checkbox }}
            disabled={disableSelection}
            onChange={(e, checked) => handleSelection(item, checked)}
          />
        }
        primaryText={`${item.name}   ${item.dosage}   ${item.frequency}`}
        secondaryText={`${item.prescribing_physician}   ${item.start_date}`}
        key={key}
      />,
    );
  });
  return components;
}

class MergePatientMedications extends Component {
  constructor(props) {
    super(props);
    this.handleSelection = this.handleSelection.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
    this.handleNext = this.handleNext.bind(this);
    const emrMeds = get(this.props.mergeData.ehr, 'Medications');
    this.state = stateFormat(this.props.mergeData.informed.medications, emrMeds);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleNext() {
    const data = mergeFormat(this.state.combined);
    this.setState({ nextLoading: true});
    this.props.editUser(this.props.emrPatient, data, this.props.mergeData.page, 'medications_merge')
      .catch(() => this.setState({ nextLoading: false }));
  }
  handleSkip() {
    this.props.mergePatientData(false);
  }
  handleSelection(item, checked) {
    const { id, dosage, prescribing_physician, start_date, frequency, name, category } = item;
    if (!checked) {
      this.setState({
        combined: reject(this.state.combined, ['id', id]),
        emrMeds: {
          ...this.state.emrMeds,
          [id]: {
            id,
            dosage,
            start_date,
            frequency,
            name,
            category,
            prescribing_physician,
            checked: false,
          },
        },
      });
    } else {
      this.setState({
        combined: {
          ...this.state.combined,
          [id]: {
            id,
            dosage,
            start_date,
            frequency,
            name,
            category,
            prescribing_physician,
          }
        },
        emrMeds: {
          ...this.state.emrMeds,
          [id]: {
            id,
            dosage,
            start_date,
            frequency,
            name,
            category,
            prescribing_physician,
            checked: true,
          },
        },
      });
    }
  }
  render() {
    const combined = buildList(true, this.state.combined, this.handleSelection, false, this.props.classes);
    const mindset = buildList(false, this.state.mindset, this.handleSelection, true, this.props.classes);
    const emrMed = buildList(false, this.state.emrMeds, this.handleSelection, false, this.props.classes);
    return (
      <div style={styles.container}>
        <EmrMergeHeader
          nextLoading={this.state.nextLoading}
          onNext={this.handleNext}
          onCancel={this.handleSkip}
          title="Update Medications"
        />
        <EmrComparisonGrid>
          <EmrCard cardTitle="Hana Patient">
            {mindset}
          </EmrCard>
          <EmrCard cardTitle="Combined Patient">
            {combined}
          </EmrCard>
          <EmrCard cardTitle="EMR Patient">
            {emrMed}
          </EmrCard>
        </EmrComparisonGrid>
      </div>
    );
  }
}

MergePatientMedications.propTypes = {
  editUser: PropTypes.func.isRequired,
  emrPatient: PropTypes.object.isRequired,
  mergeData: PropTypes.object,
  handleSkip: PropTypes.func,
};

export default withStyles(styles)(MergePatientMedications);
