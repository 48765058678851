import React, { Component, Fragment } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextField from 'material-ui/TextField';
import Button from '@material-ui/core/Button';
import { get, noop } from 'lodash';

import TermsOfUse from '../components/terms';
import { login, updateUserMessage } from '../actions/user';
import { backgroundImageStyle, colors } from '../lib/styles';
import { logEvent } from '../lib/amplitude';
import { apiFetch } from '../lib/fetch';
import Joi from 'joi-browser';

const baseStyles = {
  btnAlignment: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnAlignmentForgotPw: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: 0,
  },
  btnStyle: {
    color: 'white',
    backgroundColor: colors.secondaryColor,
    width: '130px',
    marginLeft: '100px',
  },
  btnStyleForgotPw: {
    color: 'white',
    backgroundColor: colors.secondaryColor,
    width: '12rem',
  },
  backgroundImg: backgroundImageStyle,
  centered: {
    height: '80vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorText: {
    alignSelf: 'center',
    color: colors.errorOrange,
  },
  field: {
    padding: '10px',
  },
  fieldForgotPw: {
    padding: 0,
    width: '3rem',
  },
  fieldLabel: {
    height: '2rem',
    width: '100%',
    fontSize: '1.125rem',
    color: colors.black,
  },
  fieldLabelForgotPw: {
    height: '3rem',
    width: '11.5rem',
    fontSize: '1.125rem',
    lineHeight: '2.2rem',
    color: colors.black,
  },
  forgotPw: {
    color: colors.errorOrange,
    marginTop: '1rem',
    cursor: 'pointer',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: '30px auto 0',
  },
  hanaImg: {
    marginBottom: '2.5rem',
    height: '6rem',
    zIndex: 10,
  },
  headerText: {
    display: 'flex',
    flexDirection: 'column',
    color: colors.darkGrayText,
    margin: '0 auto',
    paddingTop: '60px',
    paddingBottom: '25px',
    textAlign: 'center',
    width: '500px',
  },
  inputAlignment: {
    display: 'flex',
    alignItems: 'center',
  },
  inputAlignmentForgotPw: {
    display: 'flex',
    marginBottom: '1rem',
  },
  msgText: {
    alignSelf: 'center',
    color: colors.errorOrange,
    marginTop: '0.5rem',
  },
  textFieldInput: {
    width: '190px',
    textIndent: '7px',
    height: '75%',
    backgroundColor: colors.white,
    color: colors.black,
    boxShadow: `-1px -1px 1px ${colors.lightGrayText}`,
    border: `.5px solid ${colors.lightGrayText}`,
    verticalAlign: 'middle',
    borderRadius: '1px',
  },
};

const passwordSchema = {
  password: Joi.string()
    .min(8)
    .regex(/[A-Z]/)
    .regex(/\d/)
    .required().error(new Error('Password needs at least 8 Characters, 1 Uppercase & 1 Number')),
  verify_password: Joi.string()
    .min(8)
    .regex(/[A-Z]/)
    .regex(/\d/)
    .required().error(new Error('Password needs at least 8 Characters, 1 Uppercase & 1 Number')),
  errorMsg: 'You need at least 8 Characters, 1 Uppercase & 1 Number',
};

const messageTemplates = {
  email: 'Thank you for confirming your email you may now login.',
};

export class Login extends Component {
  constructor(props) {
    super(props);

    const isReset = get(props, 'location.query.reset_id', false);
    const msgType = get(props, 'location.query.msg', '');

    this.state = {
      error: '',
      msg: messageTemplates[msgType] || '',
      username: '',
      password: '',
      verify_password: '',
      currentAction: isReset ? 'RESET' : 'LOGIN',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.handleUpdateUsername = this.handleChange.bind(this, 'username');
    this.handleUpdatePassword = this.handleChange.bind(this, 'password');
    this.handleUpdateVerifyPassword = this.handleChange.bind(this, 'verify_password');
    this.showForgotPassword = this.changePageAction.bind(this, 'FORGOT');
    this.showLogin = this.changePageAction.bind(this, 'LOGIN');
    this.handleKeyEnter = this.handleKeyEnter.bind(this);
    this.handleKeyEnterForgotPassword = this.handleKeyEnterForgotPassword.bind(this);
    this.handleKeyEnterReset = this.handleKeyEnterReset.bind(this);
  }
  handleChange(field, evt, value) {
    this.setState({ [field]: value });
  }
  handleLogin() {
    logEvent('Login to Provider App');
    this.setState({
      error: '',
      msg: '',
      password: '',
    });
    this.props.updateUserMessage('');
    this.props.login(this.state.username, this.state.password)
      .then(() => {
        this.props.router.push('/clinic-select');
      })
      .catch(() => {
        this.setState({ error: 'Invalid Username or Password' });
      });
  }
  handleResetPassword() {
    const { password, verify_password } = this.state;
    const resetId = this.props.location.query.reset_id;
    const resetPayload = {
      password,
      verify_password,
    };
    if (password !== verify_password) {
      return this.setState({ error: 'Passwords must match.' });
    }
    const requestOptions = {
      body: resetPayload,
      method: 'PUT',
    };

    Joi.validate(resetPayload, passwordSchema, (err) => {
      if (err) {
        return this.setState({ error: err.message });
      }
      apiFetch(`/reset/${resetId}`, requestOptions)
        .then((res) => {
          return this.setState({
            error: '',
            verify_password: '',
            password: '',
            currentAction: 'LOGIN',
            msg: 'Password reset successfully, you may now login.',
          });
        })
        .catch(() => {
          this.setState({
            username: '',
            error: 'Error setting new password, please try again',
          });
        });
    });
  }
  handleForgotPassword() {
    this.props.updateUserMessage('');
    const resetPayload = {
      reset_type: 'HANA_PROVIDER',
      email: this.state.username,
    };
    const requestOptions = {
      body: resetPayload,
      method: 'POST',
    };

    return apiFetch('/reset', requestOptions)
      .then((res) => {
        return this.setState({
          username: '',
          error: '',
          msg: 'An email has been sent with reset instructions',
        });
      })
      .catch(() => {
        this.setState({
          username: '',
          error: 'Error Sending Password Reset Email',
        });
      });
  }
  changePageAction(view) {
    this.props.updateUserMessage('');
    this.setState({
      currentAction: view,
      msg: '',
      error: '',
      password: '',
    });
  }
  handleKeyEnterForgotPassword = function (e) {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      this.handleForgotPassword();
    }
  };
  handleKeyEnterReset = function (e) {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      this.handleResetPassword();
    }
  };
  handleKeyEnter = function (e) {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      this.handleLogin();
    }
  };

  render() {
    let form;
    if (this.state.currentAction === 'LOGIN') {
      form = (
        <Fragment>
          <div key="username" style={baseStyles.inputAlignment}>
            <div style={baseStyles.fieldLabel}>Username:</div>
            <TextField
              id="username"
              className="login-field"
              fullWidth={true}
              inputStyle={baseStyles.textFieldInput}
              onChange={this.handleUpdateUsername}
              style={baseStyles.field}
              underlineShow={false}
              value={this.state.username}
            />
          </div>
          <div key="password" style={baseStyles.inputAlignment}>
            <div style={baseStyles.fieldLabel}>Password:</div>
            <TextField
              id="password"
              className="login-field"
              fullWidth={true}
              inputStyle={baseStyles.textFieldInput}
              onChange={this.handleUpdatePassword}
              style={baseStyles.field}
              type="password"
              underlineShow={false}
              value={this.state.password}
              onKeyDown={this.handleKeyEnter}
            />
          </div>

          <Button
            key="login"
            style={baseStyles.btnStyle}
            onClick={this.handleLogin}
          >
            Login
          </Button>
          <Link onClick={() => { logEvent('Forgot Password'); this.showForgotPassword(); }} style={baseStyles.forgotPw}>
            Forgot your password?
          </Link>
        </Fragment>
      );
    }

    if (this.state.currentAction === 'RESET') {
      form = (
        <Fragment>
          <div key="newPassword" style={baseStyles.inputAlignment}>
            <div style={baseStyles.fieldLabel}>New Password:</div>
            <TextField
              id="newPassword"
              className="login-field"
              floatingLabelShrinkStyle={baseStyles.shrinkStyle}
              floatingLabelStyle={baseStyles.textFieldLabel}
              fullWidth={true}
              inputStyle={baseStyles.textFieldInput}
              onChange={this.handleUpdatePassword}
              style={baseStyles.field}
              type="password"
              underlineShow={false}
              value={this.state.password}
            />
          </div>
          <div key="verify" style={baseStyles.inputAlignment}>
            <div style={baseStyles.fieldLabel}>Confirm Password:</div>
            <TextField
              id="verify"
              className="login-field"
              floatingLabelStyle={baseStyles.textFieldLabel}
              fullWidth={true}
              inputStyle={baseStyles.textFieldInput}
              onChange={this.handleUpdateVerifyPassword}
              style={baseStyles.field}
              type="password"
              underlineShow={false}
              value={this.state.verify_password}
              onKeyDown={this.handleKeyEnterReset}
            />
          </div>
          <Button
            key="reset"
            style={baseStyles.btnStyle}
            onClick={this.handleResetPassword}
          >
            Update Password
          </Button>
        </Fragment>
      )
    }

    if (this.state.currentAction === 'FORGOT') {
      form = (
        <Fragment>
          <div key="email" style={baseStyles.inputAlignmentForgotPw}>
            <div style={baseStyles.fieldLabelForgotPw}>Email Address:</div>
            <TextField
              id="email"
              className="login-field"
              floatingLabelStyle={baseStyles.textFieldLabel}
              fullWidth={true}
              inputStyle={baseStyles.textFieldInput}
              onChange={this.handleUpdateUsername}
              style={baseStyles.fieldForgotPw}
              underlineShow={false}
              value={this.state.username}
              onKeyDown={this.handleKeyEnterForgotPassword}
            />
          </div>
          <div style={baseStyles.btnAlignmentForgotPw}>
            <Button
              key="reset"
              style={baseStyles.btnStyleForgotPw}
              onClick={this.handleForgotPassword}
            >
              Reset Password
            </Button>
            <Button
              key="cancel"
              style={baseStyles.btnStyleForgotPw}
              onClick={this.showLogin}
            >
              Return to Login
            </Button>
          </div>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <div style={baseStyles.backgroundImg} />
        <section style={baseStyles.centered}>
          <div style={baseStyles.headerText}>
            <img
              alt="Hana Logo"
              src="img/hana-logo.svg"
              style={baseStyles.hanaImg}
            />
            <div style={baseStyles.errorText}>{this.state.error}</div>
            <div style={baseStyles.msgText}>{this.state.msg || this.props.user.userMessage}</div>
            <div style={baseStyles.form}>
              {form}
            </div>
            <TermsOfUse />
          </div>
        </section>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

function mapDispatchToProps(dispatch) {
  const actionCreators = { login, updateUserMessage };

  return bindActionCreators(actionCreators, dispatch);
}

Login.defaultProps = {
  location: {},
  login: noop,
  router: {},
  user: {},
  children: {},
};

Login.propTypes = {
  location: PropTypes.object,
  login: PropTypes.func,
  router: PropTypes.object,
  user: PropTypes.object,
  children: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
