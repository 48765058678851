import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { assign, capitalize, concat, debounce, differenceBy, find, isNil, omitBy, sortBy } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router';

import IconButton from '@material-ui/core/IconButton';
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar';
import { AutoComplete, IconMenu, MenuItem } from 'material-ui';
import SearchIcon from 'material-ui/svg-icons/action/search';
import InviteDialog from './invite-dialog';
import LogoutDialog from './logout-dialog';
import Button from '@material-ui/core/Button';

import config from '../config';
import { colors } from '../lib/styles';
import { apiFetch } from '../lib/fetch';
import { logEvent } from '../lib/amplitude';

const baseStyles = {
  bell: {
    height: '1.2rem',
    width: '1.2rem',
    backgroundColor: 'none',
  },
  btnInvite: {
    backgroundColor: colors.charcoal,
    color: colors.black,
    minHeight: '39px',
    width: '175px',
    fontSize: '16px',
  },
  btn: {
    position: 'relative',
    backgroundColor: colors.secondaryColor,
    color: colors.white,
    minHeight: '40px',
    fontSize: '16px',
    width: '175px',
  },
  scanBtn: {
    backgroundColor: colors.primaryColor,
  },
  header: {
    backgroundColor: colors.white,
    padding: '25px',
  },
  headerText: {
    color: colors.lightGrayText,
    fontWeight: 700,
    paddingLeft: '10px',
  },
  icon: {
    color: colors.lightGreyText,
    fontColor: colors.white,
    height: '1.5rem',
    width: '1.7rem',
  },
  label: {
    color: colors.black,
  },
  logo: {
    cursor: 'pointer',
    height: '40px',
    padding: '5px 5px 5px 10px',
    marginRight: '50px',
    maxHeight: '100px',
  },
  logoutChildren: {
    lineHeight: 1.2,
    position: 'absolute',
  },
  name: {
    textTransform: 'none',
    fontSize: '12px',
  },
  unread: {
    height: '8px',
    backgroundColor: 'red',
    borderRadius: '50%',
    bottom: '10px',
    position: 'absolute',
    right: '9px',
    width: '8px',
  },
  search: {
    color: colors.lightGrayText,
    fontWeight: 400,
  },
  searchIcon: {
    color: colors.lightGrayText,
    paddingRight: '20px',
  },
  searchInput: {
    color: colors.primaryColor,
    fontWeight: 400,
  },
};

function searchFilter(searchText, key) {
  return key.toLowerCase().includes(searchText.toLowerCase());
}

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.onSearchSelect = this.onSearchSelect.bind(this);
    this.onOpenNotifications = this.onOpenNotifications.bind(this);
    this.onSelectNotification = this.onSelectNotification.bind(this);
    this.toggleUserInviteDialog = this.toggleDialog.bind(this, 'userInviteDialog');
    this.toggleUserLogoutDialog = this.toggleDialog.bind(this, 'userLogoutDialog');
    this.queryApiPatients = debounce(this.queryApiPatients.bind(this), 1000);
    this.updateInput = this.updateInput.bind(this);
    this.toggleUserInviteDialog = this.toggleUserInviteDialog.bind(this);
    this.openCheckInModal = props.openModal.bind(this, 'checkInModalOpen');

    this.state = {
      apiSearchResults: [],
      enterPressed: false,
      newNotifications: false,
      searchOpen: true,
      searchText: '',
      userInviteDialog: false,
      userLogoutDialog: false,
      triggerReOpen: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.notifications.length > this.props.notifications.length) {
      this.setState({ newNotifications: true });
    }
  }
  onSearchSelect(user, index) {
    if (index !== -1) {
      this.props.addRecent(user);
      this.props.router.push({ pathname: '/app/patient', state: { userId: user.user_id } });
      logEvent('Select Patient Search');
    } else {
      this.queryApiPatients(this.state.searchText);
      // Deals with weird bug in mui AutoComplete that results in
      // search auto closing when you press enter regardless of open state
      this.setState({ enterPressed: true, searchOpen: false}, () => {
        this.setState({ searchOpen: true });
      });
    }
  }
  onOpenNotifications() {
    this.setState({ newNotifications: false });
  }
  onSelectNotification(evt, value) {
    const splitIds = value.split('-');
    const notificationId = splitIds[5];
    this.props.dismissNotification(notificationId);
    const final = concat(splitIds[0] + "-" + splitIds[1] + "-" + splitIds[2] + "-" + splitIds[3] + "-" + splitIds[4]);
    this.props.router.push({ pathname: '/app/patient', state: { userId: final } });
  }
  toggleDialog(dialog) {
    this.setState({
      [dialog]: !this.state[dialog],
    });
  }
  queryApiPatients(searchText) {
    let first_name;
    let last_name;
    let email;

    // If the text contains @ assume email
    // if contains a space assume first + last
    // otherwise attempt to put the full string
    // in first and last. API uses OR in these cases
    if (searchText.includes('@')) {
      email = searchText;
    } else {
      if(searchText.indexOf(' ') === -1) {
        first_name  = searchText;
        last_name = searchText;
      } else {
        first_name = searchText.split(' ')[0];
        last_name = searchText.split(' ')[1];
      }
    }

    const query = omitBy({
      first_name,
      last_name,
      email,
    }, isNil);
    apiFetch(`/clinics/${this.props.clinicId}/patients`, { query })
      .then((results) => {
        const apiSearchResults = differenceBy(results.data, this.props.appointments, 'user_id')
        .map((i) => {
          i.name = `${i.first_name} ${i.last_name}`;
          i.last_first_email = `${i.last_name}, ${i.first_name} (${i.email})`;
          return i;
        })
        .concat(this.props.appointments);

        this.setState({
          apiSearchResults,
        });
      })
      .catch((e) => {
        // It's occasionnaly possible to send some bad data up.
        // Ignore errors for these cases
      });
  }
  updateInput(searchText) {
    this.setState({ enterPressed: false, searchText });
    if(searchText.length < 3) return;
    this.queryApiPatients(searchText);
  }
  render() {
    const { appointments, firstName, lastName } = this.props;
    const { newNotifications, searchText } = this.state;

    const notificationBellIcon = <img src="/img/alerts.svg" alt="notification bell" style={baseStyles.icon} />;
    let searchResults = [];
    if(searchText.length > 2 || this.state.enterPressed) {
      searchResults = sortBy(this.state.apiSearchResults, ['last_name', 'first_name']);
    }
    const notificationUnreadStyle = newNotifications ? baseStyles.unread : { display: 'none' };
    const notifications = this.props.notifications.map((notification) => {
      const appointment = find(appointments, { id: notification.appointmentId });
      const status = appointment.status
        .split('_')
        .map(capitalize)
        .join(' ');

      return (
        <MenuItem
          key={`${notification.appointmentId}-${notification.id}`}
          primaryText={`${appointment.name} is now ${status}`}
          value={`${appointment.user_id}-${notification.id}`}
        />
      );
    }).reverse();

    return (
      <Toolbar style={baseStyles.header}>
        <ToolbarGroup firstChild={true} style={{ width: '100%' }}>
          <Link href="/app" to="/app">
            <img
              alt="Clinic Logo"
              style={baseStyles.logo}
              src={`${config.API_URL}/clinics/${this.props.clinicId}/logo/100`}
              onError={(e) => { e.target.onerror = null; e.target.src = '/img/hana-logo.svg' }}
            />
          </Link>
          <SearchIcon style={baseStyles.searchIcon} viewBox="0 0 19 19" />
          <AutoComplete
            dataSource={searchResults}
            dataSourceConfig={{ text: 'last_first_email', value: 'user_id' }}
            filter={searchFilter}
            fullWidth={true}
            hintText="Search Patient(s)"
            hintStyle={baseStyles.search}
            inputStyle={baseStyles.searchInput}
            maxSearchResults={10}
            onNewRequest={this.onSearchSelect}
            open={this.state.searchOpen}
            openOnFocus={false}
            onUpdateInput={this.updateInput}
            underlineShow={false}
            searchText={this.state.searchText}
          />
        </ToolbarGroup>
        <ToolbarGroup>
          <Button
             children="SCAN QR CODE"
             style={assign({}, baseStyles.btn, baseStyles.scanBtn)}
             onClick={() => {
              logEvent('Select Scan QR'); 
              this.openCheckInModal();
            }}
          />
          <div style={{ width: '200px' }}>
            <Button
              children="SEND AN INVITE"
              style={baseStyles.btn}
              onClick={() => {
                logEvent('Select Invite Patient');
                this.toggleUserInviteDialog();
              }}
            />
          </div>
          <InviteDialog
            userInviteDialog={this.state.userInviteDialog}
            toggleUserInviteDialog={this.toggleUserInviteDialog}
            apiFetch={apiFetch}
            clinicId={this.props.clinicId}
          />
          <Button
            children={(
              <div style={baseStyles.logoutChildren}>
                <div>LOG OUT</div>
                <div style={baseStyles.name}>{lastName}{lastName && firstName ? ', ' : ''}{firstName}</div>
              </div>
            )}
            style={baseStyles.btn}
            onClick={this.toggleUserLogoutDialog}
          />
          <LogoutDialog
            userLogoutDialog={this.state.userLogoutDialog}
            toggleUserLogoutDialog={this.toggleUserLogoutDialog}
            logout={this.props.logout}
          />
          <div style={notificationUnreadStyle} />
          <IconMenu
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            targetOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            iconButtonElement={<IconButton>{notificationBellIcon}</IconButton>}
            onChange={this.onSelectNotification}
            onClick={this.onOpenNotifications}
          >
            {notifications}
          </IconMenu>
        </ToolbarGroup>
      </Toolbar>
    );
  }
}

Header.defaultProps = {
  appointments: [],
  clinicId: null,
  notifications: [],
  router: {},
  firstName: '',
  lastName: '',
};

Header.propTypes = {
  addRecent: PropTypes.func.isRequired,
  appointments: PropTypes.array,
  clinicId: PropTypes.number,
  dismissNotification: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  logout: PropTypes.func.isRequired,
  notifications: PropTypes.array,
  openModal: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

export default withStyles(baseStyles)(Header);
