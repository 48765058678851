import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { noop, map, get, find } from 'lodash';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem'
import Point from '@material-ui/icons/ArrowDropDown';
import { selectClinic } from '../state/clinic';

import HiddenContent from '../components/hidden-content';
import TermsOfUse from '../components/terms';
import { login } from '../actions/user';
import { backgroundImageStyle, colors } from '../lib/styles';
import { logEvent } from '../lib/amplitude';

const yeOldStyleBox = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: colors.black,
  boxShadow: `-1px -1px 1px ${colors.lightGrayText}`,
  border: `.5px solid ${colors.lightGrayText}`,
  background: colors.white,
  verticalAlign: 'middle',
  borderRadius: '1px',
};

const baseStyles = {
  backgroundImg: backgroundImageStyle,
  btnHeight: {
    marginTop: '2rem',
    marginLeft: '2.2rem',
  },
  btnLabel: {
    fontSize: '18px',
    color: colors.white,
  },
  btnStyle: {
    color: 'white',
    backgroundColor: colors.secondaryColor,
    minWidth: '40%',
    alignSelf: 'center',
    fontWeight: 600,
  },
  clinicSelectionError: {
    color: colors.errorOrange,
  },
  copyrightContainer: {
    display: 'flex',
    fontSize: '.6rem',
    marginTop: '1rem',
    justifyContent: 'space-around',
  },
  headerText: {
    display: 'flex',
    flexDirection: 'column',
    color: colors.darkGrayText,
    margin: '0 auto',
    paddingTop: '60px',
    paddingBottom: '25px',
    textAlign: 'center',
    width: '500px',
  },
  field: {
    padding: '10px',
  },
  fieldLabel: {
    color: colors.black,
    fontWeight: 400,
    fontSize: '.9rem'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: '30px auto 0',
    zIndex: 10,
  },
  hanaImg: {
    marginBottom: '2.5rem',
    height: '6rem',
    zIndex: 10,
  },
  inputAlignment: {
    display: 'flex',
    marginBottom: '5px',
    alignItems: 'center',
    fontSize: '.75rem'
  },
  point: {
    position: 'relative',
    fontSize: '45px',
    color: colors.highlightMedium,
  },
  selectedClinic: {
    ...yeOldStyleBox,
    width: '200px',
    height: '30px',
    marginLeft: '10px',
    backgroundColor: colors.white,
  },
  termsContainer: {
    marginTop: '8rem',
    color: colors.black,
    alignSelf: 'center',
    width: '225px',
  },
  terms: {
    fontWeight: 700,
    fontSize: '0.75rem',
  },
  textFieldInput: {
    marginLeft: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  underline: {
    display: 'none',
  },
  white: {
    color: colors.white,
    textAlign: 'center',
  },
};

export class ClinicSelection extends Component {
  constructor(props) {
    super(props);
    const clinics = get(props, 'user.clinics', []);
    this.state = {
      selectedClinic: clinics.length === 1 ? clinics[0].id : null,
      anchorEl: null
    };
    this.handleContinue = this.handleContinue.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const clinics = get(nextProps, 'user.clinics', []);
    if (clinics.length === 1 && !this.state.selectedClinic) {
      this.setState({
        selectedClinic: clinics[0].id,
      });
    }
  }
  handleContinue() {
    logEvent('Select Clinic Continue');
    const { selectedClinic } = this.state;
    if (!selectedClinic) {
      return this.setState({ error: 'Please select a clinic' });
    }

    this.props.selectClinic(this.state.selectedClinic);
    this.props.router.push('/app');
  }
  handleMenuItemClick = (index) => {
    this.setState({ selectedClinic: index, anchorEl: null });
  };
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  }
  handleClose = () => {
    this.setState({ anchorEl: null });
  }
  handleEnter = e => {
    if (e.key === 'Enter') {
      this.handleContinue();
    }
  }

  render() {
    const { error } = this.state;
    const clinics = get(this.props, 'user.clinics', []);
    const items = map(clinics, (eachClinic) => (
      <MenuItem
        key={eachClinic.id}
        disabled={clinics === 0}
        selected={clinics === this.state.selectedClinic}
        className="clinic-selection"
        onClick={() => this.handleMenuItemClick(eachClinic.id)}
      >
        {eachClinic.name}
      </MenuItem>
    ));
    const selectedClinic = find(clinics, { id: this.state.selectedClinic }) || [];
    return (
      <React.Fragment>
        <div style={baseStyles.backgroundImg} />
        <div style={baseStyles.headerText} onKeyDown={this.handleEnter}>
          <img
            alt="Hana Logo"
            src="img/hana-logo.svg"
            style={baseStyles.hanaImg}
          />
          <HiddenContent hidden={!error}>
            <div style={baseStyles.clinicSelectionError}>{error}</div>
          </HiddenContent>
          <div style={baseStyles.form}>
            <div style={baseStyles.inputAlignment}>
              <div style={baseStyles.fieldLabel}>Select Clinic:</div>
              <div
                style={baseStyles.selectedClinic}
                aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
                tabIndex={0}
                onKeyDown={this.handleClick}
                >
                <div style={baseStyles.textFieldInput}>{selectedClinic.name || null}</div>
                <Point style={baseStyles.point} />
              </div>

              <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                open={Boolean(this.state.anchorEl)}
                onKeyPress={this.handleMenuItemClick}
                >
                <MenuItem className="clinic-selection" onClick={() => this.handleMenuItemClick(null)}></MenuItem>)
                {items}
              </Menu>
            </div>
            <Button
              children="Continue"
              style={baseStyles.btnStyle}
              onClick={this.handleContinue}
            />
          </div>
          <TermsOfUse />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

ClinicSelection.defaultProps = {
  login: noop,
  user: {},
};

ClinicSelection.propTypes = {
  router: PropTypes.object,
  user: PropTypes.object,
  selectClinic: PropTypes.func,
};
export default connect(mapStateToProps, { login, selectClinic })(ClinicSelection);
