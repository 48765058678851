import { promiseHandler } from 'cooldux';

const {
  listEnd,
  listHandler,
} = promiseHandler([
  'list',
]);

export function addRecent(user) {
  return dispatch => listHandler(Promise.resolve(user), dispatch);
}


function handleAddRecent(state, action) {
  const newState = { ...state };
  newState.list = state.list.slice();
  newState.list.splice(action.index, 0, action.payload);
  return newState;
}


const initialState = {
  list: [],
};

function recent(state = initialState, action) {
  switch (action.type) {
    case listEnd.type:
      return handleAddRecent(state, action);
    default:
      return state;
  }
}

export default recent;
