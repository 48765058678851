import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, filter, get, capitalize, orderBy, chain } from 'lodash';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles'

import { openModal } from '../state/modal';
import { fetchForms } from '../actions/forms';
import { fetchMigraines } from '../actions/migraines';

import { readUserEmr, editUserEmr } from '../state/emr';
import {
  clearEmrMessage, mergePatientData, handleSkip, unmatchPatient,
  editUser, readUserEhr, readInformedData
} from '../state/merge-data';
import { browseProviders } from '../state/clinic';
import { browseDocuments, browseSignatureRequests, addSignatureRequest } from '../state/esign';
import { browseProSubmissions, createProSubmission } from '../state/pro-submissions';
import { browsePainLocations } from '../state/app-data';
import { browsePainHx } from '../state/pain-hx';
import { createAppointment, fetchAppointments, readPatient, updateAppointment } from '../state/appointments';
import { addRecent } from '../state/recent';

import AppWrapper from '../wrappers/app-wrapper';
import Tabs from '../components/patient-view-tabs';
import Loading from '../components/loading';
import AppointmentOverviewTab from '../components/appointment-overview-tab';
import ReleasesOverviewTab from '../components/releases-overview-tab';
import ProSubmissionsTab from '../components/pro-submissions-overview-tab';
import BodyOverviewTab from '../components/body-overview-tab';
import EmrOverviewTab from '../components/emr-overview-tab';

import openForm from '../lib/open-form';
import { colors } from '../lib/styles';
import { logEvent } from '../lib/amplitude';

const styles = {
  contentStyle: {
    margin: '15px',
    marginBottom: '150px',
    width: '100%',
    minWidth: '768px',
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
  },
  tab: {
    color: colors.highlightDarker,
    fontSize: '.85em',
    fonWeight: 100,
    textTransform: 'capitalize',
  },
  tabTemplate: {
    backgroundColor: colors.primaryHighlight,
  },
  iconTab: {
    display: 'flex',
    alignItems: 'center',
  },
  closeBtn: {
    border: `1px solid ${colors.highlightDark}`,
    boxShadow: 'none',
    margin: '5px',
  },
};

class AppointmentView extends Component {
  state = {
    tab: 'appointment-overview',
  }
  componentWillMount() {
    const { userId } = this.props;
    if(userId){
      this.props.readUserEmr(userId);
      this.props.fetchForms(userId);
      this.props.readPatient(userId);
      this.props.browseSignatureRequests({ user_id: userId });
      this.props.fetchMigraines(userId);
      this.props.browsePainHx(userId);
      this.props.browseProSubmissions(userId);
      this.props.fetchAppointments();
      this.props.browseDocuments();
    } else {
      this.props.router.push('/app');
    }
  }
  componentWillReceiveProps(nextProps) {
    const { userId } = this.props;
    const newUserId = nextProps.userId;
    if (userId !== newUserId) {
      this.props.readUserEmr(newUserId);
      this.props.fetchForms(newUserId);
      this.props.readPatient(newUserId);
      this.props.browseSignatureRequests({ user_id: newUserId });
      this.props.fetchMigraines(newUserId);
      this.props.browsePainHx(newUserId);
      this.props.browseProSubmissions(newUserId);
    }
  }
  componentWillUnmount() {
    this.props.mergePatientData(false);
  }
  handleChangeTab = (evt, tab) => {
    logEvent(`Select ${capitalize(tab)} Tab`);
    this.setState({ tab });
  }
  handleOpenForm = (formName) => {
    openForm(this.props.userId, formName);
  }
  handleOpenAllForms = () => {
    openForm(this.props.userId, 'all');
  }
  handleSendSignatureRequest = (templateId) => {
    this.props.addSignatureRequest(this.props.userId, templateId);
  }
  handleOpenCheckInModal = () => {
    this.props.openModal('checkInModalOpen');
  }
  render() {
    const { handleOpenAllForms, handleOpenForm } = this;
    const { tab } = this.state;
    const { forms, appointments, clinicId, createAppointment, emr, emrInstances, mergeData, updateAppointment, painHx, esign, router, proSubmissions, documentTemplates } = this.props;
    const { userId } = this.props;

    if (emr.isFetching || this.props.migraines.isFetching || appointments.isFetching || painHx.browsePending || forms.isFetching || esign.isFetching) {
      return (
        <AppWrapper router={this.props.router}>
          <div className={this.props.classes.contentStyle}>
            <Loading />
          </div>
        </AppWrapper>
      );
    }

    if (this.props.appointments.error) {
      return (
        <AppWrapper router={this.props.router}>
          <div className={this.props.classes.contentStyle}>
            <p style={{ marginTop: '100px' }}>{this.props.appointments.error.message}</p>
            <Button
              onClick={() => { this.props.router.push('/app'); }}
              className={this.props.classes.closeBtn}
              variant="contained"
            >Return to Dashboard
            </Button>
          </div>
        </AppWrapper>
      );
    }

    const appointment = find(appointments.data, { user_id: userId }) || {};
    const emrPatients = filter(emr.data, { user_id: userId });
    const signatureRequests = esign.signatureRequests.filter(r => r.user_id === userId);
    let unverifiedPatient = false;
    const firstUnverified = find(emrPatients, { status: 'UNVERIFIED' });

    // Short circuits so we don't show any more potential matches after
    // one is matched. Preventing multiple matches per patient.
    if (firstUnverified && !find(emrPatients, { status: 'VERIFIED' })) {
      unverifiedPatient = firstUnverified;
    }

    let painLevel = 'No Pain Recorded';
    const migraines = this.props.migraines[userId];
    const mostRecentMigraine = orderBy(migraines, 'end_time', 'desc')[0];
    const migrainePain = get(mostRecentMigraine, 'pain[0]', null);
    if (migrainePain) {
      const painType = capitalize(migrainePain.pain_type);
      painLevel = `${painType} Pain ● Level ${migrainePain.severity}`;
    }
    const relevantPainHx = chain(painHx.data)
      .map(p => p)
      .filter(p => p.user_id === userId)
      .orderBy('pain_start_date', 'desc')
      .value();
    const patientForms = forms[userId];
    const showEmrData = Boolean(emrInstances.length);
    const generalProps = {
      appointments, clinicId, router, forms: patientForms, appointment, handleOpenForm, handleOpenAllForms, mergeData, updateAppointment, painLevel, onOpenCheckInModal: this.handleOpenCheckInModal, proSubmissions, documentTemplates, onCreateAppointment: createAppointment,  unverifiedPatient, emrInstances, onChangeTab: this.handleChangeTab, userId
    };

    const tabProps = {
      activeTab: tab,
      emrMergeAvailable: this.props.merge,
      onChangeTab: this.handleChangeTab,
      showEmrData: showEmrData,
      showEmrAlert: unverifiedPatient,
    };

    return (
      <AppWrapper router={this.props.router}>
        <div className={this.props.classes.contentStyle}>
          {tab === 'appointment-overview' && (
            <AppointmentOverviewTab
              key={`appt-overview-${userId}`}
              {...generalProps}
            >
              <Tabs {...tabProps} />
            </AppointmentOverviewTab>
          )}
          {tab === 'emr-data' && (
            <EmrOverviewTab
              {...this.props}
              classes={{}}
              onClearEmrMessage={this.props.clearEmrMessage}
              editUserEmr={this.props.editUserEmr}
              emrPatients={emrPatients}
              key={`emr-overview-${userId}`}
              emrMergeAvailable={this.props.merge}
              mergeData={this.props.mergeData}
              mergePatientData={this.props.mergePatientData}
              readUserEhr={this.props.readUserEhr}
              readInformedData={this.props.readInformedData}
              newMatch={this.props.merge}
              {...generalProps}
            >
              <Tabs {...tabProps} />
            </EmrOverviewTab>
          )}
          {tab === 'releases' && (
            <ReleasesOverviewTab
              key={`releases-${userId}`}
              documentTemplates={this.props.documentTemplates}
              signatureRequests={signatureRequests}
              handleSendSignatureRequest={this.handleSendSignatureRequest}
              {...generalProps}
            >
              <Tabs {...tabProps} />
            </ReleasesOverviewTab>
          )}
          {tab === 'pro-submissions' && (
            <ProSubmissionsTab
              key={`submissions-${userId}`}
              proSubmissions={this.props.proSubmissions}
              onCreateProSubmission={this.props.createProSubmission}
              {...generalProps}
            >
              <Tabs {...tabProps} />
            </ProSubmissionsTab>
          )}
          {tab === 'pain-overview' && (
            <BodyOverviewTab
              key={`body-overview-${userId}`}
              migraines={migraines}
              mostRecentMigraine={mostRecentMigraine}
              painLocations={this.props.painLocations}
              painHx={relevantPainHx}
              {...generalProps}
            >
              <Tabs {...tabProps} />
            </BodyOverviewTab>
          )}
        </div>
      </AppWrapper>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const {
    appData,
    appointments,
    clinic,
    emr,
    esign,
    forms,
    mergeData,
    migraines,
    painHx,
    proSubmissions,
    user,
  } = state;
  const userId = get(ownProps, 'location.state.userId');
  const emrInstances = get(find(user.clinics, { id: clinic.clinicId }), 'emr_instances', []);
  const selectedProSubmissions = filter(proSubmissions.data, { user_id: userId }) || [];

  return {
    appointments,
    clinicId: clinic.clinicId,
    documentTemplates: esign.documents,
    emr,
    emrInstances,
    esign,
    forms,
    merge: mergeData.merge,
    mergeData,
    migraines,
    painHx,
    painLocations: appData.painLocations,
    proSubmissions: selectedProSubmissions,
    userId,
  };
}

const actionCreators = {
  addRecent,
  addSignatureRequest,
  browseDocuments,
  browseProviders,
  browseSignatureRequests,
  browsePainLocations,
  browsePainHx,
  browseProSubmissions,
  clearEmrMessage,
  createAppointment,
  createProSubmission,
  editUser,
  editUserEmr,
  fetchForms,
  fetchMigraines,
  fetchAppointments,
  handleSkip,
  mergePatientData,
  openModal,
  readPatient,
  readInformedData,
  readUserEhr,
  readUserEmr,
  unmatchPatient,
  updateAppointment,
};

AppointmentView.defaultProps = {
  appointments: {},
  documentTemplates: [],
  emrInstances: [],
  forms: {},
  migraines: {},
  painHx: {},
  painLocations: {},
};

AppointmentView.propTypes = {
  addSignatureRequest: PropTypes.func.isRequired,
  appointments: PropTypes.object,
  browseSignatureRequests: PropTypes.func.isRequired,
  browsePainHx: PropTypes.func.isRequired,
  clinicId: PropTypes.number,
  createProSubmission: PropTypes.func.isRequired,
  documentTemplates: PropTypes.array,
  editUser: PropTypes.func,
  editUserEmr: PropTypes.func,
  emr: PropTypes.object.isRequired,
  emrInstances: PropTypes.array.isRequired,
  esign: PropTypes.object,
  fetchForms: PropTypes.func.isRequired,
  fetchMigraines: PropTypes.func.isRequired,
  forms: PropTypes.object,
  handleSkip: PropTypes.func,
  merge: PropTypes.bool,
  mergeData: PropTypes.object.isRequired,
  mergePatientData: PropTypes.func,
  migraines: PropTypes.object,
  painLocations: PropTypes.object,
  painHx: PropTypes.object,
  readPatient: PropTypes.func.isRequired,
  readInformedData: PropTypes.func,
  readUserEhr: PropTypes.func,
  readUserEmr: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  updateAppointment: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(AppointmentView));
