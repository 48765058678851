import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import PatientViewContentSection from './patient-view-content-section';
import PatientViewFooter from './patient-view-footer';
import PatientViewHeader from './patient-view-header';
import PatientSummary from './patient-summary';
import PatientSummaryBtns from './patient-summary-btns';
import ReleaseList from './release-list';

import PatientHeaderProgressCircles from './patient-header-progress-circles';
import { logEvent } from '../lib/amplitude';

function ReleasesOverviewTab(props) {
  return (
    <Fragment>
      <PatientViewHeader
        avatarImg={props.appointment.avatarImgLarge}
        centerContent={(
          <PatientSummary
            {...props.appointment}
            painLevel={props.painLevel}
            updateAppointment={props.updateAppointment}
          />
        )}
        centerButtons={(
          <PatientSummaryBtns {...props} />
        )}
        rightContent={(
          <PatientHeaderProgressCircles {...props} />
        )}
      />
      {props.children}
      <PatientViewContentSection>
        <section>
        <ReleaseList
          documentTemplates={props.documentTemplates}
          signatureRequests={props.signatureRequests}
          handleSendSignatureRequest={(templateId) => {
            logEvent('Select Send Signature Request');
            props.handleSendSignatureRequest(templateId);
          }}
        />
        <PatientViewFooter router={props.router} />
      </section>
      </PatientViewContentSection>
    </Fragment>
  );
}

ReleasesOverviewTab.defaultProps = {
  appointment: {},
  documentTemplates: [],
  handleSendSignatureRequest: noop,
  painLevel: '',
  signatureRequests: [],
  updateAppointment: noop,
};

ReleasesOverviewTab.propTypes = {
  appointment: PropTypes.object,
  documentTemplates: PropTypes.array,
  handleSendSignatureRequest: PropTypes.func,
  painLevel: PropTypes.string,
  signatureRequests: PropTypes.array,
  updateAppointment: PropTypes.func,
  router: PropTypes.object,
};

export default ReleasesOverviewTab;
