import moment from 'moment';
import { promiseHandler } from 'cooldux';
import { get, keyBy } from 'lodash';
import { apiFetch } from '../lib/fetch';

const {
  browseProSubmissionsStart,
  browseProSubmissionsEnd,
  browseProSubmissionsError,
  browseProSubmissionsHandler,
} = promiseHandler('browseProSubmissions', 'application');

const {
  createProStart,
  createProEnd,
  createProError,
  createProHandler,
} = promiseHandler('createPro', 'application');

const data = [];
for (let i = 0; i < 50; i++) {
  data.push({
    id: i,
    user_id: '8c8dac88-4bcd-4bb0-b7fc-7ba149a87cf1',
    pro_type: (i % 2) ? 'NDI' : 'HIT 6',
    score: (i % 2) ? 'NDI- 76, Neck Pain 4, R Arm 0, L Arm 3' : '50',
    updated_at: moment().format('MM/DD/YYYY'),
  });
}

export function browseProSubmissions(userId) {
  return (dispatch) => {
    const promise = apiFetch(`/users/${userId}/pro_submissions`)

    return browseProSubmissionsHandler(promise, dispatch);
  };
}

export function createProSubmission(userId, pro_type) {
  return (dispatch, getState) => {
    const clinicId = get(getState(), 'clinic.clinicId');
    const options = {
      method: 'POST',
      body: {
        requested_clinic_id: clinicId,
        pro_type,
      },
    };
    const promise = apiFetch(`/users/${userId}/pro_submissions`, options);

    return createProHandler(promise, dispatch);
  };
}

const initialState = {
  browseProSubmissionsError: null,
  browseProSubmissionsPending: false,
  proSubmissions: {},
};

function finishBrowse(state, response, key, method) {
  return {
    ...state,
    data: {
      ...state.data,
      ...keyBy(response, 'id'),
    },
    [`${method}Error`]: null,
    [`${method}Pending`]: false,
  };
}

function finishCreatePro(state, createdPro) {
  return {
    ...state,
    createProError: false,
    createProPending: false,
    data: {
      ...state.data,
      [createdPro.id]: createdPro,
    }
  };
}


function proSubmissions(state = initialState, { type, payload }) {
  switch (type) {
    case browseProSubmissionsStart.type:
      return { ...state, browseProSubmissionsError: null, browseProSubmissionsPending: true };
    case browseProSubmissionsEnd.type:
      return finishBrowse(state, payload, 'proSubmissions', 'browseProSubmissions');
    case browseProSubmissionsError.type:
      return { ...state, browseProSubmissionsError: payload };
    case createProStart.type:
      return { ...state, createProError: null, createProPending: true };
    case createProEnd.type:
      return finishCreatePro(state, payload);
    case createProError.type:
      return { ...state, createProError: payload, createProPending: false };
    default:
      return state;
  }
}

export default proSubmissions;