import { throttle } from 'lodash';
import { logout } from '../actions/user';

const logoutTime = 25 * 60000; // 25 minutes
const throttleTime = 5 * 60000; // 5 minutes
const inactiveLogoutMsg = 'You have been logged out due to inactivity. Please log back in';

export default function activityInitializer(store) {
  let timer;
  function logoutIfNecessary() {
    const { loggedIn } = store.getState().user;
    clearTimeout(timer);
    timer = setTimeout(logoutIfNecessary, logoutTime);

    if (loggedIn) {
      store.dispatch(logout(inactiveLogoutMsg));
    }
  }

  function resetTimer() {
    clearTimeout(timer);
    timer = setTimeout(logoutIfNecessary, logoutTime);
  }

  const throttledReset = throttle(resetTimer, throttleTime, {
    leading: true,
    trailing: true,
  });

  window.onload = throttledReset;
  document.onmousemove = throttledReset;
  document.onmousedown = throttledReset;
  document.ontouchstart = throttledReset;
  document.onclick = throttledReset;
  document.onscroll = throttledReset;
  document.onkeypress = throttledReset;
}
