import { get, forEach, map, omit, isEmpty, reject, reduce, uniqueId } from 'lodash';

export function stateFormat(social, emrSocialRecords) {

  const observations = reduce(reject(emrSocialRecords.Observations, (social) => social.Name === ''), (all, item) => {
    const id = uniqueId('emr-soc'); // No unique id is returned by redox for an entry
    all[id] = {
      id,
      snomed_code: get(item, 'Code') || null,
      name: get(item, 'Name') || '',
    };
    return all;
  }, {});

  const emrSocial = reduce(reject(emrSocialRecords.TobaccoUse, (social) => social.Name === ''), (all, item) => {
    const id = uniqueId('emr-soc'); // No unique id is returned by redox for an entry
    all[id] = {
      id,
      snomed_code: get(item, 'Code') || null,
      name: get(item, 'Name') || '',
    };
    return all;
  }, observations);

  const mindset = {};
  if (social[0]) {
    forEach(social, (item, key) => {
      mindset[`mindset${key}`] = {
        social_hx_id: item.id,
        social_id: item.social_id,
        name: item.name,
        summary: item.summary,
        frequency: item.frequency,
      };
    });
  }
  return {
    combined: mindset,
    mindset,
    emrSocial,
  };
}

export function mergeFormat(combined) {
  if (isEmpty(combined)) {
    return {};
  }
  return {
    social: map(combined, (item) => {
      return omit(item, ['id', 'checked']);
    }),
  };
}
