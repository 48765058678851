import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { capitalize, noop } from 'lodash';

import PatientSummaryItem from './patient-summary-item';

import { colors } from '../lib/styles';

const baseStyles = {
  container: {
    marginLeft: '15px',
  },
  btn: {
    backgroundColor: colors.secondaryColor,
    color: colors.white,
    width: '175px',
  },
  items: {
    alignItems: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: colors.primaryColor,
    marginLeft: '1rem',
    maxWidth: '20rem',
  },
  itemsAlign: {
    display: 'flex',
    marginBottom: '10px',
    width: '100%',
    justifyContent: 'start',
    flexWrap: 'wrap',
  },
  name: {
    color: colors.primaryColor,
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '1.5rem',
  },
  statusMenu: {
    lineHeight: 'normal',
    height: '20px',
  },
  statusMenuIcon: {
    fill: colors.highlightDarker,
    right: '21px',
    top: '-14px',
  },
  statusMenuLabel: {
    color: colors.charcoal,
    lineHeight: 'normal',
    paddingLeft: '5px',
  },
  statusMenuUnderline: {
    lineHeight: 'normal',
    margin: '0',
  },
};


class PatientSummary extends Component {
  render() {
    const { birth_date, sex, first_name, last_name, primary_insurance_name, last_appointment_time  } = this.props;

    const birthday = moment(birth_date).format('MM/DD/YYYY');

    let lastAppointment = 'None';
    if (last_appointment_time) {
      lastAppointment = moment(last_appointment_time).format('MM/DD/YYYY');
    }

    return (
      <div style={baseStyles.container}>
        <div style={baseStyles.name}>{last_name}, {first_name}</div>
        <div style={baseStyles.itemsAlign}>
          <div style={baseStyles.items}>
            <PatientSummaryItem title="Sex" value={capitalize(sex)} />
            <PatientSummaryItem title="Date of Birth" value={birthday} />
          </div>
          <div style={baseStyles.items}>
            <PatientSummaryItem title="Insurance" value={primary_insurance_name} />
            <PatientSummaryItem title="Last Visit" value={lastAppointment} />
          </div>
        </div>
      </div>
    );
  }
}

PatientSummary.defaultProps = {
  birth_date: '',
  sex: '',
  id: null,
  first_name: '',
  last_name: '',
  painLevel: '',
  status: '',
  updateAppointment: noop,
};

PatientSummary.propTypes = {
  birth_date: PropTypes.string,
  sex: PropTypes.string,
  id: PropTypes.number,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  painLevel: PropTypes.string,
  status: PropTypes.string,
  updateAppointment: PropTypes.func,
};

export default PatientSummary;
