import { promiseHandler, makeActionCreator } from 'cooldux';
import { assign, get, find, isNil, omitBy } from 'lodash';
import { LOGOUT } from '../actions/user';
import { apiFetch } from '../lib/fetch';
import formatAppointment from '../lib/format-appointment';

const { fetchAppointmentsStart, fetchAppointmentsEnd,
  fetchAppointmentsError, fetchAppointmentsHandler } = promiseHandler('fetchAppointments', 'appointments');
const { fetchPatientsStart, fetchPatientsEnd,
  fetchPatientsError, fetchPatientsHandler } = promiseHandler('fetchPatients', 'patients');
const { readPatientsStart, readPatientsEnd,
  readPatientsError, readPatientsHandler } = promiseHandler('readPatients', 'appointments');
const { updateAppointmentsStart, updateAppointmentsEnd,
  updateAppointmentsError, updateAppointmentsHandler } = promiseHandler('updateAppointments', 'appointments');
const { scanPatientHandler, scanPatientReducer } = promiseHandler('scanPatient', 'appointments');
const { createAppointmentHandler } = promiseHandler('createAppointment', 'appointments');
const addAppointmentAC = makeActionCreator('addAppointment');

export const clearPatientScanner = makeActionCreator('clearPatientScanner', 'appointments');

export function addAppointment(appointment) {
  return function dispatcher(dispatch) {
    const formattedAppointment = formatAppointment(appointment);
    dispatch(addAppointmentAC(formattedAppointment));
  };
}

export function scanPatient(rawValue, successCb) {
  return function dispatcher(dispatch, getState) {
    const clinicId = get(getState(), 'clinic.clinicId', null);
    const promise = new Promise((resolve, reject) => {
      const qr = rawValue.split('/')[2];
      if(!qr) {
        return reject(new Error('Invalid QR code'));
      }
      resolve(apiFetch(`/clinics/${clinicId}/appointments/${qr}`, { method: 'POST' })
        .then(data => {
          successCb();
          return data;
        })
      )
    });

    return scanPatientHandler(promise, dispatch);
  };
}

export function createAppointment(user_id) {
  return function dispatcher(dispatch, getState) {
    const clinicId = get(getState(), 'clinic.clinicId', null);

    // In case we've updated the clinician(provider_id) locally
    // before an appointment was created we send that up as well.
    const appointment = getState().appointments.data.find(a => a.user_id === user_id) || {};
    const opts = {
      body: omitBy({
        provider_id: appointment.provider_id,
        user_id,
      }, isNil),
      method: 'POST'
    }

    const fetchRes = apiFetch(`/clinics/${clinicId}/appointments`, opts)

    return createAppointmentHandler(fetchRes, dispatch);
  };
}

export function fetchAppointments() {
  return function dispatcher(dispatch, getState) {
    const clinicId = get(getState(), 'clinic.clinicId', null);
    const fetchRes = apiFetch(`/clinics/${clinicId}/appointments`, { method: 'GET' })
      .then(data => (data.map(formatAppointment)));

    return fetchAppointmentsHandler(fetchRes, dispatch);
  };
}

export function fetchPatients() {
  return function dispatcher(dispatch, getState) {
    const clinicId = get(getState(), 'clinic.clinicId', null);
    const fetchRes = apiFetch(`/clinics/${clinicId}/patients`, { method: 'GET' })
      .then(data => (data.map(formatAppointment)));

    return fetchPatientsHandler(fetchRes, dispatch);
  };
}

export function readPatient(userId) {
  return function dispatcher(dispatch, getState) {
    const clinicId = get(getState(), 'clinic.clinicId', null);
    const fetchRes = apiFetch(`/clinics/${clinicId}/patients/${userId}`)
      .then(data => (formatAppointment(data)));
    return readPatientsHandler(fetchRes, dispatch);
  };
}

export function dismissScanError() {
  return (dispatch, getState) => {
    dispatch({ type: 'DISMISS_SCAN_ERROR' });
  };
}

export function updateAppointment(appointmentId, update) {
  return function dispatcher(dispatch, getState) {
    const appointment = getState().appointments.data.find(a => a.id === appointmentId);
    const clinicId = get(getState(), 'clinic.clinicId', null);
    const requestOptions = {
      method: 'PUT',
      body: update,
    };
    const fetchRes = apiFetch(`/clinics/${clinicId}/appointments/${appointmentId}`, requestOptions)
      .then(data => (assign({}, appointment, data)));
    return updateAppointmentsHandler(fetchRes, dispatch);
  };
}


export function updatePatientLocally(userId, update) {
  return function dispatcher(dispatch, getState) {
    const appointment = getState().appointments.data.find(a => a.user_id === userId) || {};
    const fetchRes = Promise.resolve({ ...appointment, user_id: userId, ...update });
    return updateAppointmentsHandler(fetchRes, dispatch);
  };
}

const initialState = {
  isFetching: false,
  data: [],
  error: false,
  scanPatientError: null,
  totalPatientsOnServer: 0,
};


// This will combine the appointment and patient data if an appointment exists
// to ensure fields are updated without overwriting appointment specific fields
// In the future it might be benefificial to move patients into their own reducer
function readPatientRes(state, patientUpdate) {
  const newState = { ...state, isFetching: false, error: false };
  const existingAppointment = find(state.data, { user_id: patientUpdate.user_id });
  const newUpdate = assign({}, existingAppointment, patientUpdate);

  newState.data = [...(state.data.filter(p => p.user_id !== patientUpdate.user_id)), newUpdate];
  return newState;
}

function updateAppointmentRes(state, patientUpdate) {
  const newState = { ...state };
  newState.data = [...(state.data.filter(p => p.user_id !== patientUpdate.user_id)), patientUpdate];
  return newState;
}


function finishFetchAppointmentsEnd(oldState, newData) {
  const newIds = newData.map(i => i.user_id);
  const state = {
    ...oldState,
    isFetching: false,
    data: [...(oldState.data.filter(a => !newIds.includes(a.user_id))), ...newData]
  }

  return state;
}

function finishFetchPatientsEnd(oldState, newData) {
  const newIds = newData.data.map(i => i.user_id);
  const state = {
    ...oldState,
    isFetching: false,
    totalPatientsOnServer: newData.totalRecords,
    data: [...(oldState.data.filter(a => !newIds.includes(a.user_id))), ...newData]
  }

  return state;
}


function appointments(state = initialState, action) {
  state = scanPatientReducer(state, action);
  switch (action.type) {
    case clearPatientScanner.type:
      return { ...state, scanPatientPending: false, scanPatientError: null };
    case fetchPatientsStart.type:
      return { ...state, isFetching: true, error: false, totalPatientsOnServer: 0 };
    case fetchAppointmentsStart.type:
      return { ...state, isFetching: true, error: false };
    case fetchAppointmentsEnd.type:
      return finishFetchAppointmentsEnd(state, action.payload);
    case fetchPatientsEnd.type:
      return finishFetchPatientsEnd(state, action.payload);
    case fetchPatientsError.type:
    case fetchAppointmentsError.type:
      return { ...state, isFetching: false };
    case readPatientsStart.type:
      return { ...state, isFetching: true, error: false };
    case readPatientsEnd.type:
      return readPatientRes(state, action.payload);
    case readPatientsError.type:
      return { ...state, isFetching: false, error: action.payload };
    case updateAppointmentsStart.type:
    case updateAppointmentsError.type:
      return { ...state };
    case addAppointmentAC.type:
    case updateAppointmentsEnd.type:
      return updateAppointmentRes(state, action.payload);
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}

export default appointments;
