import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { capitalize, get, filter } from 'lodash';

import Card from '@material-ui/core/Card';
import CardTitle from '@material-ui/core/CardHeader';
import CardText from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles'
import EmrData from './emr-data-field';

import { blueBtnStyle, colors } from '../lib/styles';

const styles = {
  btnContainer: {
    display: 'flex',
    margin: '0 10px',
  },
  cardText: {
    paddingTop: '0px',
  },
  cardWidth: {
    border: '1px solid #797979',
    boxShadow: '2px 2px 1px rgba(0, 0, 0, 0.349019607843137)',
    minWidth: '325px',
    width: '45%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  comparisonGrid: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  cardTitleStyle: {
    fontWeight: 600,
    fontSize: '18px',
    marginBottom: '0px',
    textAlign: 'center',
  },
  emrData: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px',
  },
  emrFieldData: {
    marginLeft: '10px',
    textAlign: 'left',
  },
  fieldTitle: {
    alignContent: 'right',
    textAlign: 'right',
    minWidth: '40%',
  },
  subtext: {
    marginTop: '50px',
  },
  title: {
    color: colors.primaryColor,
    fontWeight: 600,
    fontSize: '20px',
  },
  titleSection: {
    textAlign: 'left',
    marginTop: '10px',
    width: '50%',
  },
  topSection: {
    margin: '10px 0px 5px 30px',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

class VerifyEmrPatient extends Component {
  constructor(props) {
    super(props);
    this.handleRejectEmr = this.handleEditUserEmr.bind(this, 'REJECTED');
    this.handleAcceptEmr = this.handleEditUserEmr.bind(this, 'VERIFIED');
  }
  handleEditUserEmr(status) {
    const { user_id } = this.props.appointment;
    const totalUnverified = filter(this.props.emrPatients, { status: 'UNVERIFIED' }).length;
    this.props.onEditUserEmr(user_id, this.props.unverifiedPatient.id, { status })
      .then(() => {
        if(status === 'REJECTED' && totalUnverified < 2) {
          this.props.onChangeTab(null, 'appointment-overview');
        }
      });
  }
  render() {
    const { appointment, emrInstance, unverifiedPatient } = this.props;
    const emrDemo = get(unverifiedPatient, 'query_results.Demographics', {});
    return (
      <div style={styles.container}>
        <div style={styles.topSection}>
          <div style={styles.titleSection}>
            <div style={styles.title}>Potential EMR Match</div>
            <div style={styles.subtext}>Is this the same patient?</div>
          </div>
          <div style={styles.btnContainer}>
            <Button
              children="Different Patient"
              style={blueBtnStyle}
              onClick={this.handleRejectEmr}
            />
            <Button
              children="Confirm Same Patient"
              style={blueBtnStyle}
              onClick={this.handleAcceptEmr}
            />
          </div>
        </div>
        <div style={styles.comparisonGrid}>
          <Card style={styles.cardWidth}>
            <CardTitle
              classes={{ title: this.props.classes.cardTitleStyle}}
              title="Hana Patient"
            />
            <CardText style={styles.cardText}>
              <EmrData field="Name" value={appointment.name} />
              <EmrData field="DOB" value={appointment.birth_date} />
              <EmrData field="Email" value={appointment.email} />
              <EmrData field="Home Phone" value={appointment.home_phone} />
              <EmrData field="Work Phone" value={appointment.work_phone} />
              <EmrData field="Cell Phone" value={appointment.cell_phone} />
              <EmrData field="Sex" value={capitalize(appointment.sex)} />
              <EmrData field="Marital Status" value={capitalize(appointment.marital_status)} />
              <EmrData field="Street Address" value={appointment.address} />
              <EmrData field="City" value={appointment.city} />
              <EmrData field="State" value={appointment.state} />
              <EmrData field="Zip" value={appointment.zip_code} />
            </CardText>
          </Card>
          <Card style={styles.cardWidth}>
            <CardTitle
              classes={{ title: this.props.classes.cardTitleStyle}}
              title={`${emrInstance.name} Patient`}
            />
            <CardText style={styles.cardText}>
              <EmrData field="Name" value={`${emrDemo.FirstName} ${emrDemo.LastName}`} />
              <EmrData field="DOB" value={emrDemo.DOB} />
              <EmrData field="Emails" value={emrDemo.EmailAddresses.join(', ')} />
              <EmrData field="Home Phone" value={get(emrDemo, 'PhoneNumber.Home', '')} />
              <EmrData field="Work Phone" value={get(emrDemo, 'PhoneNumber.Office', '')} />
              <EmrData field="Cell Phone" value={get(emrDemo, 'PhoneNumber.Mobile', '')} />
              <EmrData field="Sex" value={capitalize(emrDemo.Sex)} />
              <EmrData field="Marital Status" value={capitalize(emrDemo.MaritalStatus)} />
              <EmrData field="Street Address" value={get(emrDemo, 'Address.StreetAddress', '')} />
              <EmrData field="City" value={get(emrDemo, 'Address.City', '')} />
              <EmrData field="State" value={get(emrDemo, 'Address.State', '')} />
              <EmrData field="Zip" value={get(emrDemo, 'Address.ZIP', '')} />
            </CardText>
          </Card>
        </div>
      </div>
    );
  }
}


VerifyEmrPatient.defaultProps = {
  appointment: {},
  modalUserId: null,
  patientModalOpen: false,
};

VerifyEmrPatient.propTypes = {
  appointment: PropTypes.object,
  emrInstance: PropTypes.object.isRequired,
  onEditUserEmr: PropTypes.func.isRequired,
  unverifiedPatient: PropTypes.object.isRequired,
};

export default withStyles(styles)(VerifyEmrPatient);
