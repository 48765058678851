import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { chain } from 'lodash';

import { logout } from '../actions/user';
import { dismissNotification } from '../actions/notifications';
import { openModal, closeModal } from '../state/modal';
import { browsePainLocationsIfNeeded } from '../state/app-data';

import { browseProviders } from '../state/clinic';
import { dismissScanError, fetchAppointments } from '../state/appointments';
import { addRecent } from '../state/recent';

import CheckIn from '../components/check-in';
import Header from '../components/header';
import Sidebar from '../components/sidebar';
import { colors } from '../lib/styles';

const baseStyles = {
  container: {
    backgroundColor: colors.highlightBright,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    textAlign: 'center',
    width: '100%',
    
  },
  mainBody: {
    display: 'flex',
    flex: 1,
    height: '100%',
    width: '100%',
  },
};

class AppWrapper extends Component {
  componentWillMount() {
    this.props.browsePainLocationsIfNeeded();
    this.props.fetchAppointments();
    this.props.browseProviders();
  }
  handleCloseError = () => {
    this.props.dismissScanError();
  }
  render() {
    const {
      addRecent, appFeatures, appointments, dismissNotification, logout,
      notifications, openModal, recentPatients, router, user,
    } = this.props;

    return (
      <div style={baseStyles.container}>
        <Header
          addRecent={addRecent}
          clinicId={this.props.clinicId}
          dismissNotification={dismissNotification}
          logout={logout}
          notifications={notifications}
          openModal={openModal}
          appointments={appointments.data}
          router={router}
          firstName={user.first_name}
          lastName={user.last_name}
        />
        <div style={baseStyles.mainBody}>
          <Sidebar
            appFeatures={appFeatures}
            recent={recentPatients}
            router={router}
          />
          <CheckIn />
          {this.props.children}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    appointments,
    clinic,
    notifications,
    recent,
    modal,
    user
  } = state;

  const sortedNotifications = chain(notifications)
    .map(notification => notification)
    .sortBy('receivedAt')
    .value();


  return {
    appFeatures: clinic.data.app_features,
    appointments,
    clinicId: clinic.clinicId,
    notifications: sortedNotifications,
    providers: clinic.providers,
    recentPatients: recent,
    modal,
    user
  };
}

const actionCreators = {
  addRecent,
  browsePainLocationsIfNeeded,
  browseProviders,
  closeModal,
  dismissNotification,
  dismissScanError,
  fetchAppointments,
  logout,
  openModal,
};

AppWrapper.defaultProps = {
  appointments: {
    data: [],
    isFetching: false,
  },
  modal: {},
  notifications: [],
  providers: [],
  signatureRequests: [],
  router: {},
  recentPatients: {},
};

AppWrapper.propTypes = {
  appFeatures: PropTypes.array.isRequired,
  addRecent: PropTypes.func.isRequired,
  appointments: PropTypes.shape({
    data: PropTypes.array,
    isFetching: PropTypes.bool,
  }),
  browsePainLocationsIfNeeded: PropTypes.func.isRequired,
  browseProviders: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  clinicId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  dismissNotification: PropTypes.func.isRequired,
  fetchAppointments: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  notifications: PropTypes.array,
  modal: PropTypes.object,
  recentPatients: PropTypes.object,
  router: PropTypes.object,
};

export default connect(mapStateToProps, actionCreators)(AppWrapper);
