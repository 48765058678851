import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import Button from '@material-ui/core/Button';

import PatientViewContentSection from './patient-view-content-section';
import PatientViewHeader from './patient-view-header';
import PatientSummary from './patient-summary';
import PatientSummaryBtns from './patient-summary-btns';
import FormList from './form-list';
import { withStyles } from '@material-ui/core/styles';

import { blueBtnStyle, colors } from '../lib/styles';
import PatientHeaderProgressCircles from './patient-header-progress-circles';
import { logEvent } from '../lib/amplitude';

const styles = {
  bottomBackground: {
    backgroundColor: colors.white,
  },
  bottomIcon: {},
  bottomBtnContainer: {
    padding: '3px 10px 10px',
    backgroundColor: colors.white,
  },
  bottomBtnFirstRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bottomBtnSecondRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btn: blueBtnStyle,
  btnLabel: {
    textTransform: 'capitalize',
  },
  printAllBtn: {
    boxShadow: 'none',
    margin: '5px',
    width: '200px',
  },
  reviewAllBtn: {
    boxShadow: 'none',
    margin: '5px',
    width: '200px',
  },
  reviewAllLabel: {
    padding: '10px',
    width: '200px',
  },
  summary: {
    flex: 1,
    minWidth: '250px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  summaryBtns: {
    display: 'flex',
    flexDirection: 'row',
  },
  topSectionIcon: {
    color: colors.primaryAccent,
    height: '18px',
    width: '18px',
  },
  waitTime: {
    padding: '10px 0px 0px 10px',
  },
};

class AppointmentOverviewTab extends Component {
  render() {
    const { props } = this;
    const { classes, handleOpenAllForms, handleOpenForm } = props;
  
    return (
      <Fragment>
        <PatientViewHeader
          avatarImg={props.appointment.avatarImgLarge}
          centerContent={(
            <PatientSummary
              {...props.appointment}
              updateAppointment={props.updateAppointment}
            />
            )}
            centerButtons={(
              <PatientSummaryBtns {...props} />
            )}
            rightContent={(
              <PatientHeaderProgressCircles {...props} />
            )}
          />
          {props.children}
          <PatientViewContentSection>
            <section>
              <Button
                className={classes.btn}
                onClick={() => {
                  logEvent('Select Review Forms');
                  handleOpenAllForms();
                }}
                variant="contained"
                >
                Review Form(s)
              </Button>
            </section>
            <FormList
              forms={props.forms}
              onOpenForm={handleOpenForm}
            />
          </PatientViewContentSection>
        </Fragment>
    );
  }
}

AppointmentOverviewTab.defaultProps = {
  appointment: {
    waitTime: '00:00',
  },
  closeAppointmentView: noop,
  forms: {},
  handleOpenAllForms: noop,
  handleOpenForm: noop,
  painLevel: '',
  updateAppointment: noop,
};

AppointmentOverviewTab.propTypes = {
  appointment: PropTypes.object,
  router: PropTypes.object,
  forms: PropTypes.object,
  handleOpenAllForms: PropTypes.func,
  handleOpenForm: PropTypes.func,
  painLevel: PropTypes.string,
  updateAppointment: PropTypes.func,
};

export default withStyles(styles)(AppointmentOverviewTab)
