import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { noop } from 'lodash';

import { addRecent } from '../state/recent';
import { updateAppointment } from '../state/appointments';
import AppWrapper from '../wrappers/app-wrapper';

import AppointmentList from '../components/appointment-list';
import memoize from 'memoize-one';

import { colors } from '../lib/styles';

const baseStyles = {
  filters: {
    color: colors.white,
    float: 'right',
    minWidth: '432px',
  },
  filterDropDown: {
    border: `1px solid ${colors.highlightDark}`,
    borderRadius: '4px',
    height: '48px',
    margin: '0px 10px',
    textAlign: 'left',
    verticalAlign: 'bottom',
    width: '150px',
  },
  filterDropDownIcon: {
    color: colors.highlightDark,
  },
  filterBtn: {
    height: '48px',
  },
  patientLists: {
    padding: '25px',
    width: '100%',
    marginBottom: '150px',
  },
};

function filterAppointments(appointments, filters) {
  const filterByClinician = !(filters.clinicianFilter === 'Clinicians');
  const filterbyMetric = !(filters.metricsFilter === 'Metrics');

  // Remove any non-appointment pre-auths from the appointment list;
  let filteredAppointments = appointments.filter(p => ('checked_in' in p));

  if (!filterByClinician && !filterbyMetric) {
    return filteredAppointments;
  }

  if (filterByClinician) {
    filteredAppointments = filteredAppointments.filter((p) => {
      return p.provider_id === filters.clinicianFilter;
    });
  }
  return filteredAppointments;
}

export class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      clinicianFilter: 'Clinicians',
      metricsFilter: 'Metrics',
      form: false,
    };
  }
  
  updateClinicianFilter = (evt, key, clinicianFilter) => {
    this.setState({ clinicianFilter });
  }
  filterList = memoize(
    ((appointments, clinicianFilter, metricsFilter) => (
      filterAppointments(appointments, { clinicianFilter, metricsFilter })
    ))
  )
  render() {
    const { clinicianFilter, metricsFilter } = this.state;
    const {
      addRecent, appointments, providers, updateAppointment,
    } = this.props;
    const filteredAppointments = this.filterList(appointments.data, clinicianFilter, metricsFilter);

    return (
      <AppWrapper router={this.props.router}>
        <div style={baseStyles.patientLists}>
          <AppointmentList
            addRecent={addRecent}
            id="CheckedIn"
            title="Checked In"
            isFetching={appointments.isFetching}
            providers={providers}
            appointments={filteredAppointments}
            updateAppointment={updateAppointment}
            waitText="Wait Time"
            waitField="checked_in"
            router={this.props.router}
          />
        </div>
      </AppWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {
    clinic, esign, appointments,
  } = state;

  return {
    providers: clinic.providers,
    documentTemplates: esign.documents,
    appointments,
  };
}

const actionCreators = {
  addRecent,
  updateAppointment,
};

App.defaultProps = {
  appointments: {
    data: [],
    isFetching: false,
  },
  documentTemplates: [],
  providers: [],
  updateAppointment: noop,
};

App.propTypes = {
  addRecent: PropTypes.func.isRequired,
  appointments: PropTypes.shape({
    data: PropTypes.array,
    isFetching: PropTypes.bool,
  }),
  documentTemplates: PropTypes.array,
  providers: PropTypes.array,
  updateAppointment: PropTypes.func,
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, actionCreators)(App);
