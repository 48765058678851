import React from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';

import CircularProgress from 'material-ui/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormListItem from './form-list-item';

import { colors, tableStyles } from '../lib/styles';
import { logEvent } from '../lib/amplitude';

const styles = {
  container: {
    border: `2px solid ${colors.highlightBright}`,
    margin: '10px',
  },
  loading: {
    display: 'flex',
    margin: '10px auto',
    textAlign: 'center',
  },
  summary: {
    ...tableStyles.cell,
    minWidth: '200px',
  },
  tbody: {
    height: '200px',
  },
  table: {
    ...tableStyles.table,
  },
  tableHeadCell: {
    ...tableStyles.head,
    fontSize: '1rem',
  },
  tableHeader: {
    ...tableStyles.head,
  },
};


function FormList(props) {
  const containerStyle = Object.assign({}, styles.container, props.containerStyle);
  const formRows = map(props.forms, (form) => {

    return (
      <FormListItem
        key={`${form.name}`}
        form={form}
        hideCompletion={props.hideCompletion}
        hidePrint={props.hidePrint}
        hideSummary={props.hideSummary}
        onOpenForm={(formName) => {
          logEvent(`Select ${form.display_name} Form`);
          props.onOpenForm(formName);
        }}
        style={styles.row}
      />
    );
  });

  return (
    <div style={containerStyle}>
      {!formRows.length && (<CircularProgress size={150} style={styles.loading} thickness={7} />)}
      <Table
        style={styles.table}
      >
      <TableHead
        style={styles.tableHeader}
        >
        <TableRow>
          <TableCell style={styles.tableHeadCell} tooltip="Form Name">Form</TableCell>
          <TableCell
            style={styles.tableHeadCell}
            tooltip="Percentage Complete"
            >% Complete
          </TableCell>
          <TableCell style={styles.summary} tooltip="Summary">Summary</TableCell>
        </TableRow>
      </TableHead>
      <TableBody
        style={styles.tbody}
      >
        {formRows}
      </TableBody>
      </Table>
    </div>
  );
}

FormList.defaultProps = {
  containerStyle: {},
  forms: {},
  onOpenForm: noop,
  hideCompletion: false,
  hideSummary: false,
};

FormList.propTypes = {
  containerStyle: PropTypes.object,
  forms: PropTypes.object,
  onOpenForm: PropTypes.func,
  hideCompletion: PropTypes.bool,
  hideSummary: PropTypes.bool,
};

export default FormList;
