import { promiseHandler, makeActionCreator } from 'cooldux';
import { get, drop, indexOf, pullAt, assign, isEmpty } from 'lodash';

import { apiFetch } from '../lib/fetch';
import { defaultMergeStatus, setUserEmrMergeStatus } from './emr';

const startMergePatientData = makeActionCreator('startMergePatientData');
const startClearEmrMessage = makeActionCreator('merge');
const addSkip = makeActionCreator('addSkip');
const { readEhrStart, readEhrEnd, readEhrError, readEhrHandler } = promiseHandler('readEhr', 'merge');
const { readInformedStart, readInformedEnd, readInformedError, readInformedHandler } = promiseHandler('readInformed', 'merge');
const { editUserStart, editUserEnd, editUserError, editUserHandler } = promiseHandler('editUser', 'merge');
const { pushCCDStart, pushCCDEnd, pushCCDError, pushCCDHandler } = promiseHandler('pushCCD', 'merge');
const { unmatchPatientStart, unmatchPatientEnd, unmatchPatientError, unmatchPatientHandler } = promiseHandler('unmatchPatient', 'merge');


export function clearEmrMessage() {
  return function dispatcher(dispatch, getState) {
    dispatch(startClearEmrMessage());
  };
}

export function mergePatientData(val, emrId) {
  return function dispatcher(dispatch, getState) {
    dispatch(startMergePatientData(val));
    if(!val) {
      dispatch(setUserEmrMergeStatus(emrId, defaultMergeStatus));
    }
  };
}

export function handleSkip(merge_status, page) {
  return function dispatcher(dispatch) {
    const left = drop(merge_status, page + 1);
    const newPage = indexOf(left, false) + page + 1;
    dispatch(addSkip({ left, newPage }));
  };
}

export function readUserEhr(userId, merge_status) {
  return function dispatcher(dispatch, getState) {
    const clinicId = get(getState(), 'clinic.clinicId');
    const fetchRes = apiFetch(`/clinics/${clinicId}/users/${userId}/ehr_record_search`)
      .then(data => ({ userId, data, merge_status }));

    return readEhrHandler(fetchRes, dispatch);
  };
}

export function readInformedData(userId) {
  return function dispatcher(dispatch, getState) {
    const clinicId = get(getState(), 'clinic.clinicId');
    const fetchRes = apiFetch(`/clinics/${clinicId}/users/${userId}/informed_record_search`)
      .then(data => ({ userId, data }));

    return readInformedHandler(fetchRes, dispatch);
  };
}

export function pushCCD(userId, emrId) {
  return function dispatcher(dispatch, getState) {
    const clinicId = get(getState(), 'clinic.clinicId');
    const fetchRes = apiFetch(`/clinics/${clinicId}/push_clinical_summary`, { method: 'POST', body: { user_id: userId } })
      .then(() => dispatch(setUserEmrMergeStatus(emrId, defaultMergeStatus)))
      .then(() => ({ userId }));

    return pushCCDHandler(fetchRes, dispatch);
  };
}

export function unmatchPatient(userId, id) {
  return function dispatcher(dispatch, getState) {
    const clinicId = get(getState(), 'clinic.clinicId');
    const fetchRes = apiFetch(`/clinics/${clinicId}/users/${userId}/emr_patients/${id}`, { method: 'PUT', body: { status: 'REJECTED' } })
      .then(() => ({ userId }));

    return unmatchPatientHandler(fetchRes, dispatch);
  };
}

export function editUser(emrPatient, data, page, endpoint) {
  return function dispatcher(dispatch, getState) {
    const { user_id, id, merge_status } = emrPatient;
    const mergeStatus = [...merge_status];
    const otherPages = assign([], mergeStatus);
    pullAt(otherPages, page);
    const left = drop(mergeStatus, page + 1);
    const clinicId = get(getState(), 'clinic.clinicId');
    const opts = {
      method: 'PUT',
      body: data,
    };

    const new_status = mergeStatus;
    new_status[page] = true;
    dispatch(setUserEmrMergeStatus(id, new_status));

    if (isEmpty(data)) {
      if (indexOf(otherPages, false) === -1) {
        const fetchRes = apiFetch(`/clinics/${clinicId}/users/${user_id}/emr_patients/${id}`, { method: 'PUT', body: { merge_status: new_status } })
          .then(() => dispatch(pushCCD(user_id, id)))
          .then(() => ({ merge: false }));

        return editUserHandler(fetchRes, dispatch);
      }

      if (page === 7 || indexOf(left, false) === -1) {
        const fetchRes = apiFetch(`/clinics/${clinicId}/users/${user_id}/emr_patients/${id}`, { method: 'PUT', body: { merge_status: new_status } })
          .then(() => ({ merge: false }));

        return editUserHandler(fetchRes, dispatch);
      }

      const fetchRes = apiFetch(`/clinics/${clinicId}/users/${user_id}/emr_patients/${id}`, { method: 'PUT', body: { merge_status: new_status } })
        .then(() => ({ page: indexOf(new_status, false)}));

      return editUserHandler(fetchRes, dispatch);
    }

    if (indexOf(otherPages, false) === -1) {
      const fetchRes = apiFetch(`/clinics/${clinicId}/users/${user_id}/${endpoint}`, opts)
        .then(() => apiFetch(`/clinics/${clinicId}/users/${user_id}/emr_patients/${id}`, { method: 'PUT', body: { merge_status: new_status } }))
        .then(() => dispatch(pushCCD(user_id, id)))
        .then(() => ({ merge: false }));

      return editUserHandler(fetchRes, dispatch);
    }

    if (page === 7 || indexOf(left, false) === -1) {
      const fetchRes = apiFetch(`/clinics/${clinicId}/users/${user_id}/${endpoint}`, opts)
        .then(() => apiFetch(`/clinics/${clinicId}/users/${user_id}/emr_patients/${id}`, { method: 'PUT', body: { merge_status: new_status } }))
        .then(() => ({ merge: false }));

      return editUserHandler(fetchRes, dispatch);
    }

    const fetchRes = apiFetch(`/clinics/${clinicId}/users/${user_id}/${endpoint}`, opts)
      .then(() => apiFetch(`/clinics/${clinicId}/users/${user_id}/emr_patients/${id}`, { method: 'PUT', body: { merge_status: new_status } }))
      .then(() => ({ page: indexOf(new_status, false) }));

    return editUserHandler(fetchRes, dispatch);
  };
}

const initialState = {
  isFetching: false,
  snackMessage: '',
  snack: false,
  ehr: {},
  informed: {},
  merge: false,
  skip: 0,
  page: 0,
};

function mergeData(state = initialState, action) {
  switch (action.type) {
    case startClearEmrMessage.type:
      return {
        ...state,
        snackMessage: '',
        snack: false,
      };
    case readEhrStart.type:
      return {
        ...state,
        isFetching: true,
      };
    case readEhrEnd.type:
      return {
        ...state,
        ehr: action.payload.data,
        page: indexOf(action.payload.merge_status, false),
        isFetching: false,
      };
    case readEhrError.type:
      return {
        ...state,
        ehr: {},
        isFetching: false,
      };
    case readInformedStart.type:
      return {
        ...state,
        isFetching: true,
      };
    case readInformedEnd.type:
      return {
        ...state,
        informed: isEmpty(action.payload.data) ? [false] : action.payload.data,
        isFetching: false,
      };
    case readInformedError.type:
      return {
        ...state,
        isFetching: false,
      };
    case editUserStart.type:
      return {
        ...state,
        isFetching: true,
      };
    case editUserEnd.type: {
      const { page, merge } = action.payload;
      if (page) {
        return {
          ...state,
          isFetching: false,
          page,
        };
      }
      return {
        ...state,
        isFetching: false,
        merge,
      };
    }
    case editUserError.type:
      return {
        ...state,
        isFetching: false,
      };
    case pushCCDStart.type:
      return {
        ...state,
        snackMessage: 'Pushing CCD to EMR ...',
        snack: true,
        isFetching: true,
      };
    case pushCCDEnd.type: {
      return {
        ...state,
        snackMessage: 'CCD Successfully Pushed to EMR',
        snack: true,
        isFetching: false,
        page: 0,
        skip: 0,
      };
    }
    case pushCCDError.type:
      return {
        ...state,
        snack: false,
        isFetching: false,
      };
    case unmatchPatientStart.type:
      return {
        ...state,
        snack: true,
        snackMessage: 'Revoking match ...',
        isFetching: true,
      };
    case unmatchPatientEnd.type: {
      return {
        ...state,
        snack: false,
        isFetching: false,
      };
    }
    case unmatchPatientError.type:
      return {
        ...state,
        snack: false,
        isFetching: false,
      };
    case startMergePatientData.type:
      if (action.payload) {
        return {
          ...state,
          merge: action.payload,
          skip: 0,
          page: indexOf(state.merge_status, false),
        };
      }
      return { ...initialState };
    case addSkip.type: {
      if (state.page === 7 || indexOf(action.payload.left, false) === -1) {
        return {
          ...state,
          merge: false,
        };
      }
      return {
        ...state,
        page: action.payload.newPage,
      };
    }
    default:
      return state;
  }
}

export default mergeData;
