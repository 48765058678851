/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { orderBy as sort } from 'lodash';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import SortedTableHead from './sorted-table-head';
import ProRow from './pro-submissions-table-row';
import { colors } from '../lib/styles';
import theme from '../lib/muiTheme';

const columnData = [
  {
    id: 'pro_type', disablePadding: false, label: 'Type', small: true,
  },
  {
    id: 'score', disablePadding: false, label: 'Score', small: true,
  },
  {
    id: 'completion_date', disablePadding: false, label: 'Completion Date', small: true,
  },
];

const StyledTablePagination = withStyles({
  caption: {
    fontSize: '1rem',
  },
  select: {
    marginRight: '4px',
  },
  toolbar: {
    paddingLeft: 5,
  },
})(TablePagination);

const styles = {
  caption: {
    fontSize: '1rem',
  },
  head: {
    backgroundColor: colors.highlightGray,
  },
  tableContainer: {
    border: '2px solid rgb(237, 238, 240)',
    borderRight: '2px solid rgb(237, 238, 240)',
    margin: '10px',
  },
  tableMessage: {
    height: '100px',
    margin: '80px 10px 10px',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
};

class ProSubmissionsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'desc',
      orderBy: 'completion_date',
      page: 0,
      rowsPerPage: 10,
      proSubmissions: sort(props.proSubmissions || {}, 'completion_date', 'desc'),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.proSubmissions !== nextProps.proSubmissions) {
      this.handleSort(nextProps.proSubmissions);
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'asc';

    if (this.state.orderBy === property && this.state.order === 'asc') {
      order = 'desc';
    }

    this.setState({ order, orderBy }, this.handleSort);
  };

  handleSort = (newSubmissions) => {
    const { order, orderBy } = this.state;
    const proSubmissions = sort(newSubmissions || this.props.proSubmissions, [orderBy, 'id'], [order, 'desc'])
      .filter(p => (p.pro_type !== 'EQ5D') && (p.pro_type !== 'MIDAS') && (p.pro_type !== 'HIT-6'));

    this.setState({ proSubmissions });
  };
  render() {
    const { order, orderBy, proSubmissions, rowsPerPage, page } = this.state;
    const displayedPros = proSubmissions.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage);

    return (
      <div className={this.props.classes.tableContainer}>
      <Paper>
        <Table>
          <SortedTableHead
            columnData={columnData}
            order={order}
            orderBy={orderBy}
            onRequestSort={this.handleRequestSort}
            className={this.props.classes.head}
          />
          <TableBody>
            {displayedPros.map((submission) => {
              return (
                <ProRow
                  key={submission.id}
                  id={submission.id}
                  type={submission.pro_type}
                  date={submission.completion_date}
                  score={submission.score}
                />
              );
            })}
          </TableBody>
          <TableFooter>
            {!!displayedPros.length &&
              <TableRow>
                <StyledTablePagination
                  colSpan={6}
                  count={proSubmissions.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  rowsPerPageOptions={[10, 20, 50]}
                  className={this.props.classes.caption}
                />
              </TableRow>
            }
          </TableFooter>
        </Table>
          {!displayedPros.length &&
            <div className={this.props.classes.tableMessage}>
              This patient hasn't submitted any PROs yet.
            </div>
          }
        </Paper>
      </div>
    )
  }
}



ProSubmissionsTable.defaultProps = {
  proSubmissions: [],
}
export default withStyles(styles)(ProSubmissionsTable);
