import React, { Component } from 'react';
import { assign } from 'lodash';
import {
  TextField,
  DatePicker,
  RadioButtonGroup,
  RadioButton,
  Checkbox,
} from 'material-ui';

import { colors } from '../lib/styles';
import Button from '@material-ui/core/Button';

const baseStyles = {
  btn: {
    backgroundColor: colors.secondaryColor,
    color: colors.white
  },
  container: {
    width: '100%',
    background: 'white',
    padding: '0 50px 50px 50px',
    margin: '30px',
  },
  headerTitle: {
    fontWeight: 100,
    marginBottom: '0px',
  },
  section: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
    marginBottom: '40px',
  },
  subSection: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
  },
  optionColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    margin: ' 20px 0',
    height: '350px',
    width: '100%',
  },
  radioButton: {
    color: colors.highlightMedium,
    width: '100px',
    fontSize: '14px',
  },
  subRadioButton: {
    width: '150px',
    fontSize: '12px',
    textAlign: 'left',
  },
  checkbox: {
    width: '200px',
    margin: '5px 0',
    fontSize: '14px',
    textAlign: 'left',
  },
  smallField: {
    width: '200px',
  },
};

class ICAForm extends Component {
  constructor(props) {
    super(props);

    this.handleDisable = this.handleDisable.bind(this);

    this.state = {
      diabetes: {
        checked: false,
        option: null,
      },
      liver: {
        checked: false,
        option: null,
      },
      renal: {
        checked: false,
        option: null,
      },
      tumor: {
        checked: false,
        option: null,
      },
      ddda: {
        checked: false,
        option: null,
      },
      dddp: {
        checked: false,
        option: null,
      },
      hdp: {
        checked: false,
        option: null,
      },
      hdr: {
        checked: false,
        option: null,
      },
      infection: {
        checked: false,
        option: null,
      },
      sagittalMalalignment: {
        checked: false,
        option: null,
      },
      pls: {
        checked: false,
        option: null,
      },
      pseudoarthrosis: {
        checked: false,
        option: null,
      },
      scoliosis: {
        checked: false,
        option: null,
      },
      spondylolisthesisD: {
        checked: false,
        option: null,
      },
      spondylolisthesisI: {
        checked: false,
        option: null,
      },
      stenosisC: {
        checked: false,
        option: null,
      },
      stenosisF: {
        checked: false,
        option: null,
      },
      stenosisS: {
        checked: false,
        option: null,
      },
      spineOther: {
        checked: false,
        option: null,
      },
    };
  }
  handleDisable(e, val) {
    console.log(e.target.value, val);
    const newOption = assign(this.state[e.target.value], { checked: val });
    this.setState({ [e.target.value]: newOption });
  }
  render() {
    return (
      <div style={baseStyles.container}>
        <h1 style={baseStyles.headerTitle}>Initial Clinical Assessment</h1>
        <div style={baseStyles.section}>
          <TextField
            defaultValue={''}
            floatingLabelText="Patient Name"
          />
          <TextField
            defaultValue={''}
            floatingLabelText="Patient ID"
          />
          <DatePicker hintText="Visit Date" />
        </div>
        <div style={baseStyles.section}>
          <TextField
            style={baseStyles.smallField}
            defaultValue={''}
            floatingLabelText="Height"
          />
          <RadioButtonGroup style={{ display: 'flex' }} name="height">
            <RadioButton
              style={baseStyles.radioButton}
              value="in"
              label="in"
            />
            <RadioButton
              style={baseStyles.radioButton}
              value="cm"
              label="cm"
            />
          </RadioButtonGroup>
          <TextField
            style={baseStyles.smallField}
            defaultValue={''}
            floatingLabelText="Weight"
          />
          <RadioButtonGroup style={{ display: 'flex' }} name="weight">
            <RadioButton
              style={baseStyles.radioButton}
              value="lbs"
              label="lbs"
            />
            <RadioButton
              style={baseStyles.radioButton}
              value="kg"
              label="kg"
            />
          </RadioButtonGroup>
        </div>
        <h4>Please indicate preoperative patient comorbidities (Mark all that apply)</h4>
        <div style={baseStyles.optionColumn}>
          <Checkbox
            style={baseStyles.checkbox}
            label="None"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="AIDS"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Anemia"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Asthma"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Cerebrovascular disease"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Chronic pulmonary disease"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Congestive heart failure"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Connective tissue disease"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Dementia"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Depression"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Hemiplegia"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Hypertension"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Leukemia"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Malignant lymphoma"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Myocardial infarction"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Nervous system disorder"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Osteoarthritis"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Osteoporosis"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Peripheral vascular disease"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Ulcer disease"
          />
          <Checkbox
            onCheck={this.handleDisable}
            style={baseStyles.checkbox}
            label="Diabetes mellitus"
            value="diabetes"
          />
          <RadioButtonGroup name="diabetesMellitus">
            <RadioButton
              disabled={!this.state.diabetes.checked}
              style={baseStyles.subRadioButton}
              value="Without end organ damage"
              label="Without end organ damage"
            />
            <RadioButton
              disabled={!this.state.diabetes.checked}
              style={baseStyles.subRadioButton}
              value="With end organ damage"
              label="With end organ damage"
            />
          </RadioButtonGroup>
          <Checkbox
            onCheck={this.handleDisable}
            style={baseStyles.checkbox}
            label="Liver disease"
            value="liver"
          />
          <RadioButtonGroup name="liverDisease">
            <RadioButton
              disabled={!this.state.liver.checked}
              style={baseStyles.subRadioButton}
              value="Mild"
              label="Mild"
            />
            <RadioButton
              disabled={!this.state.liver.checked}
              style={baseStyles.subRadioButton}
              value="Moderate"
              label="Moderate"
            />
            <RadioButton
              disabled={!this.state.liver.checked}
              style={baseStyles.subRadioButton}
              value="Severe"
              label="Severe"
            />
          </RadioButtonGroup>
          <Checkbox
            onCheck={this.handleDisable}
            style={baseStyles.checkbox}
            label="Renal disease"
            value="renal"
          />
          <RadioButtonGroup name="renalDisease">
            <RadioButton
              disabled={!this.state.renal.checked}
              style={baseStyles.subRadioButton}
              value="Mild"
              label="Mild"
            />
            <RadioButton
              disabled={!this.state.renal.checked}
              style={baseStyles.subRadioButton}
              value="Moderate"
              label="Moderate"
            />
            <RadioButton
              disabled={!this.state.renal.checked}
              style={baseStyles.subRadioButton}
              value="Severe"
              label="Severe"
            />
          </RadioButtonGroup>
          <Checkbox
            onCheck={this.handleDisable}
            style={baseStyles.checkbox}
            label="Malignant solid tumor"
            value="tumor"
          />
          <RadioButtonGroup name="malignantTumor">
            <RadioButton
              disabled={!this.state.tumor.checked}
              style={baseStyles.subRadioButton}
              value="Non-metastatic"
              label="Non-metastatic"
            />
            <RadioButton
              disabled={!this.state.tumor.checked}
              style={baseStyles.subRadioButton}
              value="Metastatic"
              label="Metastatic"
            />
          </RadioButtonGroup>
        </div>
        <h4>ASA Physical Classification</h4>
        <RadioButtonGroup style={baseStyles.section} name="ASAPC">
          <RadioButton
            style={baseStyles.radioButton}
            value={null}
            label="N/A"
          />
          <RadioButton
            style={baseStyles.radioButton}
            value="1"
            label="I"
          />
          <RadioButton
            style={baseStyles.radioButton}
            value="2"
            label="II"
          />
          <RadioButton
            style={baseStyles.radioButton}
            value="3"
            label="III"
          />
          <RadioButton
            style={baseStyles.radioButton}
            value="4"
            label="IV"
          />
          <RadioButton
            style={baseStyles.radioButton}
            value="5"
            label="VI"
          />
        </RadioButtonGroup>
        <h4 style={{ marginBottom: '0px' }}>Select all spinal diagnoses and indicate levels diagnosed (Mark all that apply)</h4>
        <div style={baseStyles.subSection}>
          <Checkbox
            onCheck={this.handleDisable}
            label="Degenerative disc disease – adjacent"
            value="ddda"
            style={{ width: '350px', textAlign: 'left' }}
          />
          <TextField
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.ddda.checked}
          />
        </div>
        <div style={baseStyles.subSection}>
          <Checkbox
            onCheck={this.handleDisable}
            label="Degenerative disc disease – primary"
            value="dddp"
            style={{ width: '350px', textAlign: 'left' }}
          />
          <TextField
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.dddp.checked}
          />
        </div>
        <div style={baseStyles.subSection}>
          <Checkbox
            onCheck={this.handleDisable}
            label="Herniated disc – primary"
            value="hdp"
            style={{ width: '350px', textAlign: 'left' }}
          />
          <TextField
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.hdp.checked}
          />
        </div>
        <div style={baseStyles.subSection}>
          <Checkbox
            onCheck={this.handleDisable}
            label="Herniated disc – recurrent"
            value="hdr"
            style={{ width: '350px', textAlign: 'left' }}
          />
          <TextField
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.hdr.checked}
          />
        </div>
        <div style={baseStyles.subSection}>
          <Checkbox
            onCheck={this.handleDisable}
            label="Infection"
            value="infection"
            style={{ width: '350px', textAlign: 'left' }}
          />
          <TextField
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.ddda.checked}
          />
        </div>
        <div style={baseStyles.subSection}>
          <Checkbox
            onCheck={this.handleDisable}
            label="Sagittal malalignment"
            value="sagittalMalalignment"
            style={{ width: '350px', textAlign: 'left' }}
          />
          <TextField
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.sagittalMalalignment.checked}
          />
        </div>
        <h5>Indicate Type of Sagittal Malalignment:</h5>
        <RadioButtonGroup
          style={assign({}, baseStyles.optionColumn, {
            marginLeft: '20px',
            height: '80px',
            justifyContent: 'center',
          })}
          name="sagittalMalalignment"
        >
          <RadioButton
            disabled={!this.state.sagittalMalalignment.checked}
            style={baseStyles.subRadioButton}
            value={null}
            label="Congenital"
          />
          <RadioButton
            disabled={!this.state.sagittalMalalignment.checked}
            style={baseStyles.subRadioButton}
            value="1"
            label="Degenerative"
          />
          <RadioButton
            disabled={!this.state.sagittalMalalignment.checked}
            style={baseStyles.subRadioButton}
            value="2"
            label="Post-traumatic"
          />
          <RadioButton
            disabled={!this.state.sagittalMalalignment.checked}
            style={baseStyles.subRadioButton}
            value="3"
            label="Scheuermann’s"
          />
          <RadioButton
            disabled={!this.state.sagittalMalalignment.checked}
            style={baseStyles.subRadioButton}
            value="4"
            label="Ankylosing spondylitis"
          />
          <RadioButton
            disabled={!this.state.sagittalMalalignment.checked}
            style={baseStyles.subRadioButton}
            value="5"
            label="DISH"
          />
          <RadioButton
            disabled={!this.state.sagittalMalalignment.checked}
            style={baseStyles.subRadioButton}
            value="6"
            label="Iatrogenic"
          />
          <RadioButton
            disabled={!this.state.sagittalMalalignment.checked}
            style={baseStyles.subRadioButton}
            value="7"
            label="S\/P laminectomy"
          />
          <RadioButton
            disabled={!this.state.sagittalMalalignment.checked}
            style={baseStyles.subRadioButton}
            value="8"
            label="Unknown"
          />
        </RadioButtonGroup>
        <div style={baseStyles.subSection}>
          <Checkbox
            onCheck={this.handleDisable}
            label="Post-laminectomy syndrome"
            value="pls"
            style={{ width: '350px', textAlign: 'left' }}
          />
          <TextField
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.pls.checked}
          />
        </div>
        <div style={baseStyles.subSection}>
          <Checkbox
            onCheck={this.handleDisable}
            label="Pseudoarthrosis"
            value="pseudoarthrosis"
            style={{ width: '350px', textAlign: 'left' }}
          />
          <TextField
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.pseudoarthrosis.checked}
          />
        </div>
        <div style={baseStyles.subSection}>
          <Checkbox
            onCheck={this.handleDisable}
            label="Scoliosis"
            value="scoliosis"
            style={{ width: '350px', textAlign: 'left' }}
          />
          <TextField
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.scoliosis.checked}
          />
        </div>
        <h5>Indicate Type of Scoliosis:</h5>
        <RadioButtonGroup
          style={assign({}, baseStyles.subSection, { justifyContent: 'center' })}
          name="scoliosis"
        >
          <RadioButton
            disabled={!this.state.scoliosis.checked}
            style={assign({}, baseStyles.subRadioButton, { width: '180px' })}
            value={null}
            label="De novo/Degenerative"
          />
          <RadioButton
            disabled={!this.state.scoliosis.checked}
            style={baseStyles.subRadioButton}
            value="1"
            label="Idiopathic"
          />
          <RadioButton
            disabled={!this.state.scoliosis.checked}
            style={baseStyles.subRadioButton}
            value="2"
            label="Congenital"
          />
          <RadioButton
            disabled={!this.state.scoliosis.checked}
            style={baseStyles.subRadioButton}
            value="3"
            label="Neuromuscular"
          />
        </RadioButtonGroup>
        <div style={assign({}, baseStyles.subSection, { justifyContent: 'space-around' })}>
          <RadioButtonGroup>
            <RadioButton
              disabled={!this.state.scoliosis.checked}
              label="Other"
              value="5"
              style={assign({}, baseStyles.subRadioButton, { width: '50px', textAlign: 'left' })}
            />
          </RadioButtonGroup>
          <TextField
            style={{ width: '400px', fontSize: '12px' }}
            defaultValue={''}
            floatingLabelText="(please specify, and indicate levels):"
            disabled={!this.state.scoliosis.checked}
          />
        </div>
        <div style={assign({}, baseStyles.subSection, { justifyContent: 'flex-start', margin: '20px 0 0 40px' })}>
          <Checkbox
            onCheck={this.handleDisable}
            label="Spondylolisthesis – degenerative"
            value="spondylolisthesisD"
            style={{ width: '350px', textAlign: 'left' }}
          />
        </div>
        <div style={baseStyles.subSection}>
          <Checkbox
            disabled={!this.state.spondylolisthesisD.checked}
            label="Grade I"
            value="scoliosis"
            style={assign({}, baseStyles.subRadioButton, { width: '110px', textAlign: 'left' })}
          />
          <TextField
            style={{ width: '400px', fontSize: '12px' }}
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.spondylolisthesisD.checked}
          />
        </div>
        <div style={baseStyles.subSection}>
          <Checkbox
            disabled={!this.state.spondylolisthesisD.checked}
            label="Grade II"
            value="scoliosis"
            style={assign({}, baseStyles.subRadioButton, { width: '110px', textAlign: 'left' })}
          />
          <TextField
            style={{ width: '400px', fontSize: '12px' }}
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.spondylolisthesisD.checked}
          />
        </div>
        <div style={baseStyles.subSection}>
          <Checkbox
            disabled={!this.state.spondylolisthesisD.checked}
            label="Grade III"
            value="scoliosis"
            style={assign({}, baseStyles.subRadioButton, { width: '110px', textAlign: 'left' })}
          />
          <TextField
            style={{ width: '400px', fontSize: '12px' }}
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.spondylolisthesisD.checked}
          />
        </div>
        <div style={baseStyles.subSection}>
          <Checkbox
            disabled={!this.state.spondylolisthesisD.checked}
            label="Grade IV"
            value="scoliosis"
            style={assign({}, baseStyles.subRadioButton, { width: '110px', textAlign: 'left' })}
          />
          <TextField
            style={{ width: '400px', fontSize: '12px' }}
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.spondylolisthesisD.checked}
          />
        </div>
        <div style={assign({}, baseStyles.subSection, { justifyContent: 'flex-start', margin: '20px 0 0 40px' })}>
          <Checkbox
            onCheck={this.handleDisable}
            label="Spondylolisthesis – isthmic"
            value="spondylolisthesisI"
            style={{ width: '350px', textAlign: 'left' }}
          />
        </div>
        <div style={baseStyles.subSection}>
          <Checkbox
            disabled={!this.state.spondylolisthesisI.checked}
            label="Grade I"
            value="scoliosis"
            style={assign({}, baseStyles.subRadioButton, { width: '110px', textAlign: 'left' })}
          />
          <TextField
            style={{ width: '400px', fontSize: '12px' }}
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.spondylolisthesisI.checked}
          />
        </div>
        <div style={baseStyles.subSection}>
          <Checkbox
            disabled={!this.state.spondylolisthesisI.checked}
            label="Grade II"
            value="scoliosis"
            style={assign({}, baseStyles.subRadioButton, { width: '110px', textAlign: 'left' })}
          />
          <TextField
            style={{ width: '400px', fontSize: '12px' }}
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.spondylolisthesisI.checked}
          />
        </div>
        <div style={baseStyles.subSection}>
          <Checkbox
            disabled={!this.state.spondylolisthesisI.checked}
            label="Grade III"
            value="scoliosis"
            style={assign({}, baseStyles.subRadioButton, { width: '110px', textAlign: 'left' })}
          />
          <TextField
            style={{ width: '400px', fontSize: '12px' }}
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.spondylolisthesisI.checked}
          />
        </div>
        <div style={baseStyles.subSection}>
          <Checkbox
            disabled={!this.state.spondylolisthesisI.checked}
            label="Grade IV"
            value="scoliosis"
            style={assign({}, baseStyles.subRadioButton, { width: '110px', textAlign: 'left' })}
          />
          <TextField
            style={{ width: '400px', fontSize: '12px' }}
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.spondylolisthesisI.checked}
          />
        </div>
        <div style={baseStyles.subSection}>
          <Checkbox
            onCheck={this.handleDisable}
            label="Stenosis – central"
            value="stenosisC"
            style={{ width: '350px', textAlign: 'left' }}
          />
          <TextField
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.stenosisC.checked}
          />
        </div>
        <div style={baseStyles.subSection}>
          <Checkbox
            onCheck={this.handleDisable}
            label="Stenosis – foraminal"
            value="stenosisF"
            style={{ width: '350px', textAlign: 'left' }}
          />
          <TextField
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.stenosisF.checked}
          />
        </div>
        <div style={baseStyles.subSection}>
          <Checkbox
            onCheck={this.handleDisable}
            label="Stenosis – subarticular/lateral recess"
            value="stenosisS"
            style={{ width: '350px', textAlign: 'left' }}
          />
          <TextField
            defaultValue={''}
            floatingLabelText="Indicate levels:"
            disabled={!this.state.stenosisS.checked}
          />
        </div>
        <div style={baseStyles.section}>
          <Checkbox
            onCheck={this.handleDisable}
            label="Other"
            value="spineOther"
            style={{ width: '75px', textAlign: 'left' }}
          />
          <TextField
            style={{ width: '525px' }}
            defaultValue={''}
            floatingLabelText="(please specify, and indicate levels):"
            disabled={!this.state.spineOther.checked}
          />
        </div>
        <h4 style={{ display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
          Has the patient had previous spine surgery?
          <RadioButtonGroup style={{ display: 'flex' }} name="weight">
            <RadioButton
              style={baseStyles.radioButton}
              value="yes"
              label="Yes"
            />
            <RadioButton
              style={baseStyles.radioButton}
              value="no"
              label="No"
            />
            <RadioButton
              style={baseStyles.radioButton}
              value="unknown"
              label="Unknown"
            />
          </RadioButtonGroup>
        </h4>
        <h6>If yes, indicate type of previous spine surgery in the region currently being treated (e.g., lumbar, thoracic, cervical). (Mark all that apply)</h6>
        <div style={baseStyles.subSection}>
          <Checkbox
            label="Decompression"
            value="scoliosis"
            style={assign({}, baseStyles.subRadioButton, { width: '130px', textAlign: 'left' })}
          />
          <Checkbox
            label="Fusion"
            value="scoliosis"
            style={assign({}, baseStyles.subRadioButton, { width: '80px', textAlign: 'left' })}
          />
          <Checkbox
            label="Total disc/other motion sparing device"
            value="scoliosis"
            style={assign({}, baseStyles.subRadioButton, { width: '265px', textAlign: 'left' })}
          />
          <Checkbox
            label="Other"
            value="scoliosis"
            style={assign({}, baseStyles.subRadioButton, { width: '80px', textAlign: 'left' })}
          />
        </div>
        <TextField
          style={{ width: '80%', fontSize: '12px', marginBottom: '40px' }}
          defaultValue={''}
          floatingLabelText="Please describe the patient’s prior surgery/surgeries including specific procedure(s) and level(s) treated:"
        />
        <h4>Indicate curve pattern</h4>
        <RadioButtonGroup style={baseStyles.subSection} name="ASAPC">
          <RadioButton
            style={baseStyles.radioButton}
            value={null}
            label="Single thoracic"
          />
          <RadioButton
            style={baseStyles.radioButton}
            value="1"
            label="Double thoracic"
          />
          <RadioButton
            style={baseStyles.radioButton}
            value="2"
            label="Double major"
          />
          <RadioButton
            style={baseStyles.radioButton}
            value="3"
            label="Triple major"
          />
          <RadioButton
            style={baseStyles.radioButton}
            value="4"
            label="Thoracolumbar/lumbar/de novo"
          />
        </RadioButtonGroup>
        <div style={baseStyles.subSection}>
          <Checkbox
            onCheck={this.handleDisable}
            label="Other"
            value="spineOther"
            style={{ width: '75px', textAlign: 'left' }}
          />
          <TextField
            style={{ width: '525px' }}
            defaultValue={''}
            floatingLabelText="(please specify):"
          />
        </div>
        <h4 style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', margin: '30px 0' }}>
          Indicate approximate year that the patient was first diagnosed with scoliosis:
        <TextField
            style={{ width: '100px' }}
            defaultValue={''}
          />
        </h4>
        <h4>Deformity Details</h4>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          Indicate the upper level of the major curve:
          <TextField
            defaultValue={''}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          Indicate the lower level of the major curve:
          <TextField
            defaultValue={''}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: '30px' }}>
          Indicate the apex vertebra of the major curve:
          <TextField
            defaultValue={''}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: '40px' }}>
          Is the apex vertebra to the right or left of the major curve?
          <RadioButtonGroup style={{ display: 'flex' }} name="weight">
            <RadioButton
              style={baseStyles.radioButton}
              value="yes"
              label="Right"
            />
            <RadioButton
              style={baseStyles.radioButton}
              value="no"
              label="Left"
            />
            <RadioButton
              style={baseStyles.radioButton}
              value="unknown"
              label="Unknown"
            />
          </RadioButtonGroup>
        </div>
        <h4 style={{ display: 'flex', justifyContent: 'space-between', margin: '30px 40px 0px' }}>
          Is there a compensatory curve ?
          <RadioButtonGroup style={{ display: 'flex' }} name="weight">
            <RadioButton
              style={baseStyles.radioButton}
              value="yes"
              label="Yes"
            />
            <RadioButton
              style={baseStyles.radioButton}
              value="no"
              label="No"
            />
            <RadioButton
              style={baseStyles.radioButton}
              value="unknown"
              label="Unknown"
            />
          </RadioButtonGroup>
        </h4>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          If yes, indicate the upper level of the compensatory curve:
          <TextField
            defaultValue={''}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          If yes, indicate the lower level of the compensatory curve:
          <TextField
            defaultValue={''}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: '30px' }}>
          If yes, indicate the apex vertebra of the compensatory curve:
          <TextField
            defaultValue={''}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: '40px' }}>
          Is the apex vertebra to the right or left of the compensatory curve:
          <RadioButtonGroup style={{ display: 'flex' }} name="weight">
            <RadioButton
              style={baseStyles.radioButton}
              value="yes"
              label="Right"
            />
            <RadioButton
              style={baseStyles.radioButton}
              value="no"
              label="Left"
            />
            <RadioButton
              style={baseStyles.radioButton}
              value="unknown"
              label="Unknown"
            />
          </RadioButtonGroup>
        </div>
        <h4>Does the patient exhibit the following? (Mark all that apply)</h4>
        <div
          style={assign({}, baseStyles.optionColumn, {
            marginLeft: '20px',
            height: '80px',
            justifyContent: 'center',
          })}
        >
          <Checkbox
            style={baseStyles.checkbox}
            label="None"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Coronal imbalance"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Axial rotation"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Sagittal imbalance"
          />
          <Checkbox
            style={baseStyles.checkbox}
            label="Unknown"
          />
        </div>
        <Button
          children="Submit"
          style={baseStyles.btn}
          onClick={() => { this.props.router.push('/app'); }}
        />
      </div>
    );
  }
}

export default ICAForm;
