import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from 'material-ui/CircularProgress';

import { colors } from '../lib/styles';

const baseStyles = {
  container: {
    textAlign: 'center',
  },
  completeHeader: {
    color: colors.primaryColor,
  },
  progressCircle: {
    border: `3px solid ${colors.lightGrayText}`,
    backgroundColor: colors.white,
    borderRadius: '100px',
    boxSizing: 'border-box',
    position: 'relative',
    marginTop: '0.625rem',
  },
  progressCircleInner: {
    marginLeft: '-3px',
    marginTop: '-3px',
  },
  text: {
    position: 'absolute',
    borderRadius: '100px',
    color: colors.primaryColor,
    marginRight: '3rem',
    marginBottom: '3rem',
    marginLeft: '2rem',
    paddingTop: '0.75rem',
    zIndex: '1',
  },
};

function ProgressCircle(props) {
  const { complete } = props;
  let completion = `${Math.floor(complete * 100)}%`;
  let progress = (<CircularProgress
    color={colors.progressCircleYellow}
    innerStyle={baseStyles.progressCircleInner}
    key="completed-progress-circle"
    max={1}
    mode="determinate"
    size={145}
    style={baseStyles.progressCircle}
    value={complete}
  />);

  if (props.isLoading) {
    completion = 'Loading';
    progress = (<CircularProgress
      color={colors.primaryColor}
      innerStyle={baseStyles.progressCircleInner}
      key="loading-progress-circle"
      size={100}
      style={baseStyles.progressCircle}
      className="progress-circle-loading"
    />);
  }

  return (
    <div style={baseStyles.container}>
      <div style={baseStyles.text}>
        <h1 style={baseStyles.completeHeader}>{completion}</h1>
        <div>{props.label}</div>
        <div>Completed</div>
      </div>
      {progress}
    </div>
  );
}

ProgressCircle.defaultProps = {
  complete: 0,
  label: 'Forms',
  isLoading: true,
};

ProgressCircle.propTypes = {
  complete: PropTypes.number,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
};

export default ProgressCircle;
