import { combineReducers } from 'redux';

import appData from './app-data';
import appointments from './appointments';
import clinic from './clinic';
import devices from './devices';
import emr from './emr';
import emrMessages from './emr-messages';
import esign from './esign';
import forms from './forms';
import clinicalFormSubmissions from './clinical-form-submissions';
import mergeData from './merge-data';
import migraines from './migraines';
import modal from './modal';
import notifications from './notifications';
import painHx from './pain-hx';
import proSubmissions from './pro-submissions';
import recent from './recent';
import user from './user';

const reducers = combineReducers({
  appData,
  appointments,
  clinic,
  devices,
  emr,
  emrMessages,
  esign,
  clinicalFormSubmissions,
  forms,
  notifications,
  mergeData,
  migraines,
  modal,
  painHx,
  proSubmissions,
  recent,
  user,
});

export default reducers;
