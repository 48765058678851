// Amplitude seems to really break when it breaks..
// Hence the try catches
export function logEvent(name, data) {
  try {
    window.amplitude.getInstance().logEvent(name, data);
  } catch (e) {
    // eslint-disable-next-line
    console.error('Error Logging Event', e);
  }
}

export function setUser(id, clinicId) {
  try {
    window.amplitude.getInstance().setUserId(id);
    window.amplitude.getInstance().setUserProperties({ clinicId });
  } catch (e) {
    // eslint-disable-next-line
    console.error('Error setting user id in Ampltiude', e);
  }
}

