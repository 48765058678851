import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get, noop, find } from 'lodash';

import { Snackbar } from 'material-ui';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles'

import MergePatientData from '../components/merge-patient-data';
import VerifyEmrPatient from '../components/verify-emr-patient';
import PatientViewContentSection from './patient-view-content-section';
import PatientViewHeader from './patient-view-header';
import PatientSummary from './patient-summary';
import PatientSummaryBtns from './patient-summary-btns';
import PatientViewFooter from './patient-view-footer';
import RevokeEmrMatchDialog from './revoke-emr-dialog';

import { blueBtnStyle, colors } from '../lib/styles';
import PatientHeaderProgressCircles from './patient-header-progress-circles';

const styles = {
  appointmentCompletion: {
    margin: '5px 0px 0px 0px',
    paddingLeft: '20px',
    minWidth: '200px',
  },
  bottomBtnContainer: {
    padding: '3px 10px 10px',
  },
  btn: blueBtnStyle,
  btnLabel: {
    textTransform: 'capitalize',
  },
  emrButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  emrContent: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '120px',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  mergeMessage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '40px 0px',
  },
  emrButton: {
    backgroundColor: colors.primaryColor,
    color: colors.white,
    fontSize: '14px',
    margin: '35px 5px',
    verticalAlign: 'top',
  },
  topSectionIcon: {
    color: colors.primaryAccent,
    height: '18px',
    width: '18px',
  },
};

class EmrOverviewTab extends PureComponent {
  state = {
    revokeMatchDialogOpen: false,
  }
  handleRevokeMatch = () => {
    const verifiedPatient = find(this.props.emrPatients, { status: 'VERIFIED' });
    return this.props.editUserEmr(this.props.userId, verifiedPatient.id, { status: 'REJECTED' })
  }
  handleToggleMatchDialog = () => {
    this.setState((prevState) => ({
      revokeMatchDialogOpen: !prevState.revokeMatchDialogOpen
    }));
  }
  render() {
    const { props } = this;
    const { appointment, classes, painLevel, updateAppointment, unverifiedPatient, userId } = props;
    const verifiedPatient = find(props.emrPatients, { status: 'VERIFIED' });
    const emrInstance = find(props.emrInstances, { emr_instance_id: get(props, 'unverifiedPatient.emr_instances_id') }) || {};
    const revoke = (<Button
      className={classes.btn}
      onClick={this.handleToggleMatchDialog}
      >Revoke Match</Button>);
    const continueMerge = <Button className={classes.btn} onClick={() => props.mergePatientData(true)}>Review/Push CCD to EMR</Button>
    let mergeMessage = null;
    let emrMessage = 'This patient is currently not matched with a patient identity in the EMR system.';
    let emrButtons = null;

    if (verifiedPatient) {
      emrMessage = 'This patient is currently matched to a corresponding patient identity in the EMR system.';
      emrButtons = (
        <div style={styles.emrButtons}>
          {revoke}
          {continueMerge}
        </div>
      );
    }

    let content = (
      <Fragment>
        {mergeMessage}
        {emrMessage}
        {emrButtons}
      </Fragment>
    );

    if(props.emrMergeAvailable && !props.unverifiedPatient) {
      content = (
        <MergePatientData
          {...props}
          classes={{}}
          emrPatient={verifiedPatient}
          emrInstance={props.emrInstances}
        />
      );
    }

      if(props.unverifiedPatient) {
        content = (
          <VerifyEmrPatient
            appointment={appointment}
            emrInstance={emrInstance}
            appointmentViewUser={userId}
            emrPatients={props.emrPatients}
            onChangeTab={props.onChangeTab}
            onEditUserEmr={props.editUserEmr}
            updateAppointment={props.updateAppointment}
            unverifiedPatient={unverifiedPatient}
          />
        );
      }

      return (
        <Fragment>
          <RevokeEmrMatchDialog
            open={this.state.revokeMatchDialogOpen}
            onToggleOpen={this.handleToggleMatchDialog}
            onRevoke={this.handleRevokeMatch}
          />
          <PatientViewHeader
            avatarImg={appointment.avatarImgLarge}
            centerContent={(
              <PatientSummary
                {...appointment}
                painLevel={painLevel}
                updateAppointment={updateAppointment}
              />
            )}
            centerButtons={(
              <PatientSummaryBtns {...props} />
            )}
            rightContent={
              <PatientHeaderProgressCircles {...props} />
            }
          />
            {props.children}
          <PatientViewContentSection>
            <div style={styles.emrContent}>
              <Snackbar
                autoHideDuration={4000}
                message={props.mergeData.snackMessage}
                open={props.mergeData.snack}
                onRequestClose={props.onClearEmrMessage}
              />
                  {content}
              </div>
            <PatientViewFooter router={props.router} />
          </PatientViewContentSection>
        </Fragment>
      );
  }
}

EmrOverviewTab.defaultProps = {
  appointment: {
    waitTime: '00:00',
  },
  forms: {},
  handleOpenAllForms: noop,
  handleOpenForm: noop,
  painLevel: '',
  updateAppointment: noop,
};

EmrOverviewTab.propTypes = {
  appointment: PropTypes.object,
  emrPatients: PropTypes.array,
  mergeData: PropTypes.object,
  forms: PropTypes.object,
  painLevel: PropTypes.string,
  userId: PropTypes.string,
  updateAppointment: PropTypes.func,
  mergePatientData: PropTypes.func,
  editUserEmr: PropTypes.func,
  router: PropTypes.object,
};

export default withStyles(styles)(EmrOverviewTab);
