import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Point from '@material-ui/icons/ArrowDropDown';
import CircularProgress from 'material-ui/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import PatientListItem from './patient-list-item';
import { apiFetch } from '../lib/fetch';
import formatAppointment from '../lib/format-appointment';

import { colors } from '../lib/styles';

const StyledTablePagination = withStyles({
  toolbar: {
    paddingLeft: 5, 
  },
  caption: {
    fontSize: '1rem',
  },
  select: {
    marginRight: '4px',
  },
})(TablePagination);

const baseStyles = {
  bottomBtn: {
    fontSize: '1rem',
    margin: 0,
    fontWeight: 600,
  },
  caption: {
    fontSize: '1rem',
  },
  clickableTableHeader: {
    boxSizing: 'border-box',
    color: colors.black,
    cursor: 'pointer',
    fontSize: '1rem',
    padding: 0,
    textAlign: 'left',
    width: '14%',
    whiteSpace: 'nowrap',
    paddingLeft: '2.5rem',
  },
  container: {
    minWidth: '768px',
    paddingLeft: '20px',
  },
  displayNumberMenuIcon: {
    width: '140px',
    height: '30px',
    marginRight: '10px',
  },
  dropDownSort: {
    color: colors.lightGreyText,
    fontSize: '2rem',
    verticalAlign: 'middle',
  },
  dualArrows: {
    color: colors.lightGreyText,
    verticalAlign: 'middle',
  },
  headerText: {
    fontWeight: 100,
    textAlign: 'left',
  },
  headerTitle: {
    fontWeight: 100,
    fontSize: '1.4rem',
  },
  subHeaderText: {
    display: 'flex',
    color: colors.hanaTableNavigation,
    margin: '5px 5px 5px 0',
    fontSize: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  table: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.highlightMedium}`,
    height: '100%',
    minWidth: '768px',
    tableLayout: 'fixed',
  },
  tableHead: {
    background: colors.hanaTableHeader,
  },
  tinyTableHeader: {
    boxSizing: 'border-box',
    color: colors.black,
    fontSize: '1rem',
    minWidth: '50px',
    width: '7.5%',
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
  tableHeader: {
    boxSizing: 'border-box',
    color: colors.black,
    fontSize: '1rem',
    minWidth: '100px',
    width: '13.33%',
    padding: '4px 10px',
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
  pagination: {
    display: 'flex',
    fontWeight: 600,
    marginTop: '0.3125rem',
    maxWidth: '5rem',
    alignItems: 'center',
    marginLeft: '-0.85rem',
  },
};

class PatientList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayNumber: 10,
      sortBy: 'last_name',
      sortDir: 'desc',
      tick: 0,
      page: 0,
      patients: [],
    };

    this.updateSort = this.updateSort.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    this.sortByName = this.updateSort.bind(this, 'last_name');
    this.sortByComplete = this.updateSort.bind(this, 'complete');
    this.handleFirst = this.handlePagination.bind(this, 'first');
    this.handlePrevious = this.handlePagination.bind(this, 'prev');
    this.handleNext = this.handlePagination.bind(this, 'next');
    this.handleLast = this.handlePagination.bind(this, 'last');
  }
  componentWillMount() {
    this.fetchPatients();
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  updateSort(col) {
    const { sortBy, sortDir } = this.state;

    if (sortBy === col) {
      return this.setState({
        sortDir: sortDir === 'asc' ? 'desc' : 'asc',
      }, this.fetchPatients);
    }
    return this.setState({
      sortDir: 'desc',
      sortBy: col,
    }, this.fetchPatients);
  }
  handleMenuItemClick = (event, index) => {
    this.setState({ displayNumber: index }, this.fetchPatients);
  };
  handlePagination(button) {
    const { page, displayNumber } = this.state;
    const max = Math.ceil(this.state.totalPatients / displayNumber);
    if (button === 'first') {
      return this.setState({ page: 0 }, this.fetchPatients);
    }
    if (button === 'next') {
      const newPage = (page === max) ? max : page + 1;
      return this.setState({ page: newPage }, this.fetchPatients);
    }
    if (button === 'last') {
      return this.setState({ page: max }, this.fetchPatients);
    }
    if (button === 'prev') {
      const newPage = (page === 1) ? 1 : page - 1;
      return this.setState({ page: newPage }, this.fetchPatients);
    }
    this.setState({ page: max }, this.fetchPatients);
  }
  fetchPatients = () => {
    const { clinicId } = this.props;
    const options = {
      query: {
        limit: this.state.displayNumber,
        offset: (this.state.page) * this.state.displayNumber,
        orderBy: this.state.sortBy,
        sortOrder: this.state.sortDir,
      }
    };

    this.setState({
      loading: true,
      error: null,
    });
    apiFetch(`/clinics/${clinicId}/patients`, options)
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
          patients: res.data.map(formatAppointment),
          totalPatients: res.totalRecords,
          limit: this.state.displayNumber > 50 ? this.state.displayNumber : !this.state.displayNumber,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          error: err,
        });
      });
  }
  forwardActive = () => {
    const { page, displayNumber } = this.state;
    const max = Math.ceil(this.state.totalPatients / displayNumber);

    if (page >= max) return false;
    return true;
  }
  backActive = () => {
    const { page } = this.state;

    if (page > 0) return true;
    return false;
  }
  handleChangePage = (event, page) => {
    this.setState({ page }, this.fetchPatients);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ displayNumber: event.target.value }, this.fetchPatients);
  };

  render() {
    let { displayNumber, page, patients } = this.state;
    const { classes } = this.props;
    const {
      addRecent, id, isFetching,
      title, onUpdateAppointment,
    } = this.props;
    const numPatients = patients.length;

    const dropDownSortIcon = <Point style={baseStyles.dropDownSort} />;
    if (isFetching && !numPatients) {
      return (
        <div style={baseStyles.container}>
          <h1 style={baseStyles.headerTitle}>{title}</h1>
          <p>Loading patients..</p>
          <CircularProgress size={100} thickness={7} />
        </div>
      );
    }
    if (!numPatients) {
      return (
        <div style={baseStyles.container}>
          <div style={baseStyles.headerText}>
            <h1 style={baseStyles.headerTitle}>{title}</h1>
            <p style={baseStyles.subHeaderText}>No patients exist at this time.</p>
          </div>
        </div>
      );
    }

    const rowStyle = [{ background: colors.hanaTableEvenRows }, {}];
    // Sorts and truncates the patient list
    let count = 0;
    const sortedPatients = this.state.patients;
    const patientRows = sortedPatients.map((patient) => {
      if (count > 0) {
        count = 0;
      } else {
        count++;
      }
      const appointment = find(this.props.appointments, { user_id: patient.user_id });
      patient = { ...patient, ...appointment };
      return (
        <PatientListItem
          addRecent={addRecent}
          checkedIn={!!patient.status}
          currentTime={this.state.currenttime}
          key={`patient-${patient.user_id}`}
          router={this.props.router}
          patient={patient}
          providers={this.props.providers}
          signatureDocuments={this.props.signatureDocuments}
          onUpdateAppointment={onUpdateAppointment}
          onUpdatePatient={this.props.onUpdatePatient}
          onCreateAppointment={this.props.createAppointment}
          highlight={rowStyle[count]}
        />
      );
    });

    return (
      <div id={id} style={baseStyles.container}>
        <div style={baseStyles.headerText}>
          <h1 style={baseStyles.headerTitle}>{title}</h1>
        </div>
        <Table
          selectable="false"
          className={classes.table}
        >
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell
                onClick={this.sortByName}
                className={classes.clickableTableHeader}
              >
                Patient Name{dropDownSortIcon}
              </TableCell>
              <TableCell className={classes.tableHeader}>Date of Birth</TableCell>
              <TableCell className={classes.tinyTableHeader} padding="none">Sex</TableCell>
              <TableCell className={classes.tableHeader}>Clinician</TableCell>
              <TableCell className={classes.tableHeader}>Check In/Out</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patientRows}
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTablePagination
                colSpan={5}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                count={this.state.totalPatients}
                rowsPerPage={displayNumber}
                rowsPerPageOptions={[10, 20, 50]}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                variant="caption"
                className={classes.caption}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }
}

PatientList.defaultProps = {
  patients: [],
  id: null,
  isFetching: false,
  providers: [],
  waitText: '',
};

PatientList.propTypes = {
  addRecent: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  createAppointment: PropTypes.func.isRequired,
  id: PropTypes.string,
  isFetching: PropTypes.bool,
  patients: PropTypes.arrayOf(PropTypes.object),
  providers: PropTypes.array,
  title: PropTypes.string,
  onUpdateAppointment: PropTypes.func,
  router: PropTypes.object.isRequired,
};

export default withStyles(baseStyles)(PatientList);
