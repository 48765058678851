import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, forEach, map, noop } from 'lodash';

import IconButton from '@material-ui/core/IconButton';
import LeftIcon from 'material-ui/svg-icons/navigation/chevron-left';
import RightIcon from 'material-ui/svg-icons/navigation/chevron-right';

import { colors } from '../lib/styles';

const bodies = ['FRONT', 'RIGHT', 'BACK', 'LEFT'];
const baseStyles = {
  absolute: {
    position: 'absolute',
  },
  btnContainer: {
    display: 'none', // Switch this to flex to enable body rotation buttons
    flexDirection: 'row',
    flexWrap: 'wrap',
    left: '-10px',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    minHeight: '525px',
    minWidth: '200px',
  },
  painIndicator: {
    backgroundColor: colors.secondaryColor,
    borderRadius: '50%',
    height: '12px',
    position: 'absolute',
    width: '12px',
  },
  rightIcon: {
    marginLeft: '220px',
  },
  svgStyle: {
    height: '525px',
    position: 'absolute',
    width: '250px',
  },
  tooltip: {
    backgroundColor: 'rgb(34, 34, 34)',
    borderRadius: '5px',
    color: 'rgb(255, 255, 255)',
    display: 'block',
    fontSize: '13px',
    margin: '-40px 0px 0px -40px',
    opacity: '0.9',
    padding: '8px 21px',
    pointerEvents: 'none',
    textAlign: 'center',
    zIndex: 999,
    width: '100px',
  },
};

// TODO: Handle tooltip overlaps
// Temporarily leaving these in the same file
// until body is put in generic component repo
class PainPoint extends Component {
  render() {
    const { location, hideLabels, scale } = this.props;
    const style = Object.assign({}, baseStyles.painIndicator, {
      left: Math.round((location.x - 6) * scale),
      top: Math.round((location.y - 6) * scale),
      width: Math.round(12 * scale),
      height: Math.round(12 * scale),
    });

    return (
      <div style={style}>
        {!hideLabels ? <div style={baseStyles.tooltip}>{location.name}</div> : null}
      </div>
    );
  }
}

class PainLine extends Component {
  render() {
    const { startLocation, endLocation, scale } = this.props;
    return (
      <line
        x1={Math.round(startLocation.x * scale)}
        y1={Math.round(startLocation.y * scale)}
        x2={Math.round(endLocation.x * scale)}
        y2={Math.round(endLocation.y * scale)}
        strokeDasharray="5, 10"
        stroke={colors.errorRed}
        strokeWidth={Math.round(5 * scale)}
      />
    );
  }
}

function makeLines(painLocations, scale) {
  if (painLocations.length < 2) {
    return [];
  }

  const painLines = [];

  forEach(painLocations, (startLocation, idx) => {
    const endLocation = painLocations[idx + 1];
    if (!endLocation) {
      return;
    }

    painLines.push(<PainLine key={`${startLocation.id}-${endLocation.id}`} startLocation={startLocation} endLocation={endLocation} scale={scale} />);
  });

  return painLines;
}

class PainBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentBody: 'BACK',
    };
    this.nextBody = this.nextBody.bind(this);
    this.prevBody = this.prevBody.bind(this);
    this.imgClick = this.imgClick.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.currentBody === 'FRONT' && this.props.selectedLocations !== nextProps.selectedLocations && nextProps.selectedLocations[0]) {
      this.setState({
        currentBody: nextProps.selectedLocations[0].body_location,
      });
    }
  }
  imgClick(e) {
    let x = get(e, 'nativeEvent.offsetX', null);
    let y = get(e, 'nativeEvent.offsetY', null);
    // Not entirely sure about using changedTouches here
    // SO seems to think we should be using targetTouches[0] or touches[0]
    // However those are empty at the point this is fired so.. ¯\_(ツ)_/¯
    if (e.nativeEvent.changedTouches && e.nativeEvent.changedTouches.length) {
      x = e.nativeEvent.changedTouches[0].pageX - e.nativeEvent.changedTouches[0].target.offsetLeft;
      y = e.nativeEvent.changedTouches[0].pageY - e.nativeEvent.changedTouches[0].target.offsetTop;
    }
    this.props.onBodyClick(x, y, this.state.currentBody);
  }
  nextBody() {
    const { currentBody } = this.state;
    const idx = bodies.indexOf(currentBody);
    this.setState({
      currentBody: bodies[(idx + 1) % 4],
    });
  }
  prevBody() {
    const { currentBody } = this.state;
    const idx = bodies.indexOf(currentBody);
    let newIndex = idx - 1;
    if (newIndex < 0) {
      newIndex = 3;
    }
    this.setState({
      currentBody: bodies[newIndex],
    });
  }
  render() {
    const { hideLabels, scale, selectedLocations } = this.props;
    const validLocations = selectedLocations.filter(l => l.body_location === this.state.currentBody);
    const painPoints = map(validLocations, (l) => {
        return <PainPoint hideLabels={hideLabels} key={l.id} location={l} scale={scale} />;
      });

    const painLines = makeLines(validLocations, scale);

    const svgStyle = Object.assign({}, baseStyles.svgStyle, {
      height: `${Math.round(525 * scale)}px`,
      width: `${Math.round(250 * scale)}px`,
    });

    const containerStyle = Object.assign({}, baseStyles.container, {
      minHeight: `${Math.round(525 * scale)}px`,
      minWidth: `${Math.round(200 * scale)}px`,
    });

    const rightIconStyle = Object.assign({}, baseStyles.rightIcon, {
      marginLeft: 220 * scale,
    });

    return (
      <div style={containerStyle}>
        <img
          alt="The human body"
          height={`${Math.round(525 * scale)}px`}
          width={`${Math.round(250 * scale)}px`}
          style={baseStyles.absolute}
          src={`/img/body-pain-${this.state.currentBody}.png`}
        />
        <svg
          style={svgStyle}
        >
          {painLines}
        </svg>
        <div
          onClick={this.imgClick}
          style={svgStyle}
        >
          {painPoints}
        </div>
        <div style={baseStyles.btnContainer}>
          <IconButton onClick={this.prevBody} height={`${Math.round(300 * scale)}px`}><LeftIcon /></IconButton>
          <IconButton onClick={this.nextBody} style={rightIconStyle}><RightIcon /></IconButton>
        </div>
      </div>
    );
  }
}

PainBody.defaultProps = {
  selectedLocations: [],
  onBodyClick: noop,
};

PainBody.propTypes = {
  selectedLocations: PropTypes.array,
  onBodyClick: PropTypes.func,
};

export default PainBody;
