import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop, orderBy } from 'lodash';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Point from '@material-ui/icons/ArrowDropDown';
import CircularProgress from 'material-ui/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import AppointmentListItem from './appointment-list-item';

import { colors } from '../lib/styles';

const StyledTablePagination = withStyles({
  toolbar: {
    paddingLeft: 5,
  },
  caption: {
    fontSize: '1rem',
  },
  select: {
    marginRight: '4px',
  },
})(TablePagination);

const baseStyles = {
  caption: {
    fontSize: '1rem',
  },
  clickableTableHeader: {
    boxSizing: 'border-box',
    color: colors.black,
    cursor: 'pointer',
    fontSize: '1rem',
    padding: '4px 10px',
    textAlign: 'left',
    width: '14%',
    whiteSpace: 'nowrap',
  },
  container: {
    minWidth: '768px',
    paddingLeft: '20px',
  },
  displayNumberMenuIcon: {
    width: '140px',
    height: '30px',
    marginRight: '10px',
  },
  dropDownSortIcon: {
    color: colors.lightGreyText,
    height: '2rem',
    verticalAlign: 'middle',
  },
  headerText: {
    fontWeight: 100,
    textAlign: 'left',
  },
  headerTitle: {
    fontWeight: 100,
    fontSize: '1.4rem',
  },
  table: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.highlightMedium}`,
    height: '100%',
    minWidth: '768px',
    tableLayout: 'fixed',
  },
  tableHead: {
    backgroundColor: colors.hanaTableHeader,
  },
  tinyTableHeader: {
    boxSizing: 'border-box',
    minWidth: '100px',
    width: '7.5%',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    color: colors.black,
    fontSize: '1rem',
  },
  tableHeader: {
    boxSizing: 'border-box',
    minWidth: '100px',
    width: '13.33%',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    color: colors.black,
    fontSize: '1rem',
  },
  pagination: {
    display: 'flex',
    fontSize: '.65rem',
    fontWeight: 600,
    margin: '5px 5px 5px 0',
    width: '220px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paginationBox: {
    width: '40px',
    height: '25px',
    margin: '0 10px',
  },
  subHeaderText: {
    display: 'flex',
    color: colors.highlightDark,
    margin: '5px 5px 5px 0',
    fontSize: '0.875rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

class AppointmentList extends Component {
  constructor(props) {
    super(props);
    const max = Math.ceil(this.props.appointments.length/5);
    let prevStyle = colors.fieldLabelGray;
    let nextStyle = colors.black;
    if(max === 1) {
      nextStyle = colors.fieldLabelGray;
    }
    this.state = {
      currentTime: moment(),
      displayNumber: 10,
      sortBy: 'id',
      sortDir: 'desc',
      tick: 0,
      page: 0,
      nextStyle,
      prevStyle,
    };

    this.tick = this.tick.bind(this);
    this.updateSort = this.updateSort.bind(this);
    this.sortByWaitTime = this.sortByWaitTime.bind(this);
    this.sortByName = this.updateSort.bind(this, 'last_name');
    this.sortByComplete = this.updateSort.bind(this, 'complete');
  }
  componentDidMount() {
    // Basically just forcing components below to rerender
    // so wait times update
    this.interval = setInterval(this.tick, 60000);
    this.tick();
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  updateSort(col) {
    const { sortBy, sortDir } = this.state;
    if (sortBy === col) {
      return this.setState({
        sortDir: sortDir === 'asc' ? 'desc' : 'asc',
      });
    }

    return this.setState({
      sortDir: 'desc',
      sortBy: col,
    });
  }
  sortByWaitTime() {
    this.updateSort(this.props.waitField);
  }
  tick() {
    this.setState({
      currentTime: moment(),
    });
  }
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ displayNumber: event.target.value });
  };
  render() {
    let { displayNumber, page } = this.state;
    const {
      addRecent, appointments, classes, id, isFetching,
      title, updateAppointment, waitField,
    } = this.props;
    const validApointments = appointments.filter(a => a.status !== 'CHECKED_OUT');
    const numAppointments = validApointments.length;

    const dropDownSortIcon = <Point style={baseStyles.dropDownSortIcon} />;

    if (isFetching && !numAppointments) {
      return (
        <div style={baseStyles.container}>
          <h1 style={baseStyles.headerTitle}>{title}</h1>
          <p>Loading appointments..</p>
          <CircularProgress size={100} thickness={7} />
        </div>
      );
    }

    if (!numAppointments) {
      return (
        <div style={baseStyles.container}>
          <div style={baseStyles.headerText}>
            <h1 style={baseStyles.headerTitle}>{title}</h1>
            <p style={baseStyles.subHeaderText}>No patients checked in at this time.</p>
          </div>
        </div>
      );
    }

    const rowStyle = [{ background: colors.hanaTableEvenRows }, {}];
    // Sorts and truncates the appointment list
    let count = 0;
    let start = page * displayNumber;
    const sortedAppointments = orderBy(validApointments, [this.state.sortBy], [this.state.sortDir]).slice(start, (displayNumber * page) + displayNumber);
    const appointmentRows = sortedAppointments.map((appointment) => {
      if (count > 0) {
        count = 0;
      } else {
        count++;
      }
      return (
        <AppointmentListItem
          appointment={appointment}
          addRecent={addRecent}
          currentTime={this.state.currenttime}
          key={`appointment-${appointment.id}`}
          providers={this.props.providers}
          signatureDocuments={this.props.signatureDocuments}
          updateAppointment={updateAppointment}
          waitField={waitField}
          router={this.props.router}
          highlight={rowStyle[count]}
        />
      );
    });

    return (
      <div id={id} style={baseStyles.container}>
        <div style={baseStyles.headerText}>
          <h1 style={baseStyles.headerTitle}>{title}</h1>
        </div>
        <Table
          selectable="false"
          className={classes.table}
        >
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell
                onClick={this.sortByName}
                className={classes.clickableTableHeader}
              >Patient Name{dropDownSortIcon}
              </TableCell>
              <TableCell className={classes.tableHeader}>Date of Birth</TableCell>
              <TableCell className={classes.tinyTableHeader} padding="none">Sex</TableCell>
              <TableCell className={classes.tableHeader}>Clinician</TableCell>
              <TableCell className={classes.tableHeader}>Check Out</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointmentRows}
          </TableBody>
          <TableFooter>
              <TableRow>
                <StyledTablePagination
                  colSpan={5}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  count={validApointments.length}
                  rowsPerPage={displayNumber}
                  rowsPerPageOptions={[10, 20, 50]}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  className={classes.caption}
                />
              </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }
}

AppointmentList.defaultProps = {
  appointments: [],
  id: null,
  isFetching: false,
  providers: [],
  signatureDocuments: [],
  title: '',
  updateAppointment: noop,
  waitField: '',
  waitText: '',
};

AppointmentList.propTypes = {
  addRecent: PropTypes.func.isRequired,
  appointments: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  isFetching: PropTypes.bool,
  providers: PropTypes.array,
  signatureDocuments: PropTypes.array,
  title: PropTypes.string,
  updateAppointment: PropTypes.func,
  waitField: PropTypes.string,
  waitText: PropTypes.string,
  router: PropTypes.object.isRequired,
};

export default withStyles(baseStyles)(AppointmentList);
