import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { browseIcaSubmissions } from '../state/clinical-form-submissions';
import AppWrapper from '../wrappers/app-wrapper';

import FormSubmissions from '../components/form-submissions';

// TODO The goal here is to eventually make the final part of the url
// a parameter and choose the correct form submission list
// const formTypes = {
//   'ica-submissions': FormSubmissions,
// };

export class IcaSubmissions extends PureComponent {
  componentWillMount() {
    // TODO: Just using this to prevent randos from somehow navigating here
    // Eventually will be based on the feature
    if (!this.props.appFeatures.length) {
      this.props.router.push('/app');
    }
  }
  render() {
    // TODO: We'll do this dynamic later
    return (
      <AppWrapper router={this.props.router}>
        <FormSubmissions
          data={this.props.icaSubmissions}
          fetchData={this.props.browseIcaSubmissions}
        />
      </AppWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {
    clinic,
    clinicalFormSubmissions,
  } = state;

  return {
    appFeatures: clinic.data.app_features || [],
    icaSubmissions: clinicalFormSubmissions.icaSubmissions,
  };
}

const actionCreators = {
  browseIcaSubmissions,
};

IcaSubmissions.propTypes = {
  appFeatures: PropTypes.array.isRequired,
  browseIcaSubmissions: PropTypes.func.isRequired,
  icaSubmissions: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, actionCreators)(IcaSubmissions);
