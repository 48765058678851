import React from 'react';
import { CircularProgress } from 'material-ui';

const progress = {
  height: '400px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default function Loading() {
  return (
    <div style={progress}>
      <CircularProgress size={150} />
    </div>
  );
}
