import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px',
    margin: '10px',
  },
  fieldTitle: {
    alignContent: 'right',
    textAlign: 'right',
    minWidth: '40%',
  },
  fieldValue: {
    marginLeft: '10px',
    textAlign: 'left',
  },
};

class EmrDataField extends PureComponent {
  render() {
    const { props } = this;

    return (
      <div className={props.classes.container}>
        {props.leftCheckbox}<div className={props.classes.fieldTitle}>{props.field}: </div><div className={props.classes.fieldValue}>{props.value || '---' }</div>
      </div>
    );
  }
};

EmrDataField.defaultProps = {
  leftCheckbox: null,
};

EmrDataField.propTypes = {
  leftCheckbox: PropTypes.node,
  field: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default withStyles(styles)(EmrDataField);
