// These env vars are set automatically as a result of
// npm run test, start, and build

// TODO: Remove this atrocity that exists
// solely because create-react-app doesnt let us set env vars
function getApiUrl() {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser && window.location.hostname.includes('mindsetmedical')) {
    const [subdomain, hostname, tld] = window.location.href.split('.');
    let api = 'api';

    if (subdomain.includes('staging')) {
      api = 'api-staging';
    }

    return `https://${api}.${hostname}.${tld.split('/')[0]}`;
  }
  return 'http://localhost:3001';
}

const env = {
  test: {
    API_URL: process.env.REACT_APP_API_URL || 'https://api-staging.mindsetmedical.com',
    AMPLITUDE_API_KEY: '3e6590c8f4270b847ea7ab0a0d535e1c',
  },
  development: {
    API_URL: process.env.REACT_APP_API_URL || getApiUrl(),
    AMPLITUDE_API_KEY: '3e6590c8f4270b847ea7ab0a0d535e1c',
  },
  staging: {
    API_URL: process.env.REACT_APP_API_URL || getApiUrl(),
    AMPLITUDE_API_KEY: '3e6590c8f4270b847ea7ab0a0d535e1c',
  },
  production: {
    API_URL: process.env.REACT_APP_API_URL || getApiUrl(),
    AMPLITUDE_API_KEY: '3e6590c8f4270b847ea7ab0a0d535e1c',
  },
};

export default env[process.env.NODE_ENV];
