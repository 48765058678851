import 'isomorphic-fetch';
import React from 'react';
import { render } from 'react-dom';
import { get } from 'lodash';
import { Router, Route, browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles'; // v1.x
import { MuiThemeProvider as V0MuiThemeProvider} from 'material-ui';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import store from './store';
import amplitudeInitializer from './initializers/amplitude';
import activityInitializer from './initializers/activity';
import ConnectedApp from './pages/app';
import ConnectedAppointmentView from './pages/appointment-view';
import ConnectedLogin from './pages/login';
import ConnectedClinicSelect from './pages/clinic-selection';
import ConnectedIcaForm from './pages/ica-form';
import ConnectedIcaSubmissions from './pages/ica-submissions';
import ConnectedPostOpPro from './pages/pro-compliance-post-op';
import ConnectedProCompliance from './pages/pro-compliance';
import PatientList from './pages/patient-listing-view';
import DeviceList from './pages/device-listing-view';
import { verifyUser } from './actions/user';
import { selectClinic } from './state/clinic';
import './index.css';
import theme from './lib/muiTheme';

const themeV0 = getMuiTheme({
 /* theme for v0.x */
  fontFamily: 'Calibri, Univers LT Std, sans-serif',
});

function requireAuth(nextState, replace, cb) {
  return store.dispatch(verifyUser())
    .then(() => {
      const noClinic = !(get(store.getState(), 'clinic.clinicId'));
      if (!(nextState.location.pathname === '/clinic-select') && noClinic) {
        try {
          store.dispatch(selectClinic());
        } catch (e) {
          replace({ pathname: '/clinic-select' });
        }
      }
    })
    .then(() => cb())
    .catch(() => {
      replace({ pathname: '/' });
      cb();
    });
}

amplitudeInitializer();
activityInitializer(store);
// TODO: Form and form-submission routes should be dynamic rather than hardcoded to ica in v2
render((
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <V0MuiThemeProvider muiTheme={themeV0}>
        <Router history={browserHistory}>
          <Route path="/" component={ConnectedLogin} />
          <Route path="/app" component={ConnectedApp} onEnter={requireAuth} />
          <Route path="/app/patientlisting" component={PatientList} onEnter={requireAuth}/>
          <Route path="/app/patient" component={ConnectedAppointmentView} onEnter={requireAuth} />
          <Route path="/app/forms/ica" component={ConnectedIcaForm} onEnter={requireAuth} />
          <Route path="/app/form-submissions/ica" component={ConnectedIcaSubmissions} onEnter={requireAuth} />
          <Route path="/app/pro/postop" component={ConnectedPostOpPro} onEnter={requireAuth} />
          <Route path="/app/pro/compliance" component={ConnectedProCompliance} onEnter={requireAuth} />
          <Route path="/clinic-select" component={ConnectedClinicSelect} onEnter={requireAuth} />
          <Route path="/app/devicelisting" component={DeviceList} onEnter={requireAuth}/>
        </Router>
      </V0MuiThemeProvider>
    </MuiThemeProvider>
  </Provider>
), document.getElementById('root'));
