import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { colors } from '../lib/styles';

const styles = {
  container: {
    backgroundColor: 'white',
    marginTop: '10px',
    borderRight: `2px solid ${colors.boxShadowOutline}`,
    borderBottom: `1px solid ${colors.boxShadowOutline}`,
  },
};

class PatientViewBottomHalf extends PureComponent {
  render() {
    return (
      <div className={this.props.classes.container}>
        {this.props.children}
      </div>
    );
  }
}

export default withStyles(styles)(PatientViewBottomHalf)
