import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from './checkbox';
import { withStyles } from '@material-ui/core/styles';
import { colors, tableStyles } from '../lib/styles';

const tableCellStyle = {
  backgroundColor: colors.lightGrayText,
  padding: '4px 10px 4px 10px',
  minWidth: '101px',
  ...tableStyles.cell,
};

const smallCellStyle = Object.assign({}, tableCellStyle, {
  backgroundColor: colors.lightGrayText,
  maxWidth: '30px',
  minWidth: '30px',
});

const checkCellStyle = Object.assign({}, tableCellStyle, {
  backgroundColor: colors.lightGrayText,
  paddingRight: '0px',
  marginRight: '0px',
  width: '10px',
});


const styles = {
  head: {
    backgroundColor: colors.lightGrayText,
  },
};

class SortedTableHead extends Component {
  static defaultProps = {
    children: null,
    columnData: [],
    order: '',
    orderBy: '',
  };

  static propTypes = {
    children: PropTypes.node,
    columnData: PropTypes.array,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    onRequestSort: PropTypes.func.isRequired,
  };

  createSortHandler = property => (event) => {
    this.props.onRequestSort(event, property);
  }
  handleSelectAllClick = () => {
    this.props.onChangeCheckbox();
  }

  render() {
    const { columnData, order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {this.props.includeCheckbox && (
            <TableCell padding="none" style={checkCellStyle}>
              <Checkbox
                checked={this.props.allChecked}
                onChange={this.handleSelectAllClick}
              />
            </TableCell>
          )}
          {columnData.map((column) => {
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
                className={this.props.classes.head}
                style={column.small === true ? smallCellStyle : tableCellStyle}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          })}
          {this.props.children}
        </TableRow>
      </TableHead>
    );
  }
}

export default withStyles(styles)(SortedTableHead)
