import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Avatar from './patient-avatar';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import openForm from '../lib/open-form';
import { colors } from '../lib/styles';
import CheckInBtn from './check-in-button';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

const baseStyles = {
  appointmentName: {
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    textAlign: 'left',
    width: '70%',
    boxSizing: 'border-box',
    padding: 'none',
    textOverflow: 'ellipsis-word',
    whiteSpace: 'nowrap',
  },
  avatar: {
    height:'60px',
    width:'60px',
  },
  clinicianDropdown: {
    border: '1px solid black',
    paddingLeft: '5px',
  },
  firstLastName: {
    marginLeft: '8px',
    fontSize: '1rem',
    textOverflow: 'ellipsis-word',
    whiteSpace: 'nowrap',
  },
  menuIcon: {
    verticalAlign: 'middle',
  },
  statusMenuLabel: {
    color: colors.black,
    paddingLeft: '0px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  statusMenuUnderline: {
    display: 'none',
  },
  td: {
    fontSize: '1rem',
  },
  statusTd: {
    padding: '4px 0px',
  },
  providerTd: {
    padding: '4px 0px',
  },
  checkoutColumn: {
    paddingRight: '0 !important',
    width: '20%',
  },
};

class AppointmentListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.onOpenAppointmentView = this.onOpenAppointmentView.bind(this);
    this.onOpenAllForms = this.onOpenAllForms.bind(this);
    this.onOpenInsuranceForm = this.onOpenInsuranceForm.bind(this);
    this.onChangeProvider = this.onChangeProvider.bind(this);
  }
  onChangeProvider(evt) {
    evt.nativeEvent.stopImmediatePropagation();

    this.props.updateAppointment(this.props.appointment.id, { provider_id: evt.target.value });
  }
  onOpenAppointmentView() {
    this.props.addRecent(this.props.appointment);
    this.props.router.push({ pathname: '/app/patient', state: { userId: this.props.appointment.user_id } });
  }
  onOpenAllForms() {
    openForm(this.props.appointment.user_id, 'all');
  }
  onOpenInsuranceForm() {
    openForm(this.props.appointment.user_id, 'INSURANCE');
  }

  render() {
    const { appointment, classes } = this.props;

    const providerMenu = (
      <FormControl className={classes.formControl}>
        <NativeSelect
          value={appointment.provider_id || null}
          onChange={this.onChangeProvider}
          name=""
          className={classes.clinicianDropdown}
        >
          <option value={null}>None Selected</option>
          {this.props.providers.map((provider) => {
            return (<option key={provider.id} value={provider.id}>{provider.name}</option>);
          })}
        </NativeSelect>
      </FormControl>
    );

    const sex = appointment.sex ? appointment.sex.split('') : '';
    return (
      <TableRow
        selected={appointment.selected}
        style={this.props.highlight}
      >
        <TableCell
          onClick={this.onOpenAppointmentView}
          className={classes.appointmentName}
        >
          <Avatar
            image={appointment.avatarImgSmall}
            classes={{ avatar: classes.avatar }}
          />
          <div style={baseStyles.firstLastName}>{`${appointment.last_name}, ${appointment.first_name}`}</div>
        </TableCell>

        <TableCell className={classes.td}>{moment(appointment.birth_date).format('MM/DD/YYYY')}</TableCell>
        <TableCell className={classes.td} padding="none">{sex[0]}</TableCell>
        <TableCell className={classes.providerTd}>{providerMenu}</TableCell>
        <TableCell style={baseStyles.checkoutColumn}>
          <CheckInBtn
            patient={appointment}
            checkedIn={true}
            onUpdateAppointment={this.props.updateAppointment}
            onCreateAppointment={this.props.updateAppointment}
          />
        </TableCell>
      </TableRow>

    );
  }
}

AppointmentListItem.defaultProps = {
  providers: [],
};

AppointmentListItem.propTypes = {
  addRecent: PropTypes.func.isRequired,
  appointment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    checked_in: PropTypes.string,
    checked_out: PropTypes.string,
    clinicians: PropTypes.array,
    complete: PropTypes.number,
    in_session: PropTypes.string,
    status: PropTypes.string.isRequired,
    user_id: PropTypes.string.isRequired,
  }).isRequired,
  providers: PropTypes.array,
  router: PropTypes.object.isRequired,
  updateAppointment: PropTypes.func.isRequired,
};

export default withStyles(baseStyles)(AppointmentListItem);
