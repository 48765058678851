import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, TextField } from 'material-ui';
import Button from '@material-ui/core/Button';
import { colors } from '../lib/styles';

const styles = {
  successMessage: {
    color: colors.successGreen,
    position: 'absolute',
    left: '20px',
    alignSelf: 'center',
  },
  errorMessage: {
    color: colors.errorRed,
    position: 'absolute',
    left: '20px',
    alignSelf: 'center',
  },
  buttonStyle: {
    fontSize: '16px',
    height: '40px',
    color: '#fff',
    backgroundColor: colors.secondaryColor,
  },
};

class InviteDialog extends Component {
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.handleCloseNotification = this.handleCloseNotification.bind(this);
    this.handleSendInvite = this.handleSendInvite.bind(this);

    this.state = {
      email: '',
      inviteMessage: '',
    };
  }
  handleUpdate(target, value) {
    this.setState({ email: value });
  }
  validateEmail() {
    const valid = this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if(valid) {
      return this.handleSendInvite(this.state.email);
    }
    this.setState({ inviteMessage: <span style={styles.errorMessage}>Invalid Email Address</span>});
  }
  updateUserInviteInput(target, value) {
    this.setState({ userInviteInput: value });
  }
  handleCloseNotification() {
    this.setState({
      inviteMessage: '',
    });
    this.props.toggleUserInviteDialog();
  }
  handleSendInvite(email) {
    const options = {
      method: 'POST',
      body: {
        user_email: email,
        app_type: 'HANA_PROVIDER',
      },
    };
    this.props.apiFetch(`/clinics/${this.props.clinicId}/clinic_access_request`, options)
      .then(() => {
        this.setState({
          inviteMessage: <span style={styles.successMessage}>Email Sent</span>,
        });
        setTimeout(() => {
          this.setState({ inviteMessage: '' });
          this.props.toggleUserInviteDialog();
        }, 1000);
      })
      .catch(() => {
        this.setState({
          inviteMessage: <span style={styles.errorMessage}>Error Sending Invite. Please try again later.</span>,
        });
      });
  }
  render() {
    const {
      userInviteDialog,
    } = this.props;

    const dialogActions = (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {this.state.inviteMessage}
        <Button
          children="Cancel"
          onClick={this.handleCloseNotification}
          style={styles.buttonStyle}
        />
        <Button
          children="Send Email"
          onClick={this.validateEmail}
          style={styles.buttonStyle}
        />
      </div>
    );

    return (
      <div>
        <Dialog
          title="Invite a Patient"
          actions={dialogActions}
          modal={true}
          open={userInviteDialog}
        >
          Please enter the email address of the person you would like to invite!
          <br />
          <TextField
            hintText="Email Address"
            floatingLabelText="Email Address"
            onChange={this.handleUpdate}
          />
        </Dialog>
      </div>
    );
  }
}

InviteDialog.propTypes = {
  userInviteDialog: PropTypes.bool,
  handleSendInvite: PropTypes.func,
  toggleUserInviteDialog: PropTypes.func,
};

export default InviteDialog;

