import theme from './muiTheme';

export const colors = {
  // Style Guide Colors
  primaryColor: '#622567', // purple color
  secondaryColor: '#00AB8E', // minty color
  blueGray: '#7f8fa4',
  blueSelect: '#68C2DA',
  btnBlue: '#0098cf',
  bottomNavigationHighlighted: '#0098d0',
  charcoal: '#595959',
  almostBlack: '#333333',
  darkGrayText: '#9b9b9b',
  highlightGray: '#edf2f5',
  lightGrayText: '#d8d8d8',
  fieldLabelGray: '#66676c',
  formTextBlue: '#0098d0',
  calendarGray: '#bac2c7',
  calendarGreen: '#309940',
  calendarYellow: '#eea321',
  calendarRed: '#e54353',
  errorRed: '#dc3636',
  errorOrange: '#de4f18',
  imageBlue: '#0098d0',
  imageGreen: '#c4c626',
  linearProgressPurple: '#800080',
  progressCircleYellow: 'rgba(198,205,96,0.6)',
  questionBackground: '#f5f5f5',
  successGreen: '#4eda1f',
  teaGreen: '#5bae81',
  white: '#ffffff',
  black: '#000000',
  boxShadowOutline: '#ceced2',
  lightGreyText: '#555555',
  painSelectionGrey: '#cbcbcb',
  painSelectionHeaderBackground: '#959595',
  theBetterWayBackBlue: '#0098d0',
  theBetterWayBackGreyText: '#959595',
  // Previous mindset colors that might be used in the app still
  primaryHighlight: '#ecf5fe',
  primaryAccent: '#596097',
  secondaryAccent: '#de4f18',
  darkAccent: '#3c3d3f',
  highlightBright: '#edeef0',
  highlightMedium: '#b8c5d0',
  highlightDark: '#9da4ad',
  highlightDarker: '#3c4c65',
  backgroundImportColor: '#8fbde5',
  lightBrightBlue: '#3ebcf9',
  painBlue: '#5f88ab',
  hanaTableHeader: '#CACAC7',
  hanaTableEvenRows: '#F5F5F5',
  hanaTableBorder: '#DDDDDA',
  hanaTableNavigation: '#66676C',
  hanaTableCheckInBtn: '#622567',
};

// Note: Reference px based on Average Zeplin page of 750 width however some are oddly sized
// Check the width of the zeplin page and reduce/enlarge the pixels by ratio difference accordingly
export const fontSizes = {
  smaller: '1.5rem', // 24px
  small: '1.625rem', // 26px
  medium: '1.75rem', // 28px
  large: '1.875rem', // 30px
  larger: '2.125rem', // 34px
  xlarge: '2.375rem', // 38px
  xxlarge: '2.5rem', // 40px
};

export const calendarPainColors = {
  painLevel1: '#309940',
  painLevel2: '#eea321',
  painLevel3: '#e54353',
};

export const checkbox = {
  marginBottom: '16 px',
  marginTop: '16px',
  width: '20px',
  height: '20px',
  flex: 1,
};

export const blueBtnStyle = {
  backgroundColor: colors.btnBlue,
  border: `1px solid ${colors.btnBlue}`,
  boxShadow: 'none',
  color: 'white',
  fontWeight: 600,
  margin: theme.spacing.unit,
  textColor: 'white',
  height: '42px',
}

export const custom = {
  blueCenter: {
    color: colors.primaryColor,
    textAlign: 'center',
  },
  blueText: {
    color: colors.primaryColor,
  },
  blueText16: {
    color: colors.primaryColor,
    fontSize: '16px',
  },
  blueTextSmall: {
    color: colors.primaryColor,
    fontSize: '12px',
  },
  blueTextTopMargin: {
    color: colors.primaryColor,
    marginTop: '20px',
  },
  blueTextMarginTop: {
    color: colors.primaryColor,
    marginTop: '15px',
  },
  blueTextNoMargin: {
    color: colors.primaryColor,
    margin: 0,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerWithColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  clipboard: {
    marginBottom: '20px',
    width: '156px',
    height: '222px',
  },
  confirmPageText: {
    color: colors.primaryColor,
    textAlign: 'center',
    marginLeft: '35px',
    marginRight: '35px',
    paddingBottom: '20px',
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  greenCheckMark: {
    marginBottom: '90px',
    width: '90px',
    height: '90px',
  },
  header: {
    color: colors.black,
    fontSize: '2.625rem',
  },
  highlightDark: {
    color: colors.highlightDark,
  },
  highlightDarker: {
    color: colors.highlightDarker,
  },
  highlightMediumLine: {
    borderColor: colors.highlightMedium,
  },
  highlightMediumText: {
    color: colors.highlightMedium,
    marginLeft: '15px',
    marginRight: '15px',
  },
  hintText: {
    color: colors.highlightMedium,
    textAlign: 'left',
  },
  raisedButton: {
    boxShadow:'none',
    borderRadius:'6px',
  },
  infoUpdatedSuccess: {
    display: 'flex',
    flexDirection: 'column',
    height: '90vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginBottomText: {
    cursor: 'pointer',
    textDecoration: 'underline',
    textAlign: 'left',
    marginTop: '3rem',
    marginLeft: '4rem',
  },
  loginBtnLabel: {
    fontSize: '18px',
    lineHeight: '2.9',
  },
  loginBtnStyle: {
    height: '50px',
    width: '80vw',
    maxWidth: '445px',
    marginTop: '40px',
  },
  loginField: {
    borderRadius: '10px',
    height: '70px',
    marginTop: '5px',
    marginBottom: '5px',
  },
  loginShrinkStyle: {
    color: colors.darkGrayText,
  },
  loginTextFieldInput: {
    backgroundColor: colors.white,
    color: colors.black,
    padding: '10px',
  },
  loginTextFieldLabel: {
    color: colors.darkGrayText,
    fontSize: '18px',
    margin: '5px 15px',
  },
  marginBottom: {
    marginBottom: '20px',
  },
  marginLeft15: {
    marginLeft: '15px',
  },
  marginRight15: {
    marginRight: '15px',
  },
  marginTop: {
    marginTop: '30px',
  },
  marginTop100: {
    marginTop: '100px',
    marginBottom: '50px',
  },
  menuItem: {
    color: colors.primaryColor,
  },
  primaryHighlight: {
    backgroundColor: colors.primaryHighlight,
  },
  redButton: {
    backgroundColor: colors.errorRed,
  },
  redErrorText: {
    color: colors.errorRed,
    marginLeft: '15px',
    marginRight: '15px',
    textAlign: 'center',
  },
  signupBox: {
    margin: '0 auto 100px',
    textAlign: 'center',
    width: '500px',
  },
  signupBtnLabel: {
    color: colors.white,
    fontSize: '1.3rem',
  },
  signupBtnSpacing: {
    margin: '25px',
  },
  signupBtnStyle: {
    backgroundColor: colors.secondaryColor,
    width: '85vw',
    maxWidth: '445px',
    height: '4.25rem',
  },
  signupCenter: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
    height: '90vh',
    width: '90%',
    margin: '0 auto',
    padding: '10px',
  },
  signupContainer: {
    backgroundColor: '#f5f5f5',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  signupErrorStyle: {
    marginLeft: '15px',
  },
  signupErrorText: {
    color: colors.errorRed,
    height: '18px',
    marginTop: '5px',
    textAlign: 'center',
  },
  signupForm: {
    width: '100%',
    margin: 'auto',
  },
  signupHeaderImg: {
    height: '180px',
    width: '180px',
  },
  signupHeaderText: {
    color: colors.black,
    paddingTop: '2.6875rem',
    paddingBottom: '2.6875rem',
    textAlign: 'center',
  },
  signupPaperStyle: {
    width: '100vw',
    maxWidth: '445px',
  },
  signupTextArea: {
    color: colors.black,
    marginLeft: '1.9375rem',
  },
  signupSubHeader: {
    color: 'rgba(0, 0, 0, 0.75)',
    fontSize: '1.5rem',
    fontWeight: 100,
    padding: '3px',
  },
  subtitleSection: {
    fontSize: '10px',
    color: colors.primaryColor,
    textAlign: 'left',
    marginLeft: '15px',
  },
  textArea: {
    border: 'none',
    background: colors.white,
    width: '100%',
    textAlign: 'center',
    height: '100px',
    color: colors.primaryColor,
    fontSize: '16px',
  },
  top: {
    top: 0,
  },
  underline: {
    display: 'none',
  },
  whiteSection: {
    backgroundColor: colors.white,
    textAlign: 'left',
  },
  whiteSectionWithPadding: {
    backgroundColor: colors.white,
    padding: '20px',
  },
  whiteSectionBlueText: {
    backgroundColor: colors.white,
    color: colors.highlightMedium,
    marginLeft: '15px',
    marginRight: '15px',
    fontSize: '18px',
  },
  whiteBackground: {
    backgroundColor: colors.white,
  },
};


export const tableStyles = {
  head: {
    background: colors.lightGrayText,
    color: colors.black,
    fontWeight: 600,
    fontSize: '1rem',
  },
  table: {
    border: `1px solid ${colors.lightGrayText}`,
    minHeight: '200px',
  },
  cell: {
    color: colors.black,
    fontWeight: 600,
    fontSize: '1rem',
  },
}

export const backgroundImageStyle = {
  background: 'url(/img/loginBackgroundImg.jpg) no-repeat center center fixed',
  backgroundSize: 'cover',
  filter: 'alpha(opacity=25)',
  height: '100%',
  opacity: 0.25,
  position: 'absolute',
  width: '100%',
  zIndex: '-1',
};

export const emrPageStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  checkbox: {
    padding: '0 10px 0 0',
  },
  centerEmrDataFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px',
    margin: '0 0 9px 0',
  },
  emrDataFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px',
    margin: '0px 10px',
  },
  fieldTitle: {
    alignContent: 'left',
    textAlign: 'left',
    minWidth: '40%',
  }
};
