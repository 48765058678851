import SocketIO from 'socket.io-client';

import config from './config';
import store from './store';
import { apiFetch } from './lib/fetch';
import { addAppointment } from './state/appointments';
import { createNotification } from './actions/notifications';
import { killQR } from './state/devices';

let createdSocket;

function getSocket() {
  if (!createdSocket) {
    createdSocket = SocketIO(`${config.API_URL}`);
  }
  return createdSocket;
}

export function subscribeToClinic(clinicId) {
  const socket = getSocket();
  socket.emit('subscribe_clinic', { clinic_id: clinicId });
  socket.on(`clinic_${clinicId}`, (data) => {
    if (data.type === 'NEW_APPOINTMENT') {
      apiFetch(`/clinics/${clinicId}/appointments/${data.id}`)
        .then((appointment) => {
          const notification = {
            appointmentId: appointment.id,
            newStatus: appointment.status,
          };
          store.dispatch(addAppointment(appointment));
          store.dispatch(createNotification(notification));
        });
    } else if (data.type === 'DEVICE_LOGIN') {
      store.dispatch(killQR(data.id));
    }
  });
}