import React from 'react';
import { colors } from '../lib/styles';

const baseStyles = {
  copyrightContainer: {
    display: 'flex',
    fontSize: '0.75rem',
    marginTop: '1rem',
    justifyContent: 'space-around',
  },
  terms: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    textDecoration: 'none !important',
  },
  termsContainer: {
    marginTop: '8rem',
    color: colors.black,
    alignSelf: 'center',
    width: '225px',
    position: 'absolute',
    bottom: '2rem',
  },
};

const TermsOfUse = () => (
    <div style={baseStyles.termsContainer} >
      <a href="https://mindsetmedical.com/termsofuse/" styles={baseStyles.terms} target="_blank" rel="noopener noreferrer"><div style={baseStyles.terms}>Terms of Use</div></a>
      <div style={baseStyles.copyrightContainer}>
        <div>2018 NuVasive, Inc.</div>
        <div>All Rights Reserved</div>
      </div>
    </div>
);

export default TermsOfUse;
