import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import PatientSummary from './patient-summary';
import PatientSummaryBtns from './patient-summary-btns';
import PatientViewContentSection from './patient-view-content-section';

import PatientViewHeader from './patient-view-header';
import PatientViewFooter from './patient-view-footer';

import ProSubmissionsTable from './pro-submissions-table';
import { withStyles } from '@material-ui/core/styles';
import { blueBtnStyle, colors } from '../lib/styles';
import PatientHeaderProgressCircles from './patient-header-progress-circles';
import theme from '../lib/muiTheme';
import { logEvent } from '../lib/amplitude';

const styles = {
  btn: blueBtnStyle,
  btnLabel: {
    textTransform: 'capitalize',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  menuItem: {
    root: 'menu-item',
    selected: 'selected',
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  proSelect: {
    width: '200px',
    border: `1px solid ${colors.charcoal}`,
    borderRadius: '6px',
    height: '40px',
    color: colors.charcoal,
  },
  proSelection: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: '1rem',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  topSectionIcon: {
    color: colors.primaryColor,
    height: '18px',
    width: '18px',
  },
  underline: {
    textDecoration: 'none',
    backgroundColor: colors.black,
  },
};

const requestedPros = [
  //  "EQ5D",
  "NDI",
  "ODI",
  "PROMIS-29",
  //  "HIT-6",
  //  "MIDAS"
];

class ProSubmissionsTab extends Component {
  state = {
    requestedPro: [],
  };
  handleProChange = (evt) => {
    this.setState({
      requestedPro: evt.target.value
    });
  }
  handleSendProRequest = () => {
    this.state.requestedPro.forEach(requestedPro => {
      this.props.onCreateProSubmission(this.props.userId, requestedPro);
    });
    logEvent(`Select ${this.state.requestedPro.join(', ')} PRO`);
    this.setState({ requestedPro: [] });
  }

  render() {
    const { props } = this;
    const { classes } = props;

    return (
      <Fragment>
        <PatientViewHeader
          avatarImg={props.appointment.avatarImgLarge}
          centerContent={(
            <PatientSummary
              {...props.appointment}
              painLevel={props.painLevel}
              updateAppointment={props.updateAppointment}
            />
          )}
          centerButtons={(
            <PatientSummaryBtns {...props} />
          )}
          rightContent={(
            <PatientHeaderProgressCircles {...props} />
          )}
        />
        {this.props.children}
        <PatientViewContentSection>
          <section className={props.classes.proSelection}>
            <FormControl>
              <Select
                multiple
                displayEmpty
                value={this.state.requestedPro}
                onChange={this.handleProChange}
                className={classes.proSelect}
                input={<Input disableUnderline={true} />}
                renderValue={selected => {
                  if (selected.length === 0) {
                    return (<em>Select PROs</em>);
                  }
                  return selected.join(', ');
                }}
              >
                <MenuItem value={[]} disabled><em>Select PROs</em></MenuItem>
                {requestedPros.map(requestedPro => (
                  <MenuItem
                    key={requestedPro}
                    value={requestedPro}
                    selected
                    classes={classes.menuItem}
                    >
                    {requestedPro}
                  </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                className={classes.btn}
                onClick={this.handleSendProRequest}
                >
                Send PRO Request(s)
              </Button>
            </section>
          <ProSubmissionsTable proSubmissions={props.proSubmissions} />
          <PatientViewFooter router={props.router} />
        </PatientViewContentSection>
      </Fragment>
    );
  }
}

ProSubmissionsTab.defaultProps = {
  appointment: {},
  documentTemplates: [],
  handleSendSignatureRequest: noop,
  painLevel: '',
  signatureRequests: [],
  updateAppointment: noop,
};

ProSubmissionsTab.propTypes = {
  appointment: PropTypes.object,
  documentTemplates: PropTypes.array,
  handleSendSignatureRequest: PropTypes.func,
  painLevel: PropTypes.string,
  signatureRequests: PropTypes.array,
  updateAppointment: PropTypes.func,
  router: PropTypes.object,
};

export default withStyles(styles)(ProSubmissionsTab)
