import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { CalendarToday, Subject } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { colors } from '../lib/styles';
import { logEvent } from '../lib/amplitude';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 100,
    minWidth: '200px',
  },
  containerBottom: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginRight: '5px',
    verticalAlign: 'middle',
  },
  patientListingIcon: {
    verticalAlign: 'middle',
  },
  link: {
    textDecoration: 'none',
  },
  list: {
    listStyle: 'none',
    padding: 0,
    textAlign: 'left',
  },
  listItem: {
    color: colors.charcoal,
    padding: '10px 0',
    verticalAlign: 'middle',
    fontSize: '1rem',
  },
  logo: {
    cursor: 'pointer',
    height: '40px',
    margin: '0 auto 10px',
  },
  top: {
    flex: '1 1 auto',
  },
  rightsSection: {
    fontWeight: 500,
    fontSize: '.7rem',
    padding: '0 0 1rem 0.5rem',
    whiteSpace: 'nowrap',
  },
  section: {
    marginTop: '1rem',
  },
  supportIcon: {
    verticalAlign: 'middle',
  },
  supportListItem: {
    color: colors.charcoal,
    padding: '10px 0',
    fontSize: '1rem',
    verticalAlign: 'middle',
  },
  terms: {
    color: 'inherit',
    fontWeight: 600,
    paddingBottom: '10px',
    textDecoration: 'inherit',
  },
  userGuideListItem: {
    color: colors.charcoal,
    padding: '10px 0',
    fontSize: '1rem',
    verticalAlign: 'middle',
  },
  userGuideIcon: {
    verticalAlign: 'middle',
  },
  wordAlign: {
    marginLeft: '5px',
  },
};

function Sidebar(props) {
  return (
    <div className={props.classes.container}>
      <section className={props.classes.top}>
        <ul className={props.classes.list}>
          <Link
            to="/app#CheckedIn"
            className={props.classes.link}
            onClick={() => { logEvent('Select Today'); }}
          >
            <li className={props.classes.listItem}>
              <CalendarToday className={props.classes.icon} />
              Today
            </li>
          </Link>
          <Link
            to="/app/patientlisting"
            className={props.classes.link}
            onClick={() => { logEvent('Select Patient Listing'); }}
          >
            <li className={props.classes.listItem}>
              <img src="/img/patient_listing.svg" alt="Patients" height="34px" width="34px" className={props.classes.patientListingIcon} />
              Patient Listing
            </li>
          </Link>
          <hr />
          <section style={styles.section}>
            <a href="/files/hana_desktop_user_manual.pdf" target="_blank" className={props.classes.link}>
              <li className={props.classes.userGuideListItem}>
                <img src="/img/journal.svg" alt="user-guide icon" height="22px" width="22px" className={props.classes.userGuideIcon} />
                <span className={props.classes.wordAlign}>User Guide</span>
              </li>
            </a>
            <a href="/files/hana_faq.pdf" target="_blank" className={props.classes.link}>
              <li className={props.classes.supportListItem}>
                <img src="/img/support.svg" alt="FAQs Icon" height="22px" width="22px" className={props.classes.supportIcon} />
                <span className={props.classes.wordAlign}>FAQ</span>
              </li>
            </a>
            <a href="https://mindsetmedical.com/termsofuse/" className={props.classes.link} target="_blank" rel="noopener noreferrer">
              <li className={props.classes.listItem}>
                <Subject className={props.classes.icon} />
                Terms of Use
              </li>
            </a>
          </section>
          <hr />
        </ul>
      </section>
      <section>
        <img
          alt="Hana Logo"
          className={props.classes.logo}
          src="/img/hana-logo.svg"
        />
        <div className={props.classes.rightsSection}>2018 NuVasive, Inc &emsp; All Rights Reserved</div>
      </section>
    </div>
  );
}

Sidebar.propTypes = {
  router: PropTypes.object.isRequired,
};

export default withStyles(styles)(Sidebar);
