import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { ceil, noop } from 'lodash';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import MoreIcon from 'material-ui/svg-icons/notification/more';
import CheckCircleIcon from 'material-ui/svg-icons/action/check-circle';
import { withStyles } from '@material-ui/core/styles';

import { colors, tableStyles } from '../lib/styles';

const styles = {
  actionBtns: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px',
    paddingLeft: '0px',
  },
  btn: {
    boxShadow: 'none',
    fontSize: '12px',
    height: '26px',
    margin: '5px',
    verticalAlign: 'middle',
    top: 'auto',
  },
  btnLabel: {
    fontSize: '12px',
  },
  btnOverlay: {
    padding: '6px 0px 0px',
  },
  complete: {
    ...tableStyles.tableCell,
    fontSize: '1rem',
    marginLeft: '3rem',
  },
  hidden: {
    display: 'none',
  },
  icon: {
    color: colors.white,
    height: '12px',
    width: '12px',
  },
  formName: {
    ...tableStyles.tableCell,
    textTransform: 'capitalize',
    fontSize: '1rem',
  },
  row: {
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: colors.highlightGray,
    }
  },
  summary: {
    ...tableStyles.tableCell,
    fontSize: '1rem',
    minWidth: '200px',
    paddingLeft: '0px',
    paddingRight: '0px',
    whiteSpace: 'wrap',
  },
};

function makeSummary(summary, id) {
  if (!summary) {
    return 'None Listed';
  }
  let icon = null;
  let abbreviatedText = summary.substring(0, 70);

  if (summary.length > abbreviatedText.length) {
    abbreviatedText = `${abbreviatedText}...`;
    icon = (
      <MoreIcon
        data-tip
        data-for={id}
        style={styles.readMoreIcon}
      />
    );
  }
  return (<div>
    {abbreviatedText} {icon}
  </div>);
}

class FormListItem extends Component {
  constructor(props) {
    super(props);
    this.onOpenForm = this.onOpenForm.bind(this);
  }
  onOpenForm() {
    const { onOpenForm, form } = this.props;
    onOpenForm(form.name);
  }
  render() {
    const { classes, form } = this.props;
    const completeClass = this.props.hideCompletion ? classes.hidden : classes.complete;
    const fullPercent = ceil(form.percentage * 100);
    const complete = form.complete === 1 ? <CheckCircleIcon color={colors.successGreen} /> : `${fullPercent}%`;

    const tooltipId = `${form.name}-tooltip}`;
    const summary = makeSummary(form.summary, tooltipId);

    return (
      <TableRow hover onClick={this.onOpenForm} className={this.props.classes.row}>
        <TableCell className={classes.formName}>{form.display_name}</TableCell>
        <TableCell className={completeClass}>{complete}</TableCell>
        <TableCell className={classes.summary}>{summary}
          <ReactTooltip
            disable={summary === form.summary}
            id={tooltipId}
            place="left"
          >
            <div style={{ maxWidth: '600px' }}>
              {form.summary}
            </div>
          </ReactTooltip>
        </TableCell>
      </TableRow>
    );
  }
}

FormListItem.defaultProps = {
  form: {
    name: '',
  },
  openForm: noop,
};

FormListItem.propTypes = {
  form: PropTypes.object,
  handleOpenForm: PropTypes.func,
  hideCompletion: PropTypes.bool,
  hidePrint: PropTypes.bool,
  hideSummary: PropTypes.bool,
};

export default withStyles(styles)(FormListItem);
