import { makeDuck, resetReducer } from 'cooldux';
import { apiFetch } from '../lib/fetch';
import { forEach } from 'lodash';

const duck = makeDuck({
  browseDevices: (clinicId) => apiFetch(`/clinics/${clinicId}/devices`),
  editDevice: (clinicId, deviceId, body) => apiFetch(`/clinics/${clinicId}/devices/${deviceId}`, { method: 'PUT', body }),
  addDevice: (clinicId, body) => apiFetch(`/clinics/${clinicId}/devices`, { method: 'POST', body }),
  deleteDevice: (clinicId, deviceId) => apiFetch(`/clinics/${clinicId}/devices/${deviceId}`, { method: 'DELETE' }),
  addDeviceQR: (clinicId, deviceId) => {
    return apiFetch(`/clinics/${clinicId}/devices/${deviceId}/qrcode`, { method: 'POST' })
    .then((qr) => {
      qr.id = deviceId;
      return qr;
    });
  },
  killQR: (id) => id
});

const {
  killQREnd,
  addDeviceQREnd
} = duck;

export const { browseDevices, editDevice, addDevice, addDeviceQR, deleteDevice, initialStateCombined, reducerCombined, killQR } = duck;


const reducer = resetReducer(initialStateCombined, (state, action) => {
  state = reducerCombined(state, action);
  switch (action.type) {
    case addDeviceQREnd.type:
      forEach(state.browseDevices, (dev) => {
        if(dev.id === action.payload.id) {
          dev.qr = action.payload.qrcode;
        }
        else {
          dev.qr = null;
        }
      })
      return { ...state, browseDevices: state.browseDevices};
    case killQREnd.type:
      forEach(state.browseDevices, (dev) => {
        if(dev.id === action.payload) {
          dev.qr = null;
        }
      })
      return { ...state, browseDevices: state.browseDevices};
    default:
      return state;
  }
});

export default reducer;