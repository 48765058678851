import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { capitalize, compact, get, isNil, map, max, noop, omitBy, orderBy } from 'lodash';
import memoize from 'memoize-one';
import { Line } from 'react-chartjs-2';
import { Calendar } from 'common-ui';

import { apiFetch } from '../lib/fetch';
import findItemByDate from '../lib/find-item-by-date';
import PainBody from './pain-body';
import PatientViewContentSection from './patient-view-content-section';
import PatientViewHeader from './patient-view-header';
import PatientSummary from './patient-summary';
import PatientSummaryBtns from './patient-summary-btns';
import bodyPainLineBuilder from '../lib/body-pain-line-builder';

import { colors } from '../lib/styles';
import PatientHeaderProgressCircles from './patient-header-progress-circles';
import { logEvent } from '../lib/amplitude';

const legendSettings = {
  labels: { usePointStyle: true, }
};

const dataConfig = {
  fill: false,
  lineTension: 0.1,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderWidth: 1,
  pointHoverRadius: 7,
  pointHoverBorderWidth: 2,
  pointRadius: 5,
  pointHitRadius: 10,
};

const chartOptions = {
  scales: {
    xAxes: [{
      gridLines: {
        color: '#FFFFFF',
      }
    }],
    yAxes: [{
      gridLines: {
        color: '#FFFFFF',
      },
      ticks: {
        beginAtZero: true,
        min: 0,
        max: 10,
      },
    }]
  }
};

const baseStyles = {
  bottomIcon: {},
  bottomHalf: {
    display: 'flex',
    flexDirection: 'row',
    height: 500,
  },
  painLevel: {
    color: colors.primaryColor,
    fontSize: '14px',
    paddingTop: '10px',
    textTransform: 'capitalize',
    textAlign: 'center',
  },
  surgeryData: {
    display: 'flex',
    justifyContent: 'left',
    marginTop: 20,
    textAlign: 'left',
    textTransform: 'capitalize',
  },
  surgeryDates: {
    fontWeight: 600,
    marginRight: '10px',
    width: '93px',
  }
};

function determineMostRecent(mostRecent, data) {
  const allData = compact([mostRecent, ...data]);
  return max(allData, p => new Date(p.pain_end_date).getTime());
}
class BodyOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarData: [],
      fetching: false,
      mostRecentPain: null,
      selectedDate: null,
    };
  }
  componentWillMount() {
    const queryOptions = {
      query: omitBy({
        populate: true,
        limit: 1,
        orderBy: 'pain_end_date',
      }, isNil)
    };
    apiFetch(`/users/${this.props.userId}/pain_hx`, queryOptions)
      .then(([mostRecentPain]) => {
        this.setState({
          mostRecentPain,
          selectedDate: get(mostRecentPain, 'pain_end_date', '').split('T')[0],
        });
      })
      .catch(() => { });
  }
  handleDateChange = (currentMonth, currentYear) => {
    this.setState({ currentMonth, currentYear, fetching: true });

    const startDate = new Date(currentYear, currentMonth, 1);
    let endDate = new Date(currentYear, currentMonth + 1, 0);
    endDate = new Date(endDate.getTime() - 1);

    const queryOptions = {
      query: omitBy({
        pain_start_date: startDate.toISOString(),
        pain_end_date: endDate.toISOString(),
        populate: true,
      }, isNil)
    };
    apiFetch(`/users/${this.props.userId}/pain_hx`, queryOptions)
      .then((data) => {
        this.setState(prevState => ({
          calendarData: data,
          mostRecentPain: determineMostRecent(prevState.mostRecentPain, data),
          fetching: false,
        }));
      })
      .catch((e) => {
        this.setState({ error: 'An Error occured fetching pain data', fetching: false });
      });
  }
  handleSelectDate = (selectedDate) => {
    logEvent('Select a date on the Pain Calendar');
    this.setState({ selectedDate })
  }
  memoBuildLineChartData = memoize((data, currentMonth, currentYear) => {
    const formattedData = bodyPainLineBuilder(data, currentMonth, currentYear);
    const labels = Object.keys(formattedData);
    const values = Object.values(formattedData);
    return {
      labels,
      datasets: [
        {
          ...dataConfig,
          label: 'Pain Scale',
          backgroundColor: 'rgb(227,70,87)',
          borderColor: colors.calendarRed,
          pointBorderColor: colors.calendarRed,
          pointBackgroundColor: colors.calendarRed,
          pointHoverBackgroundColor: colors.calendarRed,
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointStyle: 'triangle',
          data: values
        },
      ]
    };
  })
  render() {
    const {
      appointment, painLocations, updateAppointment,
    } = this.props;
    const { mostRecentPain, selectedDate } = this.state;
    let displayedPain = mostRecentPain;
    if (selectedDate) {
      const pains = findItemByDate(selectedDate, this.state.calendarData, 'pain_start_date', 'pain_end_date');
      displayedPain = orderBy(pains, 'intensity', 'desc')[0];
    }
    const aggravatingFactors = get(displayedPain, 'aggravating_factors', []).map(capitalize).join(', ') || 'None Reported';
    const alleviatingFactors = get(displayedPain, 'alleviating_factors', []).map(capitalize).join(', ') || 'None Reported';
    const limitations = get(displayedPain, 'limitations', []).map(capitalize).join(', ') || 'None Reported';
    const lineChartData = this.memoBuildLineChartData(this.state.calendarData, this.state.currentMonth, this.state.currentYear);
    const selectedLocations = map(get(displayedPain, 'pain_locations', []), l => painLocations[l]);

    const location1 = get(selectedLocations[0], 'name', '');
    const location2 = get(selectedLocations[1], 'name', '');
    const painLocation = get(displayedPain, 'pain_locations') ?
      `${location1}${location2 ? '->' + location2 : ''}`
      : 'None Reported';

    const painLevel = get(displayedPain, 'pain_type') ?
      `${capitalize(displayedPain.pain_type)} ● Level ${displayedPain.intensity}`
      : '';

    return (
      <Fragment>
        <PatientViewHeader
          avatarImg={appointment.avatarImgLarge}
          centerContent={(
            <PatientSummary {...appointment} updateAppointment={updateAppointment} />
          )}
          centerButtons={(
            <PatientSummaryBtns {...this.props} />
          )}
          rightContent={(
            <PatientHeaderProgressCircles {...this.props} />
          )}
        />

        {this.props.children}
        <PatientViewContentSection>
          <div style={baseStyles.bottomHalf}>
            <div style={{ flex: 1, margin: 10, maxWidth: 300 }}>
              <Calendar
                clickableDates={true}
                data={this.state.calendarData}
                onDateChange={this.handleDateChange}
                onClickDay={this.handleSelectDate}
                selectedDate={this.state.selectedDate}
                selectedDateColor={colors.primaryColor}
                title="Pain - History Calendar"
              />
            </div>
            <div style={{ flex: 1, flexWrap: 'wrap', height: 400, maxWidth: 300 }}>
              <PainBody
                selectedLocations={selectedLocations}
                hideLabels={true}
                scale={0.8}
              />
              <div style={baseStyles.painLevel}>
                <div>{painLocation}</div>
                <div>{painLevel}</div>
              </div>
            </div>
            <div style={{ flex: 1, flexDirection: 'column', maxWidth: 500 }}>
              <div style={{ flex: 1, margin: '10px 40px 0' }}>
                <div style={baseStyles.surgeryData}>
                  <div style={baseStyles.surgeryDates}>Limitations:</div>
                  <div>{limitations}</div>
                </div>
                <div style={baseStyles.surgeryData}>
                  <div style={baseStyles.surgeryDates}>Aggravating Factors:</div>
                  <div>{aggravatingFactors}</div>
                </div>
                <div style={baseStyles.surgeryData}>
                  <div style={baseStyles.surgeryDates}>Alleviating Factors:</div>
                  <div>{alleviatingFactors}</div>
                </div>
              </div>
              <div style={{ margin: '50px 25px 0 0', minHeight: 500, minWidth: 450, maxWidth: 500, flex: 1 }}>
                <Line
                  data={lineChartData}
                  legend={legendSettings}
                  options={chartOptions}
                />
              </div>
            </div>
          </div>
        </PatientViewContentSection>
      </Fragment>
    );
  }
}

BodyOverview.defaultProps = {
  appointment: {},
  forms: {},
  handleOpenAllForms: noop,
  handleOpenForm: noop,
  mostRecentMigraine: {},
  migraines: [],
  painLevel: 'None Reported',
  painLocations: {},
  updateAppointment: noop,
};

BodyOverview.propTypes = {
  appointment: PropTypes.object,
  forms: PropTypes.object,
  router: PropTypes.object,
  handleOpenAllForms: PropTypes.func,
  handleOpenForm: PropTypes.func,
  painLocations: PropTypes.object,
  updateAppointment: PropTypes.func,
};


export default BodyOverview;
