import { get } from 'lodash';
import { browserHistory } from 'react-router';
import makeActionCreator from '../lib/make-action-creator';

import { subscribeToClinic } from '../websocket';
import { setUser, logEvent } from '../lib/amplitude';
import { apiFetch } from '../lib/fetch';

export const LOGIN_START = 'user-LOGIN_START';
export const LOGIN_END = 'user-LOGIN_END';
export const LOGIN_ERROR = 'user-LOGIN_ERROR';
export const LOGOUT = 'user-LOGOUT';
export const UPDATE_USER_MESSAGE = 'user-UPDATE_USER_MESSAGE';

const loginStart = makeActionCreator(LOGIN_START);
const loginEnd = makeActionCreator(LOGIN_END);
const loginError = makeActionCreator(LOGIN_ERROR);
const logoutAC = makeActionCreator(LOGOUT);
const updateMessage = makeActionCreator(UPDATE_USER_MESSAGE);

function verifyRole(data) {
  if (!data.roles.includes('PROVIDER')) {
    throw new Error('Not a Provider User');
  }
  return data;
}

export function verifyUser() {
  return function dispatcher(dispatch, getState) {
    dispatch(loginStart());

    return apiFetch(`/users/me`)
      .then(verifyRole)
      .then((json) => {
        dispatch(loginEnd(json));

        const clinicId = get(getState(), 'clinics.clinicId');
        setUser(json.id, clinicId);
        subscribeToClinic(clinicId);
        return json;
      })
      .catch((err) => {
        dispatch(loginError());
        throw err;
      });
  };
}

export function login(username, password) {
  return function dispatcher(dispatch) {
    dispatch(loginStart());

    const requestOptions = {
      body: {
        username,
        password,
      },
      method: 'POST',
    };

    return apiFetch(`/auth`, requestOptions)
      .then(verifyRole)
      .then((json) => {
        dispatch(loginEnd(json));

        return json;
      })
      .catch((err) => {
        dispatch(loginError());
        throw err;
      });
  };
}

export function logout(message = 'You have been successfully logged out') {
  return function dispatcher(dispatch) {
    logEvent('Select Logout');
    dispatch(logoutAC(message));
    window.localStorage.removeItem('clinicId');
    setUser(null);
    
    return apiFetch('/logout')
    .then(() => browserHistory.push('/'));
  };
}

export function updateUserMessage(message) {
  return function dispatcher(dispatch) {
    dispatch(updateMessage(message));
  }
}
