import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';

import { colors } from '../lib/styles';

const baseStyles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1rem',
    flexWrap: 'wrap',
    textAlign: 'left',
  },
  itemTitle: {
    whiteSpace: 'nowrap',
    marginRight: '5px',
  },
  itemValue: {
    color: colors.black,
    fontWeight: 100,
    textTransform: 'capitalize',
    marginRight: '5px',
  },
};

function PatientSummaryItem(props) {
  return (
    <div style={baseStyles.container}>
      <div style={baseStyles.itemTitle}>{props.title}:</div>
      <div style={baseStyles.itemValue}>{capitalize(props.value)}</div>
    </div>
  );
}

PatientSummaryItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.node,
};

export default PatientSummaryItem;
