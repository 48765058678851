import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    overrides:{
      MuiButton: {
        root: {
          margin:'10px',
          borderRadius: '6px',
          display: 'flex',
          alignItems: 'center',
        }
      },
    },
    typography: {
        useNextVariants: true,
        fontFamily: [
          'calibri',
          'Univers LT Std',
          'sans-serif',
        ].join(','),
      },
});

export default theme;
