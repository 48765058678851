import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from 'material-ui/TextField';
import Button from '@material-ui/core/Button';
import { map, sortBy, forEach } from 'lodash';

import AppWrapper from '../wrappers/app-wrapper';
import { browseDevices, addDevice, deleteDevice, addDeviceQR, editDevice, killQR } from '../state/devices';
import Device from'../components/device';
import { colors } from '../lib/styles';

const baseStyles = {
  lists: {
    padding: '25px',
    width: '100%',
    marginBottom: '150px',
  },
  createDevice: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: '3rem',
    justifyContent: 'center',
  },
  deviceBtn: {
    backgroundColor: colors.primaryColor,
    color: colors.white,
    fontSize: '14px',
    marginTop: '2.1875rem',
    marginLeft: 0,
  },
  deviceBtns: {
    display: 'flex',
    flex: 1,
  },
  deviceId: {
    flex: 1,
    paddingTop: '40px',
  },
  deviceItem: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px',
  },
  deviceList: {
    textAlign: 'left',
    marginTop: '2rem',
  },
  devicesList: {
    textAlign: 'left',
  },
  headerTitle: {
    textAlign: 'left',
  },
};

export class DeviceListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newDevicePass: ''
    };
    this.onCreateNewDevice = this.onCreateNewDevice.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onCreateQR = this.onCreateQR.bind(this);
  }
  componentWillMount() {
    this.props.browseDevices(this.props.clinic.clinicId);
  }
  onPasswordChange(evt, newDevicePass) {
    this.setState({
      newDevicePass,
      passwordError: '',
    });
  }
  onEdit(id, dev) {
    return this.props.editDevice(this.props.clinic.clinicId, id, dev)
    .then(() => {
      this.props.browseDevices(this.props.clinic.clinicId);
    });
  }
  onDelete(id) {
    this.props.deleteDevice(this.props.clinic.clinicId, id)
    .then(() => {
      this.props.browseDevices(this.props.clinic.clinicId);
    });
  }
  onCreateNewDevice() {
    this.props.addDevice(this.props.clinic.clinicId, {password: this.state.newDevicePass})
      .then(() => {
        this.setState({newDevicePass: ''});
        this.props.browseDevices(this.props.clinic.clinicId);
      });
  }
  onCreateQR(id) {
    let hasQR = false;
    forEach(this.props.devices.browseDevices, (dev) => {
      if (id === dev.id && dev.qr) {
        hasQR = true;
      }
    });
    if (hasQR) {
      return this.props.killQR(id);
    }
    return this.props.addDeviceQR(this.props.clinic.clinicId, id);
  }
  render() {
    const {
      browseDevices,
    } = this.props.devices;
    const devs = sortBy(browseDevices, 'id');
    return (
      <AppWrapper router={this.props.router}>
        <div style={baseStyles.lists}>
        <h1 style={baseStyles.headerTitle}>Devices</h1>
          <div style={baseStyles.createDevice}>
            <p>Create a new device</p>
            <TextField
              floatingLabelText="New Password"
              hintText="New Password"
              onChange={this.onPasswordChange}
              style={baseStyles.field}
              type="password"
              underlineShow={true}
              value={this.state.newDevicePass}
            />
            <Button
              children="Create device"
              style={baseStyles.deviceBtn}
              onClick={this.onCreateNewDevice}
              disabled={!this.state.newDevicePass || this.state.newDevicePass.length < 6}
            />
          </div>

          <h2 style={baseStyles.deviceList}>Device List</h2>
          <div style={baseStyles.devicesList}>
            {map(devs, (d) => {
              return (
                <Device
                  device={d}
                  key={d.id}
                  onEdit={this.onEdit}
                  onDelete={this.onDelete}
                  onCreateQR={this.onCreateQR}
                  clinicId={this.props.clinic.clinicId}
                />
              )
            })}
          </div>
        </div>
      </AppWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {
    clinic,
    devices
  } = state;

  return {
    clinic,
    devices
  };
}



export default connect(mapStateToProps, { browseDevices, addDevice, deleteDevice, addDeviceQR, editDevice, killQR })(DeviceListPage);
