import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Warning from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../lib/styles';

const styles = {
  emrLabel: {
    marginLeft: 0,
    paddingLeft: 0,
  },
  emrLabelIcon: {
    paddingTop: 0,
    paddingLeft: '20px',
  },
  emrLabelWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  indicator: {
    backgroundColor: colors.primaryColor,
  },
  tab: {
    color: colors.highlightDarker,
    fontSize: '0.95rem',
    textTransform: 'capitalize',
  },
  tabTemplate: {
    backgroundColor: colors.primaryHighlight,
  },
  warning: {
    color: colors.errorRed,
  }
};

class PatientViewTabs extends Component {
  render() {
    const { classes, showEmrData, showEmrAlert } = this.props;

    return (
      <Tabs
        value={this.props.activeTab}
        onChange={this.props.onChangeTab}
        variant="fullWidth"
        classes={{
          indicator: classes.indicator,
        }}
      >
        <Tab label="Patient Overview" value="appointment-overview" style={styles.tab} />
        {showEmrData && (
          <Tab
            icon={showEmrAlert ? <Warning className={classes.warning} /> : null}
            classes={{ labelIcon: classes.emrLabelIcon, labelContainer: classes.emrLabel, wrapper: classes.emrLabelWrapper }}
            label="EMR Data"
            value="emr-data"
            style={styles.tab}
          />
          )}
        <Tab label="Releases" value="releases" style={styles.tab} />
        <Tab label="PRO" value="pro-submissions" style={styles.tab} />
        <Tab label="Pain Overview" value="pain-overview" style={styles.tab} />
      </Tabs>
    )
  }
}

PatientViewTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  showEmrData: PropTypes.bool.isRequired,
};

export default withStyles(styles)(PatientViewTabs);
