import React, { Component } from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import ModeEditIcon from 'material-ui/svg-icons/editor/mode-edit';
import TextField from 'material-ui/TextField';
import Button from '@material-ui/core/Button';
import QRCode from 'qrcode.react';

import { colors } from '../lib/styles';

const baseStyles = {
  container: {},
  deviceBtn: {
    backgroundColor: colors.primaryColor,
    color: colors.white,
    fontSize: '14px',
    margin: '35px 5px 0px', // large margin-top to account for textField weirdness
    borderRadius: '0.375rem',
    boxShadow: 'none',
  },
  deviceBtns: {
    display: 'flex',
    flex: 1,
  },
  deviceId: {
    flex: 1,
    paddingTop: '40px',
  },
  deviceItem: {
    display: 'flex',
    margin: '10px',
    alignItems: 'center',
  },
  raisedButton: {
    boxShadow: 'none',
    borderRadius: '0.375rem',
  },
};

class Device extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      password: '',
      passwordError: '',
    };
    this.updatePassword = this.updatePassword.bind(this);
    this.deleteDevice = this.deleteDevice.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.updatePasswordStart = this.updatePasswordStart.bind(this);
    this.createQR = this.createQR.bind(this);
  }
  deleteDevice() {
    this.props.onDelete(this.props.device.id);
  }
  updatePassword() {
    const { password } = this.state;
    if (!password || password.length < 6) {
      return this.setState({
        passwordError: 'Password must be at least 6 characters',
      });
    }
    const update = { password };

    this.props.onEdit(this.props.device.id, update)
      .then(() => {
        this.setState({
          editing: false,
          password: '',
          passwordError: '',
        });
      });
  }
  createQR() {
    this.props.onCreateQR(this.props.device.id);
  }
  onPasswordChange(evt, password) {
    this.setState({
      password,
      passwordError: '',
    });
  }
  updatePasswordStart() {
    this.setState({
      editing: true,
      passwordError: '',
    });
  }
  render() {
    const editIcon = <ModeEditIcon />;

    let editArea = (
      <div style={baseStyles.deviceBtns}>
        <Button
          children="QR Login"
          style={baseStyles.deviceBtn}
          onClick={this.createQR}
        />
        <RaisedButton
          backgroundColor={colors.primaryColor}
          icon={editIcon}
          label="Change Password"
          labelColor={colors.white}
          onClick={this.updatePasswordStart}
          primary={false}
          style={baseStyles.deviceBtn}
          buttonStyle={baseStyles.raisedButton}
        />
        <Button
          children="Delete"
          style={baseStyles.deviceBtn}
          onClick={this.deleteDevice}
        />
      </div>
    );

    if (this.state.editing) {
      editArea = (<div style={baseStyles.deviceBtns}>
        <TextField
          errorText={this.state.passwordError}
          floatingLabelText="New Device Password"
          hintText="New Device Password"
          onChange={this.onPasswordChange}
          type="password"
          underlineShow={true}
          value={this.state.password}
        />
        <Button
          children="Save"
          style={baseStyles.deviceBtn}
          onClick={this.updatePassword}
          disabled={!this.state.password || this.state.password.length < 6}
        />
      </div>);
    }
    return (
      <div style={baseStyles.deviceItem}>
        <div style={baseStyles.deviceId}>
          {this.props.device.id}
          {this.props.device.qr ? (
            <QRCode
              value={this.props.device.qr}
              style={{margin: '10px'}}
              size={400}
            />
          ) : ''}
        </div>
        {editArea}
      </div>
    );
  }
}

export default Device;
