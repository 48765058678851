import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'material-ui';
import Button from '@material-ui/core/Button';
import { colors } from '../lib/styles';

const buttonStyle = {
  fontSize: '16px',
  height: '40px',
  color: '#fff',
  backgroundColor: colors.secondaryColor,
};

class RevokeEmrDialog extends PureComponent {
  render() {
    const {
      open,
    } = this.props;
    return (
      <Dialog
        modal={true}
        open={open}
        title="Are you sure you wish to revoke this patient's match with the EMR?"
        style={{ textAlign: 'center' }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          children="Cancel"
          onClick={this.props.onToggleOpen}
          style={buttonStyle}
        />
        <Button
          children="Revoke Match"
          onClick={this.props.onRevoke}
          style={buttonStyle}
        />
        </div>
      </Dialog>
    );
  }
}

RevokeEmrDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggleOpen: PropTypes.func.isRequired,
  onRevoke: PropTypes.func.isRequired,
};

export default RevokeEmrDialog;

