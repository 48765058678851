import React from 'react';
import { map, sum, reduce } from 'lodash';

import ProgressCircle from './progress-circle';

const baseStyles = {
  progressCircles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
};

const PatientHeaderProgressCircles = (props) => {
  const proSigned = reduce(props.proSubmissions, (signed, current) => {
    if (current.completion_date) {
      return signed + 1;
    }
    return signed;
  }, 0);

  const releasesSigned = reduce(props.signatureRequests, (signed, current) => {
    if (current.status === 'Signed') {
      return signed + 1;
    }
    return releasesSigned;
  }, 0);

  const proTotalCompletion = proSigned / props.proSubmissions.length || 0;
  const releasesTotalCompletion = releasesSigned / props.documentTemplates.length || 0;
  const formsPercentages = map(props.forms, f => f.percentage);
  const formsTotalCompletion = sum(formsPercentages) / formsPercentages.length || 0;

  return(
    <section style={baseStyles.progressCircles}>
      <ProgressCircle
        label="Forms"
        complete={formsTotalCompletion}
        isLoading={formsPercentages.length < 1}
      />
      <ProgressCircle
        label="Releases"
        complete={releasesTotalCompletion}
        isLoading={false}
      />
      <ProgressCircle
        label="PROs"
        complete={proTotalCompletion}
        isLoading={false}
      />
    </section>
  )
};

export default PatientHeaderProgressCircles;
