import { promiseHandler } from 'cooldux';
import { keyBy } from 'lodash';
import faker from 'faker';
import moment from 'moment';
import { apiFetch } from '../lib/fetch';

const {
  browseIcaSubmissionsStart,
  browseIcaSubmissionsEnd,
  browseIcaSubmissionsError,
  browseIcaSubmissionsHandler,
} = promiseHandler('browseIcaSubmissions', 'application');

const dummySubmissions = [];

for (let i = 1; i < 50; i++) {
  const createdDate = faker.date.recent();
  dummySubmissions.push({
    id: i,
    patient_name: `${faker.name.firstName()} ${faker.name.lastName()}`,
    date: moment(createdDate).format('MM/DD/YYYY'),
    time: moment(createdDate).format('h:mma'),
    form_type: 'Initial Clinical Assessment',
  });
}

export function browseIcaSubmissions() {
  return (dispatch) => {
    const promise = apiFetch('/pain_locations')
      .then(() => dummySubmissions);
    return browseIcaSubmissionsHandler(promise, dispatch);
  };
}

const initialState = {
  browseIcaSubmissionsError: null,
  browseIcaSubmissionsPending: false,
  icaSubmissions: {},
};


function finishBrowse(state, response, key, method) {
  return {
    ...state,
    [key]: keyBy(response, 'id'),
    [`${method}Error`]: null,
    [`${method}Pending`]: false,
  };
}


function clinicalFormSubmissions(state = initialState, { type, payload }) {
  switch (type) {
    case browseIcaSubmissionsStart.type:
      return { ...state, browsePainLocationsError: null, browsePainLocationsPending: true };
    case browseIcaSubmissionsEnd.type:
      return finishBrowse(state, payload, 'icaSubmissions', 'browseIcaSubmissions');
    case browseIcaSubmissionsError.type:
      return { ...state, browseIcaSubmissionsError: payload };

    default:
      return state;
  }
}

export default clinicalFormSubmissions;
