import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { get, map, random } from 'lodash';
import {
  FormControl, InputLabel, MenuItem, Table, TableRow, TableCell, TableHead, TableBody, Select
} from '@material-ui/core';
import RaisedButton from 'material-ui/RaisedButton';
import AVFiberManualRecord from 'material-ui/svg-icons/av/fiber-manual-record';
import AVStop from 'material-ui/svg-icons/av/stop';
import AlertWarning from 'material-ui/svg-icons/alert/warning';
import ModeEditIcon from 'material-ui/svg-icons/editor/mode-edit';

import AppWrapper from '../wrappers/app-wrapper';
import ProSquare from '../components/pro-square';
import { fetchAppointments } from '../state/appointments';

import { colors,custom } from '../lib/styles';

const baseStyles = {
  body: {
    marginTop: '5px',
  },
  bottomBtnContainer: {
    padding: '3px 10px 10px',
  },
  bottomBtnFirstRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  complete: {
    color: colors.calendarGreen,
  },
  contentStyle: {
    backgroundColor: colors.white,
    margin: '15px',
    width: '100%',
    minWidth: '768px',
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
  },
  due: {
    color: colors.calendarYellow,
  },
  h3: {
    textAlign: 'left',
    margin: '1rem'
  },
  pSmallText: {
    color: colors.white,
    margin: 0,
    fontSize: '0.8rem',
  },
  pBigText: {
    fontSize: '1.5rem',
    color: colors.white,
    padding: '0.3rem',
    margin: '0.5rem',
  },
  square: {
    backgroundColor: colors.calendarRed,
    height: '6rem',
    width: '6rem',
  },
  symbol: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '1rem',
    padding: '2rem',
  },
  symbolColors: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '7rem',
  },
  pastDue: {
    color: colors.calendarRed,
  },
  reviewAllBtn: {
    boxShadow: 'none',
    margin: '5px',
    width: '200px',
  },
  reviewAllLabel: {
    padding: '10px',
    width: '200px',
  },
  row: {
    backgroundColor: '#fff',
  },
  select: {
    marginLeft: '2rem',
    width: '13rem',
  },
  selectAndSymbolsAlignment: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableAlign: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableTitle: {
    fontSize: '0.85rem',
  },
  top: {
    marginRight: '2rem',
    marginLeft: '1.5rem',
  },
};

class ProCompliancePostOp extends Component {
  state = {
    pagination: '',
    open: false,
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  componentWillMount() {
    this.props.fetchAppointments(this.props.clinic.clinicId);
  }

  render() {
    const { appointments } = this.props;
    const reviewAllIcon = <ModeEditIcon color={colors.white} style={baseStyles.bottomIcon} />;

    return (
      <AppWrapper router={this.props.router}>
        <div style={baseStyles.contentStyle}>
        <h3 style={baseStyles.h3}>PRO Compliance: Post-Op</h3>
        <ProSquare
          text="Post-Op"
          squareStyle={baseStyles.square}
          smallTextStyle={baseStyles.pSmallText}
          bigTextStyle={baseStyles.pBigText}  
        />
          <section style={baseStyles.tableAlign}>
              <div style={baseStyles.selectAndSymbolsAlignment}>
                <FormControl style={baseStyles.select}>
                  <InputLabel>10 Patients Per Page</InputLabel>
                  <Select
                    open={this.state.open}
                    onClose={this.handleClose}
                    onOpen={this.handleOpen}
                    value={this.state.pagination}
                    onChange={this.handleChange}
                    variant="outlined"
                    inputProps={{ name: 'pagination' }}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value="one">1</MenuItem>
                    <MenuItem value="two">2</MenuItem>
                    <MenuItem value="three">3</MenuItem>
                  </Select>
                </FormControl>
                <section style={baseStyles.symbol}>
                  <div style={baseStyles.symbolColors}>
                    <AlertWarning color={`${colors.calendarGreen}`} />
                    <span style={baseStyles.complete}>Complete</span>
                  </div>
                  <div style={baseStyles.symbolColors}>
                    <AVFiberManualRecord color={`${colors.calendarYellow}`} />
                    <span style={baseStyles.due}>Due</span>
                  </div>
                  <div style={baseStyles.symbolColors}>
                    <AVStop color={`${colors.calendarRed}`} />
                    <span style={baseStyles.pastDue}>Past Due</span>
                  </div>
                </section>
              </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={baseStyles.tableTitle}>Patient Name ▼</TableCell>
                  <TableCell style={baseStyles.tableTitle}>MRN</TableCell>
                  <TableCell style={baseStyles.tableTitle}>Date of Birth</TableCell>
                  <TableCell style={baseStyles.tableTitle}>Sex</TableCell>
                  <TableCell style={baseStyles.tableTitle}>PRO Status</TableCell>
                  <TableCell style={baseStyles.tableTitle}>Select</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={baseStyles.body}>
                {map(appointments, p => {
                  const rando = random(0, 100);
                  // I don't know what this is but it seems right
                  const proStatus = rando < 33 ? (<AVStop style={`${colors.calendarRed}`} />)
                    : rando < 88 ? (<AlertWarning color={`${colors.calendarYellow}`} />)
                      : (<AVFiberManualRecord color={`${colors.calendarGreen}`} />);
                  return (
                    <TableRow style={baseStyles.row} key={p.id}>
                      <TableCell component="th" scope="row">{`${p.first_name} ${p.last_name}`}</TableCell>
                      <TableCell>{`${p.dob}`}</TableCell>
                      <TableCell>mrn</TableCell>
                      <TableCell>{`${p.dob}`}</TableCell>
                      <TableCell>{`${p.gender}`}</TableCell>
                      <TableCell>{proStatus}</TableCell>
                      <TableCell><Link to={`/patient-details/${p.user_id}`}><img src="/img/startTestBtn@2x.png" alt="E-mail Button" /></Link></TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <div style={baseStyles.bottomBtnContainer}>
              <div style={baseStyles.bottomBtnFirstRow}>
                <RaisedButton
                  backgroundColor={colors.blueSelect}
                  icon={reviewAllIcon}
                  label="Send Reminder"
                  labelColor={colors.white}
                  labelStyle={baseStyles.reviewAllLabel}
                  primary={false}
                  style={baseStyles.reviewAllBtn}
                  buttonStyle={custom.raisedButton}
                />
              </div>
            </div>
          </section>
        </div>
      </AppWrapper>
    )
  }
}

function mapStateToProps(state) {
  const { appointments, user, clinic } = state;
  return {
    appointments: get(appointments, 'fetchAppointments', []),
    user,
    clinic,
  };
}


export default connect(mapStateToProps, { fetchAppointments })(ProCompliancePostOp);
