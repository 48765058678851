import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { colors } from '../lib/styles';

const styles = {
  tableCell: {
    padding: '4px 10px 4px 10px',
    minWidth: '101px',
    maxWidth: '250px',
    fontSize: '1rem',
  },
  row: {
    '&:nth-of-type(even)': {
      backgroundColor: colors.highlightGray,
    },
  },
};

const ProRow = (props) => {
  return (
    <TableRow
      className={props.classes.row}
      hover={false}
      key={props.id}
      tabIndex={0}
    >
      <TableCell className={props.classes.tableCell}>{props.type}</TableCell>
      <TableCell className={props.classes.tableCell}>
        {props.score || 'Awaiting Submission'}
      </TableCell>
      <TableCell className={props.classes.tableCell}>
        {props.date ? moment(props.date).format('MM/DD/YYYY') : 'Awaiting Submission'}
      </TableCell>
    </TableRow>
  );
};

ProRow.propTypes = {
  rowStyle: PropTypes.object,
};

ProRow.defaultProps = {
  rowStyle: {},
};
export default withStyles(styles)(ProRow);
