import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import CheckInBtn from './check-in-button';

class PatientSummaryBtns extends Component {
  render() {
    const { appointment, updateAppointment, onCreateAppointment } = this.props;
    return (
      <Fragment>
        <CheckInBtn
          patient={appointment}
          checkedIn={!!appointment.status}
          onUpdateAppointment={updateAppointment}
          onCreateAppointment={onCreateAppointment}
        />
      </Fragment>
    )
  }
}

PatientSummaryBtns.defaultProps = {
  appointment: {},
  updateAppointment: noop,
  onCreateAppointment: noop,
};

PatientSummaryBtns.propTypes = {
  appointment: PropTypes.object,
  updateAppointment: PropTypes.func,
  onCreateAppointment: PropTypes.func,
};

export default PatientSummaryBtns;
