import React, { Component } from 'react';
import { get } from 'lodash';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { colors } from '../lib/styles';

const btnLabel = {
  checkedIn: 'Check In',
  checkedOut: 'Check Out',
}

const btnStyles = {
  float: 'right',
  width: '140px',
  marginRight: '3rem',
};

const styles = {
  checkIOBtn: {
    ...btnStyles,
    backgroundColor: colors.hanaTableCheckInBtn,
    color: colors.white,
  },
  checkIOBtnWhite: {
    ...btnStyles,
    backgroundColor: colors.white,
    color: colors.hanaTableCheckInBtn,
    border: `1px solid ${colors.hanaTableCheckInBtn}`,
    borderRadius: '5px',
  },
};


class CheckInBtn extends Component {
  handleClickCheckBtn = () => {
    const { patient, checkedIn } = this.props;
    if(patient.status === 'CHECKED_OUT') {
      return this.props.onUpdateAppointment(patient.id, { status: 'CHECKED_IN' });
    }
    if (checkedIn && patient) {
      return this.props.onUpdateAppointment(patient.id, { status: 'CHECKED_OUT' });
    }
    const userId = get(patient, 'user_id', '');
    this.props.onCreateAppointment(userId);
  }
  render() {
    const { patient, checkedIn, checkedOutClass, checkedInClass, classes } = this.props;

    return (
      <div>
        <Button
          children={checkedIn && patient.status !== "CHECKED_OUT" ? btnLabel.checkedOut : btnLabel.checkedIn}
          className={checkedIn && patient.status !== "CHECKED_OUT" ? (checkedOutClass || classes.checkIOBtnWhite) : (checkedInClass || classes.checkIOBtn)}
          onClick={this.handleClickCheckBtn}
        />
      </div>
    );
  }
}

CheckInBtn.propTypes = {
  checkedIn: PropTypes.bool.isRequired,
  checkedInClass: PropTypes.string,
  checkedOutClass: PropTypes.string,
  patient: PropTypes.shape({
    id: PropTypes.number,
    checked_in: PropTypes.string,
    checked_out: PropTypes.string,
    clinicians: PropTypes.array,
    complete: PropTypes.number,
    email: PropTypes.string,
    in_session: PropTypes.string,
    status: PropTypes.string,
    user_id: PropTypes.string,
  }),
  onCreateAppointment: PropTypes.func.isRequired,
  onUpdateAppointment: PropTypes.func.isRequired,
};

export default withStyles(styles)(CheckInBtn);
