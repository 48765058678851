import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table';
import SortIcon from 'material-ui/svg-icons/action/swap-vert';
import CircularProgress from 'material-ui/CircularProgress';

import { colors } from '../lib/styles';

const baseStyles = {
  clickableTableHeader: {
    cursor: 'pointer',
    fontSize: '16px',
  },
  container: {
    minWidth: '768px',
    paddingLeft: '20px',
  },
  displayNumberMenu: {
    bottom: '-3px',
    height: 'auto',
  },
  displayNumberMenuLabel: {
    color: colors.charcoal,
    lineHeight: 'normal',
    paddingLeft: '4px',
    paddingRight: '18px',
  },
  displayNumberMenuIcon: {
    fill: colors.charcoal,
    right: '-16px',
    top: '-12px',
  },
  displayNumberMenuUnderline: {
    display: 'none',
  },
  headerText: {
    fontWeight: 100,
    textAlign: 'left',
  },
  headerTitle: {
    fontWeight: 100,
  },
  sortIcon: {
    color: colors.highlightMedium,
    height: '20px',
    verticalAlign: 'middle',
  },
  subHeaderText: {
    color: colors.highlightDark,
    margin: '5px',
  },
  table: {
    border: `1px solid ${colors.highlightMedium}`,
  },
  tableContainer: {
    margin: '0px 10px 20px',
  },
  tableHeader: {
    fontSize: '16px',
    minWidth: '100px',
  },
};

const sortIcon = <SortIcon style={baseStyles.sortIcon} />;

const FormSubmissionItem = props => (
  <TableRow>
    <TableRowColumn>{props.name}</TableRowColumn>
    <TableRowColumn>{props.type}</TableRowColumn>
    <TableRowColumn>{props.date}</TableRowColumn>
    <TableRowColumn>{props.time}</TableRowColumn>
  </TableRow>
);

class FormSubmissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: 'id',
      sortDir: 'desc',
    };

    this.updateSort = this.updateSort.bind(this);
    this.sortByName = this.updateSort.bind(this, 'patient_name');
  }
  componentWillMount() {
    this.props.fetchData();
  }
  updateSort(col) {
    const { sortBy, sortDir } = this.state;
    if (sortBy === col) {
      return this.setState({
        sortDir: sortDir === 'asc' ? 'desc' : 'asc',
      });
    }

    return this.setState({
      sortDir: 'desc',
      sortBy: col,
    });
  }
  render() {
    if (this.props.isFetching) {
      return (
        <div style={baseStyles.container}>
          <h1 style={baseStyles.headerTitle}>Initial Clinical Assessment</h1>
          <p>Loading Submissions..</p>
          <CircularProgress size={100} thickness={7} />
        </div>
      );
    }

    const sortedSubmissions = orderBy(this.props.data, [this.state.sortBy], [this.state.sortDir]);
    const submissions = sortedSubmissions.map((submission) => {
      return (
        <FormSubmissionItem
          key={submission.id}
          name={submission.patient_name}
          date={submission.date}
          time={submission.time}
          type={submission.form_type}
        />
      );
    });
    return (
      <div style={baseStyles.container}>
        <div style={baseStyles.headerText}>
          <h1 style={baseStyles.headerTitle}>Recent Initial Clinical Assessments</h1>
        </div>
        <div style={baseStyles.tableContainer}>
          <Table
            selectable={false}
            multiSelectable={false}
            style={baseStyles.table}
          >
            <TableHeader
              adjustForCheckbox={false}
              displaySelectAll={false}
              enableSelectAll={false}
            >
              <TableRow>
                <TableHeaderColumn
                  onClick={this.sortByName}
                  style={baseStyles.clickableTableHeader}
                >Patient Name{sortIcon}
                </TableHeaderColumn>
                <TableHeaderColumn style={baseStyles.tableHeader}>Form Type</TableHeaderColumn>
                <TableHeaderColumn style={baseStyles.tableHeader}>Date Submitted</TableHeaderColumn>
                <TableHeaderColumn style={baseStyles.tableHeader}>Time Submitted</TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody
              displayRowCheckbox={false}
              deselectOnClickaway={true}
              showRowHover={true}
              stripedRows={false}
            >
              {submissions}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

FormSubmissions.defaultProps = {
  data: {},
  isFetching: false,
};

FormSubmissions.propTypes = {
  data: PropTypes.object,
  fetchData: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
};

export default FormSubmissions;
