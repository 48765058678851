import React, { Component } from 'react';
import AppWrapper from '../wrappers/app-wrapper';

import { addRecent } from '../state/recent';
import { createAppointment, updateAppointment, updatePatientLocally } from '../state/appointments';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { noop } from 'lodash';

import PatientList from '../components/patient-list';

const baseStyles = {
  patientLists: {
    padding: '25px',
    width: '100%',
    marginBottom: '150px',
  },
};

export class PatientListPage extends Component {
  render() {
    const {
      addRecent, appointments, providers, updateAppointment,
    } = this.props;
    return (
      <AppWrapper router={this.props.router}>
        <div style={baseStyles.patientLists}>
          <PatientList
            addRecent={addRecent}
            appointments={appointments.data}
            clinicId={this.props.clinicId}
            createAppointment={this.props.createAppointment}
            id="PatientList"
            title="Patient List"
            isFetching={appointments.isFetching}
            providers={providers}
            onUpdateAppointment={updateAppointment}
            onUpdatePatient={this.props.updatePatientLocally}
            router={this.props.router}
          />
        </div>
      </AppWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {
    clinic: { clinicId, providers },
    esign, appointments,
  } = state;


  return {
    clinicId,
    providers,
    documentTemplates: esign.documents,
    appointments,
  };
}

const actionCreators = {
  addRecent,
  createAppointment,
  updateAppointment,
  updatePatientLocally,
};

PatientListPage.defaultProps = {
  appointments: {
    data: [],
    isFetching: false,
  },
  documentTemplates: [],
  providers: [],
  updateAppointment: noop,
};

PatientListPage.propTypes = {
  addRecent: PropTypes.func.isRequired,
  appointments: PropTypes.shape({
    data: PropTypes.array,
    isFetching: PropTypes.bool,
    cobb: PropTypes.array,
  }),
  documentTemplates: PropTypes.array,
  providers: PropTypes.array,
  updateAppointment: PropTypes.func,
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, actionCreators)(PatientListPage);
