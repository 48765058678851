import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Avatar from './patient-avatar';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import openForm from '../lib/open-form';
import { colors } from '../lib/styles';
import { logEvent } from '../lib/amplitude';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import CheckInBtn from './check-in-button';

const baseStyles = {
  avatar: {
    height:'60px',
    marginRight: '8px',
    verticalAlign: 'middle',
    width:'60px',
  },
  clinicianDropdown: {
    border: '1px solid black',
    paddingLeft: '5px',
  },
  menuIcon: {
    verticalAlign: 'middle',
  },
  patientName: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    width: '20%',
    whiteSpace: 'nowrap',
    fontSize: '1rem',
  },
  statusMenuLabel: {
    color: colors.black,
    paddingLeft: '0px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  statusMenuUnderline: {
    display: 'none',
  },
  statusTd: {
    padding: '4px 0px',
  },
  providerTd: {
    padding: '4px 0px',
  },
  td: {
    fontSize: '1rem',
  },
  checkoutColumn: {
    paddingRight: '0 !important',
    width: '20%',
  },
};


class PatientList extends PureComponent {
  onChangeProvider = (evt) => {
    evt.nativeEvent.stopImmediatePropagation();
    // if we have an appointment update it
    if(this.props.patient.id) {
      this.props.onUpdateAppointment(this.props.patient.id, { provider_id: evt.target.value });
    }
    // if not we just update locally, allowing the creation of an appointment at that provider later
    this.props.onUpdatePatient(this.props.patient.user_id, { provider_id: evt.target.value });
  }
  onOpenAppointmentView = () => {
    this.props.addRecent(this.props.patient);
    this.props.router.push({ pathname: '/app/patient', state: { userId: this.props.patient.user_id } });
  }
  onOpenAllForms = () => {
    openForm(this.props.patient.user_id, 'all');
  }
  onOpenInsuranceForm = () => {
    openForm(this.props.patient.user_id, 'INSURANCE');
  }
  render() {
    const { classes, checkedIn, onUpdateAppointment, patient } = this.props;
    const sex = patient.sex ? patient.sex.split('') : '';
    const providerMenu = (
      <FormControl className={classes.formControl}>
        <NativeSelect
          value={patient.provider_id || null}
          onChange={this.onChangeProvider}
          name=""
          className={classes.clinicianDropdown}
          >
          <option value={null}>None Selected</option>
          {this.props.providers.map((provider) => {
            return (<option key={provider.id} value={provider.id}>{provider.name}</option>);
          })}
        </NativeSelect>
      </FormControl>
      
    );
    return (
      <TableRow
        selected={patient.selected}
        style={this.props.highlight}
      >
        <TableCell
          onClick={() => {
            logEvent('Select a Patient');
            this.onOpenAppointmentView();
          }}
          className={classes.patientName}
        >
          <Avatar
            image={patient.avatarImgSmall}
            classes={{ avatar: this.props.classes.avatar }}
          />
          {patient.last_name}, {patient.first_name}
        </TableCell>
        <TableCell className={classes.td}>{moment(patient.birth_date).format('MM/DD/YYYY')}</TableCell>
        <TableCell className={classes.td} padding="none">{sex[0]}</TableCell>
        <TableCell className={classes.providerTd}>{providerMenu}</TableCell>
        <TableCell style={baseStyles.checkoutColumn}>
          <CheckInBtn
            patient={patient}
            checkedIn={checkedIn}
            onUpdateAppointment={onUpdateAppointment}
            onCreateAppointment={this.props.onCreateAppointment}
          />
        </TableCell>
      </TableRow>

    );

  }
}

PatientList.defaultProps = {
  patient: {},
  providers: [],
};

PatientList.propTypes = {
  addRecent: PropTypes.func.isRequired,
  checkedIn: PropTypes.bool.isRequired,
  patient: PropTypes.shape({
    id: PropTypes.number,
    checked_in: PropTypes.string,
    checked_out: PropTypes.string,
    clinicians: PropTypes.array,
    complete: PropTypes.number,
    in_session: PropTypes.string,
    status: PropTypes.string,
    user_id: PropTypes.string,
  }).isRequired,
  providers: PropTypes.array,
  router: PropTypes.object.isRequired,
  onUpdateAppointment: PropTypes.func.isRequired,
};

export default withStyles(baseStyles)(PatientList);
