import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'material-ui';
import Button from '@material-ui/core/Button';
import { colors } from '../lib/styles';

const buttonStyle = {
  fontSize: '16px',
  height: '40px',
  color: '#fff',
  backgroundColor: colors.secondaryColor,
};

class LogoutDialog extends Component {
  constructor(props) {
    super(props);
    this.closeNotification = this.handleCloseNotification.bind(this, false);
    this.logout = this.handleCloseNotification.bind(this, true);
  }
  handleCloseNotification(logout) {
    if(logout) {
      this.props.logout();
    }
    this.props.toggleUserLogoutDialog();
  }
  render() {
    const {
      userLogoutDialog,
    } = this.props;
    return (
      <Dialog
        modal={true}
        open={userLogoutDialog}
        title="Are you sure you wish to log out?"
        style={{ textAlign: 'center' }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          children="Cancel"
          onClick={this.closeNotification}
          style={buttonStyle}
        />
        <Button
          children="Log Out"
          onClick={this.logout}
          style={buttonStyle}
        />
        </div>
      </Dialog>
    );
  }
}

LogoutDialog.propTypes = {
  userLogoutDialog: PropTypes.bool,
  toggleUserLogoutDialog: PropTypes.func,
};

export default LogoutDialog;

