import moment from 'moment';
import { get } from 'lodash';

function buildInitialObject(lastDayDate) {
  const end = Number(lastDayDate.format('DD')) || 31;
  const data = {};
  for(let i = 1; i <= end; i++) {
    data[i] = 0;
  }
  return data;
}
function bodyPainLineBuilder(data = [], month, year) {
  const firstDayDate = moment(new Date(year, month, 1));
  const lastDayDate = moment(new Date(year, month + 1, 0));

  const initialObject = buildInitialObject(lastDayDate);

  /* eslint-disable no-param-reassign */

  return data.reduce((lineData, currentPain) => {
    const intensity = get(currentPain, 'intensity', 0);
    if (!intensity) {
      return lineData;
    }
    const { pain_start_date, pain_end_date } = currentPain;
    const startDate = moment(pain_start_date);
    const endDate = moment(pain_end_date);
    const startDay = Number(startDate.format('DD'));

    const endDay = Number(endDate.format('DD'));

    if (startDate.isBetween(firstDayDate, lastDayDate) || lineData[startDay] < intensity) {
      lineData[startDay] = intensity;
    }

    if (startDate < endDate) {
      if (endDate.isBetween(firstDayDate, lastDayDate) && lineData[endDay] < intensity) {
        lineData[endDay] = intensity;
      }

      for (let currentDate = startDate; moment(currentDate) < endDate; currentDate = moment(currentDate).add(1, 'day')) {
        if(currentDate.isBetween(firstDayDate, lastDayDate)) {
          const currentDay = Number(currentDate.format('DD'));

          if (!lineData[currentDay] || lineData[currentDay] < intensity) {
            lineData[currentDay] = intensity;
          }
        }
      }
    }

    return lineData;
  }, initialObject);
  /* eslint-enable no-param-reassign */
}

export default bodyPainLineBuilder;
