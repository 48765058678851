import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, forEach, assign } from 'lodash';
import { withStyles } from '@material-ui/core/styles'

import EmrComparisonGrid from './emr-comparison-grid';
import EmrMergeHeader from './emr-merge-header';
import EmrCard from './emr-card';
import EmrDataField from './emr-data-field';
import Checkbox from './checkbox';
import { stateFormat, mergeFormat } from '../lib/merge-format-PI';
import { emrPageStyles } from '../lib/styles';

const styles = { ...emrPageStyles };

const CustomCardText = withStyles(styles)((props) => {
  const list = [];
  forEach(props.state, (val, key) => {
    if (key === 'last_name') {
      return;
    }
    let value = val[props.type];
    if (key === 'first_name') {
      value = `${val[props.type]}`
    }
    list.push((
      <EmrDataField
        classes={{ container: props.classes.emrDataFieldContainer, fieldTitle: props.classes.fieldTitle }}
        key={`${key}-${props.type}`}
        leftCheckbox={<Checkbox
          checked={val.selection === props.type}
          onChange={(e, checked) => props.handleSelection(key, props.type)}
          classes={{ root: props.classes.checkbox }}
        />}
        field={val.label}
        value={value}
      />
    ));
  });

  return list;
});

CustomCardText.propTypes = {
  type: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  handleSelection: PropTypes.func.isRequired,
};

function uncheckNull(mindsetVal, emrVal) {
  if (mindsetVal) {
    return 'mindset';
  }
  if (emrVal) {
    return 'emr';
  }
  return 'custom';
}

class MergePatientPI extends Component {
  constructor(props) {
    super(props);
    this.handleSelection = this.handleSelection.bind(this);
    this.handleManual = this.handleManual.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
    this.handleNext = this.handleNext.bind(this);

    const { appointment, mergeData } = this.props;
    const emrDemo = get(mergeData, 'ehr.Header.Patient.Demographics', {});
    const initial = stateFormat(appointment, emrDemo);
    this.state = forEach(initial, (val) => {
      val.selection = uncheckNull(val.mindset, val.emr);
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleNext() {
    const data = mergeFormat(this.state, 'YYYY-MM-DD');
    this.setState({ nextLoading: true});
    this.props.editUser(this.props.emrPatient, data, this.props.mergeData.page, 'info_merge')
      .catch(() => this.setState({ nextLoading: false }));
  }
  handleSkip() {
    this.props.mergePatientData(false);
  }
  handleReset() {
    const update = {};
    forEach(this.state, (field, key) => {
      update[key] = assign({}, field, {
        selection: 'mindset',
        custom: '',
      });
    });
    this.setState(update);
  }
  handleSelection(field, type) {
    this.setState({
      [field]: assign({}, this.state[field], {
        selection: type,
      }),
    });
  }
  handleManual(e, manual) {
    const { field } = e.target.dataset;
    this.setState({
      [field]: assign({}, this.state[field], {
        selection: 'custom',
        custom: manual,
      }),
    });
  }
  render() {
    const HanaList = <CustomCardText state={this.state} handleSelection={this.handleSelection} type="mindset" />;
    const EmrList = <CustomCardText state={this.state} handleSelection={this.handleSelection} type="emr" />;
    const selection = mergeFormat(this.state);
    const EmrFieldClasses = { container: this.props.classes.centerEmrDataFieldContainer };
    return (
      <div style={styles.container}>
        <EmrMergeHeader
          nextLoading={this.state.nextLoading}
          onNext={this.handleNext}
          onCancel={this.handleSkip}
          title="Update Patient Info"
        />
        <EmrComparisonGrid>
          <EmrCard cardTitle="Hana Patient">
            {HanaList}
          </EmrCard>

          <EmrCard cardTitle="Combined Patient">
            <EmrDataField classes={EmrFieldClasses} field="Name" value={`${selection.first_name} ${selection.last_name}`} />
            <EmrDataField classes={EmrFieldClasses} field="DOB" value={selection.birth_date} />
            <EmrDataField classes={EmrFieldClasses} field="Height" value={selection.height} />
            <EmrDataField classes={EmrFieldClasses} field="Weight"  value={selection.weight} />
            <EmrDataField classes={EmrFieldClasses} field="Primary Language"  value={selection.primary_language} />
            <EmrDataField classes={EmrFieldClasses} field="Email"  value={selection.email} />
            <EmrDataField classes={EmrFieldClasses} field="Home Phone"  value={selection.home_phone} />
            <EmrDataField classes={EmrFieldClasses} field="Work Phone"  value={selection.work_phone} />
            <EmrDataField classes={EmrFieldClasses} field="Cell Phone"  value={selection.cell_phone} />
            <EmrDataField classes={EmrFieldClasses} field="Street Address"  value={selection.address} />
            <EmrDataField classes={EmrFieldClasses} field="City"  value={selection.city} />
            <EmrDataField classes={EmrFieldClasses} field="State"  value={selection.state} />
            <EmrDataField classes={EmrFieldClasses} field="Zip"  value={selection.zip_code} />
            <EmrDataField classes={EmrFieldClasses} field="Emergency Contact"  value={selection.emergency_contact} />
            <EmrDataField classes={EmrFieldClasses} field="Relationship"  value={selection.emergency_relationship} />
            <EmrDataField classes={EmrFieldClasses} field="Phone Number"  value={selection.emergency_phone} />
            <EmrDataField classes={EmrFieldClasses} field="Email"  value={selection.emergency_email} />
          </EmrCard>
          <EmrCard cardTitle="EMR Patient">
            {EmrList}
          </EmrCard>
        </EmrComparisonGrid>
      </div>
    );
  }
}

MergePatientPI.propTypes = {
  appointment: PropTypes.object,
  editUser: PropTypes.func.isRequired,
  emrPatient: PropTypes.object.isRequired,
  mergeData: PropTypes.object,
  handleSkip: PropTypes.func,
};

export default withStyles(styles)(MergePatientPI);
